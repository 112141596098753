import React, { Fragment } from "react";

// const dataJadwalIfUndefine = {
//   tanggal_mulai: new Date(),
//   tanggal_berakhir: new Date(),
//   tempat: "",
// };
const convertDate = (date) => {
  return {date: new Date(date).toDateString(), time: new Date(date).toLocaleTimeString() }
}
const InfoJadwal = ({ data_jadwal }) => {
  const startDateTime = convertDate(data_jadwal?.tanggal_mulai);
  const endDateTime = convertDate(data_jadwal?.tanggal_berakhir);
  return (
    <Fragment>
      <div className="form-group">
        <hr />
      </div>
      <div className="wrapper-table remove-scroll">
        <table className="table table-striped font-12">
          {data_jadwal && 
            <tbody>
              <tr>
                <td>Tanggal</td>
                <td>
                  : {startDateTime?.date} - {endDateTime?.date}
                </td>
              </tr>
              <tr>
                <td>Waktu</td>
                <td>
                  : {startDateTime?.time} - {endDateTime?.time}
                </td>
              </tr>
              <tr>
                <td>Tempat</td>
                <td>: {data_jadwal?.tempat}</td>
              </tr>
            </tbody>
          }
        </table>
      </div>
    </Fragment>
  );
};

export default InfoJadwal;
