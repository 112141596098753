/* eslint-disable no-unused-vars */
import Loading from "components/body/Loading";
import NextBtn from "components/body/buttons/nextBtn";
import PrevBtn from "components/body/buttons/prevBtn";
import { getRequest } from "config/axios";
import { debounce } from "lodash";
import React, { Fragment, useState, useCallback } from "react";
import { useEffect } from "react";
import { Modal, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { checkError, setLoading } from "redux/GeneralReducers/GeneralActions";
import { set_value } from "redux/SuratReducers/BuatSurat/BuatSuratActions";

const Pemeriksa = () => {
  const [lgShow, setLgShow] = useState(false);
  const { BuatSuratReducers, LoginReducers } = useSelector((state) => state);
  const { pemeriksa, user } = BuatSuratReducers;
  return (
    <Fragment>
      <Modals lgShow={lgShow} setLgShow={setLgShow} />
      <div className="col-md-12">
        <div className="form-group">
          <label htmlFor="pemeriksa">
            {user ? (
              LoginReducers.userInfo.user_id === user.user_id ? (
                <a href="# " onClick={() => setLgShow(!lgShow)}>
                  Pemeriksa *
                </a>
              ) : (
                "Pemeriksa"
              )
            ) : (
              <a href="# " onClick={() => setLgShow(!lgShow)}>
                Pemeriksa *
              </a>
            )}
          </label>
          <div className="box-input-with-list font-12">
            <ol style={{ marginTop: "10px" }}>
              {pemeriksa.user &&
                pemeriksa.user.map((view, i) => {
                  return (
                    <li key={i}>
                      {view.data_pegawai.peg_nama} -{" "}
                      {view.data_pegawai.is_plt && " (PLT) "}
                      {view.data_pegawai.jabatan_nama}
                    </li>
                  );
                })}
            </ol>
          </div>
        </div>
      </div>
      <div className="col-md-12 flex-space-between">
        <PrevBtn to="penerima" />
        <NextBtn to="referensi" />
      </div>
    </Fragment>
  );
};

export default Pemeriksa;

const Modals = (props) => {
  const [dataPegawai, setDataPegawai] = useState(null);
  const [selected, setSelected] = useState([]);
  const [selectedPlt, setSelectedPlt] = useState([]);
  const dispatch = useDispatch();
  const { GeneralReducers, BuatSuratReducers, LoginReducers } = useSelector(
    (state) => state
  );
  const { pemeriksa } = BuatSuratReducers;
  let satuan_kerja_id =
    LoginReducers.userInfo.data_pegawai.satuan_kerja.satuan_kerja_id || 0;

  const getPegawai = (current_page = 1, q = "") => {
    dispatch(setLoading(true));
    getRequest(
      `api/egs/list/pegawai?q=${q}&satuan_kerja_id=${satuan_kerja_id}&page=${current_page}&per_page=10`
    )
      .then((res) => {
        setDataPegawai(res.data);
        dispatch(setLoading(false));
      })
      .catch((err) => dispatch(checkError(err)));
  };

  const debounceSave = useCallback(
    debounce((current_page, q) => getPegawai(current_page, q), 1000),
    []
  );

  const setListPegawai = (item) => {
    const newSelect = {
      data_pegawai: {
        peg_nama: item.fullName,
        peg_nip: item.peg_nip,
        jabatan_nama: item.namaJabatan,
        is_plt: item.is_plt || false,
      },
    };
    const a = selected.filter((view) => {
      return item.peg_nip !== view.data_pegawai.peg_nip;
    });

    setSelected([...a, newSelect]);
  };

  const delSelected = ({ data_pegawai: { peg_nip, is_plt } }) => {
    let lists = selected.filter(
      (list) => list.data_pegawai.peg_nip !== peg_nip
    );
    setSelected(lists);
  };

  const setPengirim = () => {
    dispatch(
      set_value({
        value: { ...pemeriksa, user: selected, plt: selectedPlt },
        name: "pemeriksa",
      })
    );
    props.setLgShow(false);
  };

  const setVal = () => {
    setSelected(pemeriksa.user);
  };

  useEffect(setVal, [props.lgShow]);
  useEffect(getPegawai, []);

  return (
    <Fragment>
      <Modal
        size="lg"
        show={props.lgShow}
        onHide={() => props.setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header className="header-modal" closeButton>
          <Modal.Title
            className="title-modal"
            id="example-modal-sizes-title-lg"
          >
            PEMERIKSA
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row reset-margin-row">
            <div className="col-md-8 content-modal">
              <div className="title-tab-second bg-white no-border-bottom text-right">
                <span className="mr-3">
                  {dataPegawai && dataPegawai.current_page} dari{" "}
                  {dataPegawai && dataPegawai.last_page}
                </span>
                <i
                  className="fa fa-chevron-left mr-3 pointer-mouse"
                  onClick={() => {
                    dataPegawai.current_page > 1 &&
                      getPegawai(dataPegawai.current_page - 1);
                  }}
                ></i>
                <i
                  className="fa fa-chevron-right pointer-mouse"
                  onClick={() => {
                    dataPegawai.current_page < dataPegawai.last_page &&
                      getPegawai(dataPegawai.current_page + 1);
                  }}
                ></i>
              </div>
              <Loading isLoading={GeneralReducers.loading} />
              <Table striped bordered hover className="table-modal-content">
                <thead>
                  <tr>
                    <th>Nama</th>
                    <th>Jabatan</th>
                  </tr>
                </thead>
                <tbody>
                  {dataPegawai &&
                    dataPegawai.data.map((view, i) => {
                      return (
                        <tr
                          key={i}
                          onClick={() => setListPegawai(view)}
                          className={`pointer-mouse ${view.active_selected && `active-selected`
                            }`}
                        >
                          <td>{view.fullName}</td>
                          <td>
                            {view.is_plt && <b>PLT - </b>}
                            {view.namaJabatan}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </div>
            <div className="col-md-4 content-modal">
              <div className="title-tab-second">Pilihan</div>
              <ul className="parent-list">
                {selected &&
                  selected.map((view, i) => {
                    return (
                      <li key={i} className="child-list">
                        {view.data_pegawai.peg_nama}
                        {view.data_pegawai.is_plt && " (PLT)"}
                        <i
                          className="fa fa-trash pointer-mouse"
                          onClick={() => delSelected(view)}
                        ></i>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
          <hr />
          <div className="row reset-margin-row ">
            <div className="col-md-4 mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Kata Kunci"
                onChange={(e) => debounceSave(1, e.target.value)}
              />
            </div>
            <div className="col-md-5 mb-3"></div>
            <div className="col-md-3 tabs-header-modal space-table mb-3">
              {selected && (
                <button
                  onClick={setPengirim}
                  className="btn col-md-12 btn-primary mr-2"
                >
                  Oke
                </button>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};
