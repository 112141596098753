import moment from 'moment';
import { useEffect } from 'react';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
// import { delRequest } from 'config/axios';
import Loading from 'components/body/Loading';
import Paginate from 'components/footers/paginate';
import { useDispatch, useSelector } from 'react-redux';
import { getListMethod } from 'redux/SuratReducers/ListSuratMasuk/ListSuratAction';
import {
  decrement,
  increment,
  setFirstPage,
  setLastPage,
} from 'utils/pagination';
// import {
//   checkError,
//   setLoading,
//   setStatus,
// } from 'redux/GeneralReducers/GeneralActions';

const Content = () => {
  const dispatch = useDispatch();
  const { GeneralReducers, ListSuratReducers, LoginReducers } = useSelector(
    (state) => state
  );
  const { loading, status, msg } = GeneralReducers;
  const { items, meta } = ListSuratReducers.listBankSuratMasuk;
  let satuan_kerja_id =
    LoginReducers.userInfo.data_pegawai.satuan_kerja.satuan_kerja_id || 0;
  const getList = (current_page = 1) => {
    dispatch(
      getListMethod(
        `master/bank-mail-masuk-internal`,
        'listBankSuratMasuk',
        current_page,
        satuan_kerja_id
      )
    );
  };

  // const deleteSurat = (id) => {
  //   dispatch(setLoading(true));
  //   delRequest(`egs/master/bank-mail/${id}`)
  //     .then((res) => {
  //       dispatch(setLoading(false));
  //       dispatch(setStatus({ status: 'success-bank-surat', msg: 'Success !' }));
  //     })
  //     .catch((err) => dispatch(checkError(err, 'fail-bank-surat')));
  // };

  useEffect(getList, []);

  return (
    <Fragment>
      <div className='content-title'></div>
      <div className='content-body'>
        {status === 'success-bank-surat' && (
          <div className='alert alert-success'>{msg}</div>
        )}
        {status === 'fail-bank-surat' && (
          <div className='alert alert-danger'>{msg}</div>
        )}
        <Loading isLoading={loading} />
        <table className='table table-striped table-list'>
          <thead>
            <tr>
              <td width='130px'>Tanggal Terima</td>
              <td>Pengirim</td>
              <td>Nomor Surat</td>
              <td>Hal</td>
              <td>Disposisi</td>
            </tr>
          </thead>
          <tbody>
            {items &&
              items.map((list, i) => {
                return (
                  <tr key={i}>
                    <td>
                      <Link to={`/bank-surat/masuk/list/detail/${list.id}`}>
                        {moment(list.tanggal).format('LL')}
                      </Link>
                    </td>
                    <td>{list.dari}</td>
                    <td>{list.no_surat}</td>
                    <td>{list.hal}</td>
                    <td>
                      {list.disposisi &&
                        <>
                          {list.disposisi.peg_nama}
                          <p>{list.disposisi.jabatan_nama}</p>
                          {list.disposisi.aksi.map((item, i) => {
                            return (
                              <p key={i}>
                                <span className='badge badge-primary'>
                                  {item}
                                </span>
                              </p>
                            )
                          })}
                        </>
                      }
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      {meta && (
        <Paginate
          meta={meta}
          increment={() => increment(meta.current_page, getList)}
          decrement={() => decrement(meta.current_page, getList)}
          setLastPage={() => setLastPage(meta.last_page, getList)}
          setFirstPage={() => setFirstPage(meta.from, getList)}
        />
      )}
    </Fragment>
  );
};

export default Content;
