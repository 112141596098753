import "../../styles/content/login.scss";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Navmenu from "./cards/navmenu";
import { Redirect } from "react-router-dom";
import { getTokenFcm } from "utils/fcm-helper";
import { login } from "redux/loginRedux/LoginActios";
import { setLoading } from "redux/GeneralReducers/GeneralActions";

const Login = () => {
  const [username, setUsername] = useState(null);
  const [typeInput, setTypeInput] = useState("password");
  const [icEyes, setIcEyes] = useState("fa-eye-slash");
  const [password, setPassword] = useState(null);
  const { LoginReducers, GeneralReducers } = useSelector((state) => state);
  const dispatch = useDispatch();
  
  const postData = (e) => {
    e.preventDefault()
    dispatch(setLoading(true));
    dispatch(login({ username, password }));
  };

  const openDownload = () => {
    window.open("https://api.codemagic.io/artifacts/b8e43696-31a1-4b43-8687-86c68ddc54e5/1ade79e1-a77a-434e-be03-7e41865fb45c/app-debug.apk", "_blank")
  }
  const toTypePassword = () => {
    setTypeInput("password")
  }
  const toTypeText = () => {
    setTypeInput("text")
  }
  const toEye = () => {
    setIcEyes('fa-eye');
  }
  const toEyeSlash = () => {
    setIcEyes('fa-eye-slash')
  }
  const onShowPassword = () => {
    if(typeInput === 'text') {
      toTypePassword(); 
      toEyeSlash();
    }
    if(typeInput === 'password'){
      toTypeText(); 
      toEye();
    }
  }
  useEffect(getTokenFcm, []);

  if (LoginReducers.isLogin) {
    return <Redirect to="dashboard" />;
  }

  return (
    <div className="container-login">
      <Navmenu />
      <div className="left-content">
        <div className="form-wrapper center-element">
          <div className="title">
            <span>Selamat Datang di EGS - AMS</span>
            <span>Silahkan Masuk</span>{" "}
            {GeneralReducers.status === "error" && (
              <div className="alert alert-danger">{GeneralReducers.msg}</div>
            )}
          </div>
          <form  onSubmit={(e) => postData(e)}> 
          <div className="input-wrapper">
            <label htmlFor="username">Username / Nomor Induk Pegawai</label>
            <i className="fa fa-envelope"></i>
            <input
              type="text"
              autoComplete="off"
              id="username"
              defaultValue={username}
              aria-describedby="emailHelp"
              placeholder="username / nomor nnduk pegawai"
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="input-wrapper">
            <label htmlFor="password">Kata Sandi</label>
            <i className="fa fa-key"></i>
            <input
              type={typeInput}
              id="password"
              aria-describedby="password"
              placeholder="password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <div onClick={onShowPassword} className="eye-password">
              <i className={`fa ${icEyes} `}/>
            </div>
          </div>
          <div className="btn-wrapper">
            <button type="submit" disabled={GeneralReducers.loading}>
              {GeneralReducers.loading ? "Sedang Masuk..." : "Masuk"}
            </button>
          </div>
          <div className="option-wrapper">
            <div>
              <input type="checkbox" id="ingat_saya" />{" "}
              <label htmlFor="ingat_saya"> Ingat Saya</label>
            </div>
            <a href="# ">Lupa Kata Sandi</a>
          </div>
          </form>
        </div>
      </div>
      <div className="right-content">
        <img src={process.env.PUBLIC_URL + '/assets/img/Mobile.svg'} className="phone" alt="phone" />
        <div className="download">
          <span>Download Aplikasi</span>
          {/* <img src={process.env.PUBLIC_URL + '/assets/img/apple.png'} onClick={openDownload} alt="apple" /> */}
          <img src={process.env.PUBLIC_URL + '/assets/img/gooleplay.png'} onClick={openDownload} alt="googleplay" />
        </div>
      </div>
      <footer>
        <img src={process.env.PUBLIC_URL + '/assets/img/footer-logo.png'} className="footer-logo" alt="footer_logo" />
        <div className="text-footer">
          <span>PALLAKA STUDIO</span>
          <span> &copy; 2021 Pallaka Studio</span>
        </div>
        <div className="text-support">
          <div>Di dukung oleh</div>
          <img src={process.env.PUBLIC_URL + '/assets/img/logo-bsre.svg'} className="footer-foo" alt="footer_logo" />
        </div>
      </footer>
    </div>
  );
};

export default Login;
