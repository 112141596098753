import { PersistGate } from "redux-persist/integration/react";
import LostConnection from "components/body/LostConnection";
import { store, persistor } from "redux/rootRedux/Store";
import "react-notifications-component/dist/theme.css";
import "react-datepicker/dist/react-datepicker.css";
import React, { useEffect } from "react";
import Navigate from "./route/Navigate";
import { Provider } from "react-redux";
import "./styles/index.scss";
import moment from "moment";
import "moment/locale/id";
import swCheck from "utils/sw-helper";
moment.locale("id");

function App() {
  useEffect(() => swCheck(), [])

  return (
    <div>
      <LostConnection />
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Navigate />
        </PersistGate>
      </Provider>
    </div>
  );
}

export default App;
