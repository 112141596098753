import React from "react";
// import "../../styles/content/content.scss";

const ContentWrapper = (props) => {
  return (
    <div className="content">
      <div className="content-wrapper">{props.children}</div>
    </div>
  );
};

export default ContentWrapper;
