import { SET_SKPD } from "redux/rootRedux/TypeActions";

const GlobalState = {
  skpd: null,
};

export const SuratReducers = (state = GlobalState, action) => {
  switch (action.type) {
    case SET_SKPD:
      return {
        ...state,
        skpd: action.payload,
      };
    default:
      return state;
  }
};
