import { store } from "redux/rootRedux/Store";

const { default: Axios } = require("axios");

const instance = Axios.create({
  baseURL: process.env.REACT_APP_BASE_LINK,
  // timeout: 10000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export const setTokenToHeader = (AuthToken) => {
  const getToken =
    AuthToken || `Bearer ${store.getState().LoginReducers.token}`;
  instance.defaults.headers.common["Authorization"] = getToken;
};

export const getRequest = (url) => {
  setTokenToHeader();
  return instance.get(url);
};

export const putRequest = (url, data) => {
  setTokenToHeader();
  return instance.put(url, data);
};

export const postRequest = (url, data) => {
  setTokenToHeader();
  return instance.post(url, data);
};

export const patchRequest = (url, data) => {
  setTokenToHeader();
  return instance.patch(url, data);
};
export const delRequest = (url, data) => {
  setTokenToHeader();
  return instance.delete(url);
};

export const postFileRequest = (url, data) => {
  setTokenToHeader();
  return instance.post(url, data, {
    headers: {
      Accept: "multipart/form-data",
      // ContentType: "application/json"
      "X-FROM-APP": "egs-app",
    },
  });
};
