import React from "react";

const PrevBtn = ({ to }) => {
  return (
    <a href={`#${to}`} id="toTabContent">
      Sebelumnya
    </a>
  );
};

export default PrevBtn;
