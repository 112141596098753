import Content from "./cards/content";
import React from "react";
import Layout from "layout";

export function BankSuratMasukSekretariat() {
  return (
    <Layout subnavbar={{
      print_btn: true,
      reset_btn: false,
      path: "/bank-surat/list-surat/",
      title: "Bank Surat Masuk (Sekretariat)", keysTab: "listBankSuratMasukSekretariat"
    }}>
      <Content />
    </Layout>
  );
}
