import React, { useCallback, useState } from "react";
// import DatePicker from "react-datepicker";
import { VictoryBar, VictoryChart, VictoryTheme } from "victory";
import Select from "react-select";
import { getRequest } from "config/axios";
import { useDispatch } from "react-redux";
import { checkError } from "redux/GeneralReducers/GeneralActions";
import { useEffect } from "react";
// import moment from "moment";
// import Layout from "layout";

function Content() {
  // const [data, setData] = useState(null);
  // const [date, setDate] = useState(new Date());
  // const dispatch = useDispatch();

  // let monthYear = moment(date).format("MMMM YYYY");
  // const getData = () => {
  //   let month = date.getMonth() + 1;
  //   getRequest(`/egs/statistik/count?month=${month}&year=${date.getFullYear()}`)
  //     .then((res) => {
  //       setData(res.data.items);
  //     })
  //     .catch((err) => dispatch(checkError(err)));
  // };

  // useEffect(getData, [date]);

  return (
    <>
      <div className="form-row">
        <div className="col-md-10 mb-3 mt-3">
          <h3>Statistik Arsip</h3>
        </div>
      </div>

      <div className="chart-wrapper">
        <Chart />
      </div>
    </>
  );
}

export default Content;

const Chart = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [idJenisArsip, setIdJenisArsip] = useState(null);
  const [tahun, setTahun] = useState(new Date().getFullYear());
  const [dataJenisArsip, setDataJenisArsip] = useState([
    { value: 0, label: "Pilih" },
  ]);

  let defaultValue = [
    { month: "Jan", total: 0 },
    { month: "Feb", total: 0 },
    { month: "Mar", total: 0 },
    { month: "Apr", total: 0 },
    { month: "Mei", total: 0 },
    { month: "Jun", total: 0 },
    { month: "Jul", total: 0 },
    { month: "Aug", total: 0 },
    { month: "Sep", total: 0 },
    { month: "Okt", total: 0 },
    { month: "Nov", total: 0 },
    { month: "Des", total: 0 },
  ];

  const setOptions = (data) =>
    data.map((item) => ({ value: item._id, label: item.nama }));

  const getJenisArsip = (q = "") => {
    getRequest(`egs/master/arsip/jenis-arsip?q=${q}`)
      .then((res) => {
        let a = setOptions(res.data.items);
        setDataJenisArsip(a);
      })
      .catch((err) => dispatch(checkError(err)));
  };

  const getData = useCallback((id, tahun) => {
    getRequest(`egs/statistik/arsip?tahun=${tahun}&jenis_arsip_id=${id}`)
      .then((res) => {
        setData(res.data.items.datas);
      })
      .catch((err) => dispatch(checkError(err)));
  }, [dispatch]);


  useEffect(() => {
    if (idJenisArsip) {
      getData(idJenisArsip, tahun)
    }
  }, [getData, idJenisArsip, tahun]);
  useEffect(getJenisArsip, []);

  return (
    <div className="my-chart">
      <div className="title">Jenis Arsip</div>
      <div className="input-data m-tb-20">
        <Select
          placeholder="Pilih Jenis Arsip"
          className="select-class"
          options={dataJenisArsip}
          onChange={(e) => setIdJenisArsip(e.value)}
        />
      </div>
      <div className="title">Tahun</div>
      <div className="input-data m-tb-20">
        <input
          type="text"
          defaultValue={tahun}
          className="form-control"
          onChange={(e) => setTahun(e.target.value)}
        />
      </div>
      <div className="my-chart-body">
        <VictoryChart theme={VictoryTheme.grayscale} domainPadding={20}>
          <VictoryBar
            cornerRadius={{ top: 8, bottom: 8 }}
            style={{
              data: {
                fill: "#c43a31",
                width: 15,
              },
            }}
            data={data ? data : defaultValue}
            width={1200}
            x="month"
            y="total"
          />
        </VictoryChart>
      </div>
    </div>
  );
};
