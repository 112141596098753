const increment = (data, cb) => {
  let current_page = data + 1;
  cb(current_page);
};

const decrement = (data, cb) => {
  let current_page = data - 1;
  cb(current_page);
};

const setLastPage = (data, cb) => {
  cb(data);
};

const setFirstPage = (data, cb) => {
  cb(data);
};

export { increment, decrement, setFirstPage, setLastPage };
