import React, { Fragment } from "react";
import { Modal } from "react-bootstrap";
import Buttons from "components/body/buttons/Button";
import SelectSatuanKerja from "components/body/selects/selectSatuanKerja";
import { delRequest, postRequest, putRequest } from "config/axios";

const ModalComponent = ({ isOpen, setOpen, unitKerja, setUnitKerja, getList }) => {

  const submit = () => {
    postRequest(`egs/pegawai-egs/api/unit-kerja`, unitKerja)
      .then(res => {
        setOpen(!isOpen)
        getList()
      }).catch(err => console.log("respon unit kerja error", err))
  };

  const update = (id) => {
    putRequest(`egs/pegawai-egs/api/unit-kerja/${id}`, unitKerja)
      .then(res => {
        setOpen(!isOpen)
        getList()
      }).catch(err => console.log("respon unit kerja error", err))
  }

  const deleteData = (id) => {
    delRequest(`egs/pegawai-egs/api/unit-kerja/${id}`)
      .then(res => {
        setOpen(!isOpen)
        getList()
      }).catch(err => console.log("respon unit kerja error", err))
  }

  const onHideModal = () => {
    setOpen(false)
    setUnitKerja({
      status: true,
      type_unit_kerja: 3,
    })
  }

  return (
    <Fragment>
      <Modal
        size="md"
        show={isOpen}
        onHide={onHideModal}
        aria-labelledby="example-modal-sizes-title-lg"
        animation={false}
      >
        <Modal.Header className="header-modal" closeButton>
          <Modal.Title
            className="title-modal"
            id="example-modal-sizes-title-lg"
          >
            Tambah Unit Kerja
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row reset-margin-row">
            <div className="col-md-12 content-modal">
              <div className="container-fluid">
                <div className="form-group">
                  <label htmlFor="lokasi">Satuan Kerja*</label>
                  <SelectSatuanKerja
                    placeholder="Pilih Satuan Kerja"
                    className="m-b-5"
                    list="listMasterSatuanKerja"
                    sourceReducer="ListSuratReducers"
                    defaultValue={{ value: unitKerja?.satuan_kerja_id, label: unitKerja?.name }}
                    onChange={(e) => setUnitKerja({ ...unitKerja, satuan_kerja_id: e.value })}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="code">Kode Unit Kerja*</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={unitKerja?.code}
                    onChange={e => setUnitKerja({ ...unitKerja, code: e.target.value })}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="unit-kerja">Unit Kerja*</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={unitKerja?.name}
                    onChange={e => setUnitKerja({ ...unitKerja, name: e.target.value })}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="unit-kerja">Kode Referensi*</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={unitKerja?.sapk_id}
                  // onChange={e => setUnitKerja({ ...unitKerja, sapk_id: e.target.value })}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="lokasi">Alamat*</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={unitKerja?.address}
                    onChange={e => setUnitKerja({ ...unitKerja, address: e.target.value })}
                  />
                </div>
                <div className="form-group">
                  <div>Status</div>
                  <div style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "start"
                  }}>
                    <input
                      type="radio"
                      id="aktif"
                      name="status"
                      defaultChecked={unitKerja.status === true}
                      onChange={() => setUnitKerja({ ...unitKerja, status: unitKerja.status })}
                    />
                    <label style={{ marginLeft: "5px", marginRight: "8px", marginTop: "8px" }} htmlFor="aktif">Aktif</label>
                    <input
                      type="radio"
                      id="non-aktif"
                      name="status"
                      defaultChecked={unitKerja.status === false}
                      onChange={() => setUnitKerja({ ...unitKerja, status: !unitKerja.status })}
                    />
                    <label style={{ marginLeft: "5px", marginTop: "8px" }} htmlFor="non-aktif">Non-Aktif</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="row reset-margin-row ">
            <div className="col-md-6 mb-3">
              {unitKerja.id &&
                <Buttons
                  className="btn btn-danger mr-2"
                  children="Hapus"
                  onClick={() => deleteData(unitKerja.id)}
                />
              }
            </div>
            <div className="col-md-6 text-right mb-3">
              <Buttons
                onClick={onHideModal}
                className="btn btn-default mr-2"
                children="Batal"
              />
              {unitKerja.id ?
                <Buttons
                  onClick={() => update(unitKerja.id)}
                  className="btn btn-primary"
                  children="Ubah"
                // loading={GeneralReducers.loading}
                />
                :
                <Buttons
                  onClick={submit}
                  className="btn btn-primary"
                  children="Simpan"
                // loading={GeneralReducers.loading}
                />
              }
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default ModalComponent;
