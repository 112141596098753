import React, { Fragment, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from 'react-redux'
import Buttons from "components/body/buttons/Button";
import Select from "react-select";
import SelectSatuanKerja from "components/body/selects/selectSatuanKerja"
import { getListSelect, set_option_default } from "redux/SelectReducers/SelectAction";
import { delRequest, postRequest, putRequest } from "config/axios";

const ModalComponent = ({ isOpen, setOpen, jabatan, setJabatan, getList }) => {
  const [unitKerjaValue, setUnitKerjaValue] = useState({ value: jabatan?.unit_kerja_id, label: jabatan?.unitKerja?.name })
  const dispatch = useDispatch()
  const getListUnitKerja = (id = 0) => {
    dispatch(getListSelect(`pegawai-egs/api/unit-kerja`, "unitKerja", id));
  }
  const postPegawai = () => {
    postRequest('egs/pegawai-egs/api/jabatan', { ...jabatan })
      .then(res => {
        getListUnitKerja()
        onCloseModal()
      }).catch(error => console.log("error post jabatan", error))
  }

  const updateJabatan = () => {
    putRequest(`egs/pegawai-egs/api/jabatan/${jabatan.id}`, { ...jabatan })
      .then(res => {
        getListUnitKerja()
        onCloseModal()
      }).catch(error => console.log("error post jabatan", error))
  }

  const deleteJabatan = () => {
    delRequest(`egs/pegawai-egs/api/jabatan/${jabatan.id}`)
      .then(res => {
        getListUnitKerja()
        onCloseModal()
      }).catch(error => console.log("error post jabatan", error))
  }

  const changeUnitKerja = (e) => {
    console.log("selec", e.label)
    setJabatan({ ...jabatan, unit_kerja_id: e.value })
    setUnitKerjaValue({ label: e.label, value: e.value });
  }

  const onCloseModal = () => {
    setOpen(false);
    getList();
    dispatch(set_option_default())
    setJabatan({ status: true })
  }

  return (
    <Fragment>
      <Modal
        size="md"
        show={isOpen}
        onHide={() => setOpen(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        animation={false}
      >
        <Modal.Header className="header-modal" closeButton>
          <Modal.Title
            className="title-modal"
            id="example-modal-sizes-title-lg"
          >
            Tambah Jabatan
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row reset-margin-row">
            <div className="col-md-12 content-modal">
              <div className="container-fluid">
                <div className="form-group">
                  <label htmlFor="lokasi">Satuan Kerja*</label>
                  <SelectSatuanKerja
                    placeholder="Pilih SKPD"
                    className="m-b-5"
                    list="listMasterSatuanKerja"
                    sourceReducer="ListSuratReducers"
                    defaultValue={{ value: jabatan?.satuan_kerja_id, label: jabatan?.satuanKerja?.name }}
                    onChange={(e) => {
                      getListUnitKerja(e.value);
                      setUnitKerjaValue(null)
                      setJabatan({ ...jabatan, satuan_kerja_id: e.value })
                    }}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="unit_kerja">Unit Kerja*</label>
                  <SelectSatuanKerja
                    placeholder="Pilih Unit Kerja"
                    className="m-b-5"
                    list="unitKerja"
                    sourceReducer="ListOptionReducers"
                    value={unitKerjaValue}
                    // defaultValue={{ value: jabatan?.unit_kerja_id, label: jabatan?.unitKerja?.name }}
                    onChange={changeUnitKerja}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="kode_jabatan">Kode Jabatan*</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => setJabatan({ ...jabatan, code: e.target.value })}
                    defaultValue={jabatan?.code}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="lokasi">Jabatan*</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => setJabatan({ ...jabatan, name: e.target.value })}
                    defaultValue={jabatan?.name}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="jft">Jenis*</label>
                  <Select
                    placeholder="Pilih Jenis"
                    className="m-b-5"
                    // defaultValue={{ value: 1, label: "JFT" }}
                    options={[
                      { value: 1, label: "JFT" }
                    ]}
                    // defaultValue={}
                    onChange={(e) => setJabatan({ ...jabatan, type_jabatan: e.value })}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="lokasi">Kode Referensi*</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => setJabatan({ ...jabatan, sapk_id: e.target.value })}
                  // defaultValue={data.klasifikasi && data.klasifikasi.kode}
                  />
                </div>
                <div className="form-group">
                  <div>Status</div>
                  <div style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "start"
                  }}>
                    <input
                      type="radio"
                      id="aktif"
                      name="status"
                      defaultChecked={jabatan.status === true}
                      onChange={() => setJabatan({ ...jabatan, status: true })}
                    />
                    <label style={{ marginLeft: "5px", marginRight: "8px", marginTop: "8px" }} htmlFor="aktif">Aktif</label>
                    <input
                      type="radio"
                      id="non-aktif"
                      name="status"
                      defaultChecked={jabatan.status === false}
                      onChange={() => setJabatan({ ...jabatan, status: false })}
                    />
                    <label style={{ marginLeft: "5px", marginTop: "8px" }} htmlFor="non-aktif">Non-Aktif</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="row reset-margin-row ">
            <div className="col-md-6 mb-3">
              {jabatan.id &&
                <Buttons
                  className="btn btn-danger mr-2"
                  children="Hapus"
                  onClick={deleteJabatan}
                />
              }
            </div>
            <div className="col-md-6 text-right mb-3">
              <Buttons
                onClick={() => setOpen(false)}
                className="btn btn-default mr-2"
                children="Batal"
              />
              {jabatan.id ?
                <Buttons
                  onClick={updateJabatan}
                  className="btn btn-primary"
                  children="Ubah"
                // loading={GeneralReducers.loading}
                />
                :
                <Buttons
                  onClick={postPegawai}
                  className="btn btn-primary"
                  children="Simpan"
                // loading={GeneralReducers.loading}
                />
              }
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default ModalComponent;
