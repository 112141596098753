import { SET_NOTIF, RESET_NOTIF, SET_BADGE } from "redux/rootRedux/TypeActions";

const GlobalState = {
  notifData: [],
  persetujuan_count: 0,
  surat_masuk: 0,
  konsep: 0,
  notifCount: 0,
};

export const NotificationReducers = (state = GlobalState, action) => {
  switch (action.type) {
    case SET_NOTIF:
      console.log("cek action notif", action.payload);
      let persetujuan, surat_masuk, konsep;
      action.payload.kategori === "persetujuan"
        ? (persetujuan = state.persetujuan_count + 1)
        : (persetujuan = state.persetujuan_count);
      action.payload.kategori === "surat-masuk"
        ? (surat_masuk = state.surat_masuk + 1)
        : (surat_masuk = state.surat_masuk);
      action.payload.kategori === "konsep"
        ? (konsep = state.konsep + 1)
        : (konsep = state.konsep);
      return {
        ...state,
        notifCount: persetujuan + surat_masuk + konsep,
        persetujuan_count: persetujuan,
        surat_masuk: surat_masuk,
        konsep: konsep,
        notifData: [action.payload, ...state.notifData],
      };
    case SET_BADGE:
      return {
        ...state,
        surat_masuk: action.payload.surat_masuk,
        konsep: action.payload.konsep,
        persetujuan_count: action.payload.persetujuan,
        notifCount: action.payload.total_notif,
      };
    case RESET_NOTIF:
      let update;
      if (action.payload === "listPersetujuan") {
        update = "persetujuan_count";
      } else if (action.payload === "listKonsep") {
        update = "konsep";
      } else {
        update = "surat_masuk";
      }
      return {
        ...state,
        notifData: [],
        // persetujuan_count: 0,
        // surat_masuk: 0,
        notifCount: 0,
        [update]: 0,
      };

    default:
      return state;
  }
};
