/* eslint-disable react-hooks/exhaustive-deps */
import Loading from "components/body/Loading";
import { getRequest } from "config/axios";
import React, { Fragment, useEffect, useState, useCallback } from "react";
import { Modal, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { checkError, setLoading } from "redux/GeneralReducers/GeneralActions";
import { set_value } from "redux/SuratReducers/BuatSurat/BuatSuratActions";
import { debounce } from "lodash";
import PrevBtn from "components/body/buttons/prevBtn";
import NextBtn from "components/body/buttons/nextBtn";
import Radio from "components/body/inputs/Radio";
// import InputsWithLabel from "components/body/inputs/InputsWithLabel";
import { useRef } from "react";
import MultipleInput from "components/body/inputs/MultipleInput";

const Penerima = () => {
  const dispatch = useDispatch();
  const { BuatSuratReducers } = useSelector((state) => state);
  const { penerima, tembusan } = BuatSuratReducers;
  const { type_tanda_tangan } = penerima;

  const [lgShow, setLgShow] = useState(false);
  const [title, setTitle] = useState(null);
  const [focus, setFocus] = useState(null);
  const [kepada, setKepada] = useState(BuatSuratReducers.penerima?.user_eksternal);
  const [penerimaTembusan, setTembusan] = useState(BuatSuratReducers.tembusan?.user_eksternal);

  let refKepada = useRef(null)
  let refTembusan = useRef(null)

  const refs = { penerima: refKepada, tembusan: refTembusan }
  const receiver = BuatSuratReducers[focus];
  const actionInput = { penerima: (e) => setKepada(e), tembusan: (e) => setTembusan(e) }

  const setModals = (data) => {
    setTitle(data);
    setLgShow(!lgShow);
  };

  const changeRadioBtn = (e, data) => {
    dispatch(
      set_value({
        value: { ...penerima, type_tanda_tangan: e.target.value, user: [], user_eksternal: [] },
        name: "penerima",
      })
    );
    dispatch(
      set_value({
        value: { ...tembusan, user: [], user_eksternal: [] },
        name: "tembusan",
      })
    );
    dispatch(set_value({ value: data, name: "tanda_tangan" }));
    resetForm();
  };

  const resetForm = () => {
    setFocus(null);
    setKepada([])
    setTembusan([])
  }

  const updateDispatch = (name, data) => {
    dispatch(
      set_value({
        value: {
          ...BuatSuratReducers[name],
          user_eksternal: data
        },
        name,
      })
    )
  }

  const inputPenerima = (e) => {
    if (refs[focus].current.value === ";") {
      refs[focus].current.value = "";
      actionInput[focus]("");
    }
    actionInput[focus](refs[focus].current.value);
  }
  const inputKeyPress = (e) => {
    if (e.key === ';') {
      const data = [...receiver.user_eksternal, refs[focus].current.value];
      updateDispatch(focus, data)
      refs[focus].current.value = "";
      actionInput[focus](refs[focus].current.value);
    }
  }

  const delPenerima = (name, data) => {
    const newData = BuatSuratReducers[name].user_eksternal.filter(item => item !== data);
    updateDispatch(name, newData)
  }

  const onFocusInput = (e) => {
    setFocus(e.target.name)
  }

  return (
    <Fragment>
      <Modals lgShow={lgShow} title={title} setLgShow={setLgShow} />
      <div className="mb-3 badge badge-primary">Internal/Eksternal</div>
      <div className="form-group row">
        <label htmlFor="jabatan" className="col-md-2 col-form-label">
          Alur Pemrosesan :
        </label>
      </div>
      <div className="form-group row">
        <div className=" col-md-12">
          <div className="custom-control custom-radio custom-control-inline">
            <Radio
              id="customRadioInline1"
              name="tanda_tangan"
              value="tanda tangan digital"
              label="Tanda Tangan Digital"
              checked={type_tanda_tangan === "tanda tangan digital"}
              onChange={(e) => changeRadioBtn(e, false)}
            />
          </div>
          <div className="custom-control custom-radio custom-control-inline">
            <Radio
              id="customRadioInline2"
              name="tanda_tangan"
              value="tanda tangan basah"
              label="Tanda Tangan Basah"
              checked={type_tanda_tangan === "tanda tangan basah"}
              onChange={(e) => changeRadioBtn(e, true)}
            />
          </div>
          <div className="custom-control custom-radio custom-control-inline">
            <Radio
              id="customRadioInline3"
              name="tanda_tangan"
              value="tanda tangan digital (eksternal)"
              label="Tanda Tangan Digital (Eksternal)"
              checked={type_tanda_tangan === "tanda tangan digital (eksternal)"}
              onChange={(e) => changeRadioBtn(e, false)}
            />
          </div>
        </div>
      </div>
      {(type_tanda_tangan === 'tanda tangan basah' || type_tanda_tangan === 'tanda tangan digital (eksternal)') && (
        <>
          <div className="form-group multiple-input">
            <MultipleInput
              label="Kepada"
              name="penerima"
              value={kepada}
              action={inputPenerima}
              multipeValue={penerima.user_eksternal}
              onKeyPress={inputKeyPress}
              delList={delPenerima}
              ref={refKepada}
              onFocus={onFocusInput}
            />
            {focus === 'penerima' &&
              <span className="multiple-info">*gunakan karakter semicolon " ; " untuk menambah penerima </span>
            }
          </div>
          <div className="form-group multiple-input">
            <MultipleInput
              label="Tembusan Eksternal"
              name="tembusan"
              value={penerimaTembusan}
              multipeValue={tembusan.user_eksternal}
              action={inputPenerima}
              onKeyPress={inputKeyPress}
              delList={delPenerima}
              ref={refTembusan}
              onFocus={onFocusInput}
            />
            {focus === 'tembusan' &&
              <span className="multiple-info">*gunakan karakter semicolon " ; " untuk menambah penerima </span>
            }
          </div>
        </>
      )}
      {/* {type_tanda_tangan === 'tanda tangan digital (eksternal)' && (
        <>
          <div className="form-group">
            <InputsWithLabel
              label="Kepada"
              value={penerima.user_eksternal}
              action={(e) =>
                dispatch(
                  set_value({
                    value: { ...penerima, user_eksternal: [e.target.value] },
                    name: "penerima",
                  })
                )
              }
            />
          </div>
          <div className="form-group">
            <InputsWithLabel
              label="Tembusan Eksternal"
              value={tembusan.user_eksternal}
              action={(e) =>
                dispatch(set_value({
                  value: { ...tembusan, user_eksternal: [e.target.value] },
                  name: "tembusan",
                }))
              }
            />
          </div>
        </>
      )} */}
      {type_tanda_tangan === 'tanda tangan digital' && (
        <>
          <div className="form-group">
            <label>
              <a href="# " onClick={() => setModals("Kepada")}>
                Kepada
              </a>
            </label>
            <div className="box-input-with-list font-12">
              <ol style={{ marginTop: "10px" }}>
                {penerima.user.map((view, i) => {
                  return (
                    <li key={i}>
                      {view.data_pegawai.peg_nama} -{" "}
                      {view.data_pegawai.jabatan_nama}
                    </li>
                  );
                })}
                {penerima.plt.length > 0 && (
                  <>
                    <hr />
                    {penerima.plt.map((view, i) => {
                      return (
                        <li key={i}>
                          {view.data_pegawai.peg_nama} - (PLT){" "}
                          {view.data_pegawai.jabatan_nama}
                        </li>
                      );
                    })}
                  </>
                )}
              </ol>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-12">
              <label htmlFor="tebusan">
                <a href="# " onClick={() => setModals("Tembusan")}>
                  Tembusan
                </a>
              </label>
              <div className="box-input-with-list font-12">
                <ol style={{ marginTop: "10px" }}>
                  {tembusan.user.map((view, i) => {
                    return (
                      <li key={i}>
                        {view.data_pegawai.peg_nama} -{" "}
                        {view.data_pegawai.jabatan_nama}
                      </li>
                    );
                  })}
                  {tembusan.plt.length > 0 && (
                    <>
                      <hr />
                      {tembusan.plt.map((view, i) => {
                        return (
                          <li key={i}>
                            {view.data_pegawai.peg_nama} - (PLT){" "}
                            {view.data_pegawai.jabatan_nama}
                          </li>
                        );
                      })}
                    </>
                  )}
                </ol>
              </div>
            </div>
          </div>
        </>
      )}


      <div className="col-md-12 flex-space-between">
        <PrevBtn to="pengirim" />
        <NextBtn to="pemeriksa" />
      </div>
    </Fragment>
  );
};

export default Penerima;

const Modals = (props) => {
  const [dataPegawai, setDataPegawai] = useState(null);
  const [dataSkpd, setDataSkpd] = useState(null);
  const dispatch = useDispatch();
  const { GeneralReducers, BuatSuratReducers, LoginReducers } = useSelector(
    (state) => state
  );
  const [selected, setSelected] = useState([]);
  const [selectedPlt, setSelectedPlt] = useState([]);
  const { penerima, tembusan } = BuatSuratReducers;
  const { title } = props;
  let {
    satuan_kerja_id,
    satuan_kerja_nama,
  } = LoginReducers.userInfo.data_pegawai.satuan_kerja;
  const [idSkpd, setIdSkpd] = useState({
    label: satuan_kerja_nama,
    value: satuan_kerja_id,
  });
  const [q_keywords, setQ] = useState("");

  const getSkpd = () => {
    getRequest(`/api/master/skpd`)
      .then((res) => {
        // console.log("respon list skpd", res);
        dispatch(setLoading(false));
        filterSkpd(res.data);
      })
      .catch((err) => dispatch(checkError(err)));
  };

  const filterSkpd = (data) => {
    let a = data.data.map((list) => {
      return { label: list.satuan_kerja_nama, value: list.satuan_kerja_id };
    });
    setDataSkpd(a);
  };

  const onSelectSkpd = (e) => setIdSkpd(e);

  const getPegawai = (current_page = 1, q = q_keywords, id = idSkpd.value) => {
    dispatch(setLoading(true));
    setQ(q);
    getRequest(
      `api/egs/list/pegawai?satuan_kerja_id=${id}&q=${q}&page=${current_page}&per_page=8`
    )
      .then((res) => {
        dispatch(setLoading(false));
        setDataPegawai(res.data);
      })
      .catch((err) => dispatch(checkError(err)));
  };

  const debounceSave = useCallback(
    debounce(
      (current_page, q_keywords, id) =>
        getPegawai(current_page, q_keywords, id),
      1000
    ),
    []
  );

  const addSelected = (data) => {
    const newSelect = {
      data_pegawai: {
        peg_nama: data.fullName,
        peg_nip: data.peg_nip,
        jabatan_nama: data.namaJabatan,
        is_plt: data.is_plt || false,
      },
    };
    if (data.is_plt) {
      let listPlt = selectedPlt.filter(
        (list) => list.data_pegawai.peg_nip !== data.peg_nip
      );
      setSelectedPlt([...listPlt, newSelect]);
    } else {
      let lists = selected.filter(
        (list) => list.data_pegawai.peg_nip !== data.peg_nip
      );
      setSelected([...lists, newSelect]);
    }
  };

  const delSelected = ({ data_pegawai: { is_plt, peg_nip } }) => {
    if (is_plt) {
      let listPlt = selectedPlt.filter(
        (list) => list.data_pegawai.peg_nip !== peg_nip
      );
      setSelectedPlt(listPlt);
    } else {
      let lists = selected.filter(
        (list) => list.data_pegawai.peg_nip !== peg_nip
      );
      setSelected(lists);
    }
  };

  const savePenerima = () => {
    if (props.title === "Kepada") {
      setValue("penerima", penerima);
    } else if (props.title === "Tembusan") {
      setValue("tembusan", tembusan);
    }
    props.setLgShow(false);
  };

  const setValue = (field, data) => {
    dispatch(
      set_value({
        value: {
          ...data,
          plt: selectedPlt,
          user: selected,
        },
        name: field,
      })
    );
  };

  const setSelectedProps = () => {
    if (title === "Kepada") {
      setSelected(BuatSuratReducers.penerima.user);
      setSelectedPlt(BuatSuratReducers.penerima.plt);
    } else {
      setSelected(BuatSuratReducers.tembusan.user);
      setSelectedPlt(BuatSuratReducers.tembusan.plt);
    }
  };

  useEffect(getPegawai, [idSkpd.value]);
  useEffect(() => debounceSave(1, q_keywords, idSkpd.value), [q_keywords]);
  useEffect(setSelectedProps, [title]);
  useEffect(getSkpd, []);

  return (
    <Fragment>
      <Modal
        size="lg"
        show={props.lgShow}
        onHide={() => props.setLgShow(false)}
        className="large-modal"
      >
        <Modal.Header className="header-modal" closeButton>
          <Modal.Title
            className="title-modal"
            id="example-modal-sizes-title-lg"
          >
            {props.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row reset-margin-row">
            <div className="col-md-9 content-modal">
              <div className="row">
                <div className="col-md-7 ">
                  <Select
                    placeholder="Pilih SKPD"
                    className="m-l-5 m-b-5"
                    options={dataSkpd}
                    defaultValue={idSkpd}
                    onChange={(e) => onSelectSkpd(e)}
                  />
                </div>
                <div className="col-md-5">
                  <div className="title-tab-second bg-white no-border-bottom text-right">
                    <span className="mr-3">
                      {dataPegawai && dataPegawai.current_page} dari{" "}
                      {dataPegawai && dataPegawai.last_page}
                    </span>
                    <i
                      className="fa fa-chevron-left mr-3 pointer-mouse"
                      onClick={() => {
                        dataPegawai.current_page > 1 &&
                          getPegawai(dataPegawai.current_page - 1);
                      }}
                    ></i>
                    <i
                      className="fa fa-chevron-right pointer-mouse"
                      onClick={() => {
                        dataPegawai.current_page < dataPegawai.last_page &&
                          getPegawai(dataPegawai.current_page + 1);
                      }}
                    ></i>
                  </div>
                </div>
              </div>

              <Loading isLoading={GeneralReducers.loading} />
              <Table striped bordered hover className="table-modal-content">
                <thead>
                  <tr>
                    <th>Nama</th>
                    <th>Unit</th>
                    <th>Jabatan</th>
                    <th>Nip</th>
                  </tr>
                </thead>
                <tbody>
                  {dataPegawai &&
                    dataPegawai.data.map((list, i) => {
                      return (
                        <tr
                          key={i}
                          className="pointer-mouse"
                          onClick={() => addSelected(list)}
                        >
                          <td>{list.fullName}</td>
                          <td>{list.satuanKerjaNama}</td>
                          <td>
                            {list.is_plt && <b>PLT - </b>} {list.namaJabatan}
                          </td>
                          <td>{list.peg_nip}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </div>

            <div className="col-md-3 content-modal">
              <div className="title-tab-second">Pilihan</div>
              {selected &&
                selected.map((list, i) => {
                  return (
                    <ul key={i} className="parent-list">
                      <li className="child-list">
                        {list.data_pegawai.peg_nama}{" "}
                        <i
                          className="fa fa-trash pointer-mouse"
                          onClick={() => delSelected(list)}
                        ></i>
                      </li>
                    </ul>
                  );
                })}
              {selectedPlt.length > 0 && (
                <div className="title-tab-second m-t-5">PLT : </div>
              )}

              {selectedPlt.length > 0 &&
                selectedPlt.map((list, i) => {
                  return (
                    <ul key={i} className="parent-list">
                      <li className="child-list">
                        {list.data_pegawai.peg_nama}{" "}
                        <i
                          className="fa fa-trash pointer-mouse"
                          onClick={() => delSelected(list)}
                        ></i>
                      </li>
                    </ul>
                  );
                })}
            </div>
          </div>
          <hr />
          <div className="row reset-margin-row ">
            <div className="col-md-5 mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Kata Kunci"
                value={q_keywords}
                onChange={(e) => setQ(e.target.value)}
              />
            </div>
            <div className="col-md-4 mb-3"></div>

            <div className="col-md-3 tabs-header-modal space-table mb-3">
              <button className="btn btn-primary mr-2" onClick={savePenerima}>
                Simpan
              </button>

              <button
                className="btn btn-primary"
                onClick={() => {
                  setSelected([]);
                  setSelectedPlt([]);
                }}
              >
                Hapus Semua
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};
