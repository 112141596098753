import Loading from "components/body/Loading";
import { getRequest } from "config/axios";
import React, { Fragment, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { checkError, setLoading } from "redux/GeneralReducers/GeneralActions";
import { set_value_surat_masuk } from "redux/SuratReducers/BuatSuratMasuk/BuatSuratMasukAction";

const ModalKodeKlasifikasi = (props) => {
  const [listKlasifikasi, setKlasifikasi] = useState(null);
  const [q, setQ] = useState("");

  const dispatch = useDispatch();
  const { GeneralReducers } = useSelector((state) => state);

  // const { penerima, tembusan } = BuatSuratMasukReducers;

  const getKlasifikasi = (current_page = 1) => {
    dispatch(setLoading(true));
    getRequest(`egs/master/klasifikasi?q=${q}&page=${current_page}&per_page=5`)
      .then((res) => {
        dispatch(setLoading(false));
        setKlasifikasi(res.data);
      })
      .catch((err) => dispatch(checkError(err)));
  };

  useEffect(getKlasifikasi, [q]);

  return (
    <Fragment>
      <Modal
        size="lg"
        show={props.kodeShow}
        onHide={() => props.setKodeShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header className="header-modal" closeButton>
          <Modal.Title
            className="title-modal"
            id="example-modal-sizes-title-lg"
          >
            KODE KLASIFIKASI
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row reset-margin-row">
            <div className="col-md-12 content-modal">
              <Loading isLoading={GeneralReducers.loading} />
              {listKlasifikasi &&
                listKlasifikasi.items.map((view, i) => {
                  return (
                    <List
                      key={i}
                      setKodeShow={props.setKodeShow}
                      childs={view.childs}
                      data={view}
                    />
                  );
                })}
            </div>
          </div>
          <hr />
          <div className="row reset-margin-row ">
            <div className="col-md-4 mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Kata Kunci"
                onChange={(e) => setQ(e.target.value)}
              />
            </div>
            <div className="col-md-5 mb-3"></div>
            <div className="col-md-3 tabs-header-modal space-table mb-3">
              <button className="btn col-md-12 btn-primary mr-2">Ok</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default ModalKodeKlasifikasi;

const List = ({ data, childs, setKodeShow }) => {
  const dispatch = useDispatch();
  let child = null;

  const { BuatSuratMasukReducers } = useSelector((state) => state);
  const { kepala_surat } = BuatSuratMasukReducers;
  const setKlasifikasi = (data) => {
    dispatch(
      set_value_surat_masuk({
        value: { ...kepala_surat, klasifikasi: data },
        name: "kepala_surat",
      })
    );
    setKodeShow(false);
  };
  if (childs) {
    child = childs.map((view, i) => (
      <List
        data={view}
        key={i}
        setKodeShow={setKodeShow}
        childs={view.childs}
      />
    ));
  }
  return (
    <ul style={{ listStyle: "none" }}>
      <li>
        <div className="nested-list">
          <div
            className={`pointer-mouse mt-1`}
            onClick={() => setKlasifikasi(data)}
          >
            <i className="fa fa-chevron-right"></i> {data.kode} {data.nama}
          </div>
          {/* <div className="line"></div> */}
        </div>
        {child && child}
      </li>
    </ul>
  );
};
