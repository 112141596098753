import React, { forwardRef, Fragment } from 'react'

function MultipleInput({
  value,
  label,
  action,
  type = "text",
  disabled = false,
  id,
  name,
  multipeValue = [],
  delList = () => null,
  onFocus = () => null,
  onKeyPress = () => null
}, ref) {
  return (
    <Fragment>
      <label htmlFor={id}>{label}</label>
      <div className="multiple-input-form">
        {/* <div className="multiple-input-form__value"> */}
        {multipeValue.map((item, i) => (
          <span key={i}>{item} <i className="fa fa-trash" onClick={() => delList(name, item)}></i></span>
        ))}
        {/* </div> */}
        <input
          type={type}
          name={name}
          id={id}
          disabled={disabled}
          className="form-control"
          value={value}
          onChange={action}
          onKeyPress={onKeyPress}
          ref={ref}
          onFocus={onFocus}
        />
      </div>
    </Fragment>
  )
}

export default forwardRef(MultipleInput)
