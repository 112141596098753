import { getRequest, postRequest } from "config/axios";
import React, { Fragment, useState, useEffect } from "react";
import { Modal, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";
import {
  checkError,
  setLoading,
  setStatus,
} from "redux/GeneralReducers/GeneralActions";
// import { set_value } from "redux/SuratReducers/BuatSurat/BuatSuratActions";
// import ModalTo from "./ModalTo";

const DisposisiAtauTeruskan = (props) => {
  const [modals, setModals] = useState(false);
  const [petunjuk, setPetunjuk] = useState([]);
  const dispatch = useDispatch();
  const { GeneralReducers } = useSelector((state) => state);
  const history = useHistory();
  const { id } = props.match.params;
  const [data, setData] = useState({
    user: [],
    plt: [],
    action: {
      is_disposisi: false,
      text: [],
      keterangan: null,
      is_private: false,
    },
  });

  const setUser = (lists, plt) => {
    setData({ ...data, user: lists, plt });
  };

  const setText = (e) => {
    let a = data.action.text.filter((list) => list !== e.target.value);
    e.target.checked
      ? setData({
        ...data,
        action: {
          ...data.action,
          text: [...data.action.text, e.target.value],
        },
      })
      : setData({
        ...data,
        action: { ...data.action, text: a },
      });
  };

  const filterNip = (data) => data.map((item) => item.peg_nip);

  const getMailType = () => {
    getRequest(`egs/list-aksi`)
      .then((res) => {
        setPetunjuk(res.data.items);
      })
      .catch((err) => dispatch(checkError(err)));
  };

  const disposisi = () => {
    dispatch(setLoading(true));
    let user = filterNip(data.user);
    let plt = filterNip(data.plt);
    let newData = {
      ...data,
      user,
      plt,
    };
    postRequest(`egs/mail-disposisi/${id}`, newData)
      .then((res) => {
        console.log("post request disposisi ", res);
        dispatch(setLoading(false));
        dispatch(setStatus({ status: "success disposisi", msg: "Success" }));
        setData({
          user: [],
          plt: [],
          action: {
            is_disposisi: false,
            text: [],
            keterangan: null,
            is_private: false,
          },
        });
        history.push(`/surat-masuk/daftar-surat`);
      })
      .catch((err) => dispatch(checkError(err)));
  };

  useEffect(getMailType, []);
  // console.log("daaaaaaaaa", data);
  return (
    <Fragment>
      <Modals modals={modals} setModals={setModals} setUser={setUser} />
      {GeneralReducers.status === "success disposisi" ? (
        <div className="alert-success alert">{GeneralReducers.msg}</div>
      ) : (
        <div className="form-group">
          <label htmlFor="pemeriksa">
            <b>Dispossi / Lanjutkan</b>
          </label>
        </div>
      )}
      <div className="form-group row">
        <label htmlFor="jabatan" className="col-md-2 col-form-label">
          Pilih Aksi
        </label>

        <div className=" col-md-8">
          <div className="custom-control custom-radio custom-control-inline">
            <input
              type="radio"
              id="customRadioInline1"
              name="aksi"
              className="custom-control-input"
              defaultChecked={data.action.is_disposisi}
              onChange={() =>
                setData({
                  ...data,
                  action: {
                    ...data.action,
                    is_disposisi: true,
                  },
                })
              }
            />
            <label
              className="custom-control-label"
              htmlFor="customRadioInline1"
            >
              Disposisi
            </label>
          </div>
          <div className="custom-control custom-radio custom-control-inline">
            <input
              type="radio"
              id="customRadioInline2"
              name="aksi"
              className="custom-control-input"
              defaultChecked={!data.action.is_disposisi}
              onChange={() =>
                setData({
                  ...data,
                  action: {
                    ...data.action,
                    is_disposisi: false,
                    is_private: false,
                  },
                })
              }
            />
            <label
              className="custom-control-label"
              htmlFor="customRadioInline2"
            >
              Teruskan
            </label>
          </div>
        </div>
      </div>
      {data.action.is_disposisi && (
        <div className="row m-10">
          {petunjuk.map((item, i) => {
            return (
              <div
                key={i}
                className="col-md-3 col-sm-5 mb-2 form-check form-check-inline"
              >
                <input
                  className="form-check-input"
                  type="checkbox"
                  id={`a${i}`}
                  value={item}
                  onChange={setText}
                />
                <label className="form-check-label" htmlFor={`a${i}`}>
                  {item}
                </label>
              </div>
            );
          })}
        </div>
      )}
      <div className="form">
        <label htmlFor="nomor_surat col-md-12">Kepada</label>
        <div className="form-inline mb-2">
          <a
            href="# "
            className="btn btn-primary col-md-1 mb-2"
            onClick={() => setModals(true)}
          >
            Tambah
          </a>
          <div className="col-md-10">
            <div className="box-input-with-list font-12">
              <ol style={{ marginTop: "10px" }}>
                {data.user.length > 0 &&
                  data.user.map((view, i) => {
                    return (
                      <li key={i}>
                        {view.peg_nama} - {view.namaJabatan}
                      </li>
                    );
                  })}
                {data.plt.length > 0 && <hr />}
                {data.plt.length > 0 &&
                  data.plt.map((view, i) => {
                    return (
                      <li key={i}>
                        {view.peg_nama} - {view.namaJabatan}
                      </li>
                    );
                  })}
              </ol>
            </div>
          </div>
        </div>
      </div>

      <div className="form-group">
        <label htmlFor="keterangan">Keterangan</label>
        <textarea
          type="text"
          className="form-control"
          value={data.action.keterangan || " "}
          onChange={(e) =>
            setData({
              ...data,
              action: { ...data.action, keterangan: e.target.value },
            })
          }
          rows="7"
        ></textarea>
      </div>
      {data.action.is_disposisi && (
        <div className="form-group row">
          <label htmlFor="jabatan" className="col-md-2 col-form-label">
            Tipe Disposisi
          </label>

          <div className=" col-md-8">
            <div className="custom-control custom-radio custom-control-inline">
              <input
                type="radio"
                id="terbuka"
                name="type"
                className="custom-control-input"
                defaultChecked={!data.action.is_private}
                onClick={(e) =>
                  setData({
                    ...data,
                    action: { ...data.action, is_private: false },
                  })
                }
              />
              <label className="custom-control-label" htmlFor="terbuka">
                Terbuka
              </label>
            </div>
            <div className="custom-control custom-radio custom-control-inline">
              <input
                type="radio"
                id="tertutup"
                name="type"
                className="custom-control-input"
                defaultChecked={data.action.is_private}
                onClick={(e) =>
                  setData({
                    ...data,
                    action: { ...data.action, is_private: true },
                  })
                }
              />
              <label className="custom-control-label" htmlFor="tertutup">
                Tertutup
              </label>
            </div>
          </div>
        </div>
      )}
      <div className="form-group">
        <button onClick={disposisi} className="btn btn-info m-lr-5">
          Submit
        </button>
      </div>
    </Fragment>
  );
};

export default withRouter(DisposisiAtauTeruskan);

const Modals = (props) => {
  const [dataPegawai, setDataPegawai] = useState(null);
  const dispatch = useDispatch();
  const { GeneralReducers } = useSelector((state) => state);
  const [selected, setSelected] = useState([]);
  const [selectedPlt, setSelectedPlt] = useState([]);
  const [q, setQ] = useState("");
  const { modals, setModals, setUser } = props;

  const getPegawai = (current_page = 1) => {
    dispatch(setLoading(true));
    getRequest(`api/egs/list/pegawai?with_kontrak=${true}&q=${q}&page=${current_page}&per_page=10`)
      .then((res) => {
        dispatch(setLoading(false));
        setDataPegawai(res.data);
      })
      .catch((err) => {
        dispatch(checkError(err));
      });
  };

  const addSelected = (data) => {
    if (data.is_plt) {
      let plt = selectedPlt.filter((list) => list.peg_nip !== data.peg_nip);
      setSelectedPlt([...plt, data]);
    } else {
      let lists = selected.filter((list) => list.peg_nip !== data.peg_nip);
      setSelected([...lists, data]);
    }
  };

  const delSelected = (data) => {
    if (data.is_plt) {
      let lists = selectedPlt.filter((list) => list.peg_nip !== data.peg_nip);
      setSelectedPlt(lists);
    } else {
      let lists = selected.filter((list) => list.peg_nip !== data.peg_nip);
      setSelected(lists);
    }
  };

  const savePenerima = () => {
    setUser(selected, selectedPlt);
    setModals(false);
  };

  const setSelectedProps = () => { };

  useEffect(setSelectedProps, [modals]);
  useEffect(getPegawai, [q]);

  return (
    <Fragment>
      <Modal
        size="lg"
        show={modals}
        onHide={() => setModals(false)}
        className="large-modal"
      // aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header className="header-modal" closeButton>
          <Modal.Title
            className="title-modal"
            id="example-modal-sizes-title-lg"
          >
            Kepada
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row reset-margin-row">
            <div className="col-md-9 content-modal">
              <div className="title-tab-second bg-white no-border-bottom text-right">
                <span className="mr-3">
                  {dataPegawai && dataPegawai.current_page} dari{" "}
                  {dataPegawai && dataPegawai.last_page}
                </span>
                <i
                  className="fa fa-chevron-left mr-3 pointer-mouse"
                  onClick={() => {
                    dataPegawai.current_page > 1 &&
                      getPegawai(dataPegawai.current_page - 1);
                  }}
                ></i>
                <i
                  className="fa fa-chevron-right pointer-mouse"
                  onClick={() => {
                    dataPegawai.current_page < dataPegawai.last_page &&
                      getPegawai(dataPegawai.current_page + 1);
                  }}
                ></i>
              </div>
              <Table striped bordered hover className="table-modal-content">
                <thead>
                  <tr>
                    <th>Nama</th>
                    <th>Unit</th>
                    <th>Jabatan</th>
                    <th>Nip</th>
                  </tr>
                </thead>
                <tbody>
                  {GeneralReducers.loading ? (
                    <tr>
                      <td colSpan="4" align="center">
                        <i className="fa fa-spinner fa-pulse fa-fw"></i>
                      </td>
                    </tr>
                  ) : (
                    dataPegawai &&
                    dataPegawai.data.map((list, i) => {
                      return (
                        <tr
                          key={i}
                          className="pointer-mouse"
                          onClick={() => addSelected(list)}
                        >
                          <td>{list.peg_nama}</td>
                          <td>{list.satuanKerjaNama}</td>
                          <td>
                            {list.is_plt && `PLT - `} {list.namaJabatan}
                          </td>
                          <td>{list.peg_nip}</td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </Table>
            </div>
            <div className="col-md-3 content-modal">
              <div className="title-tab-second">Pilihan</div>
              {selected &&
                selected.map((list, i) => {
                  return (
                    <ul key={i} className="parent-list">
                      <li className="child-list">
                        {list.peg_nama}{" "}
                        <i
                          className="fa fa-trash pointer-mouse"
                          onClick={() => delSelected(list)}
                        ></i>
                      </li>
                    </ul>
                  );
                })}
              {selectedPlt.length > 0 && (
                <div className="title-tab-second m-t-5">PLT : </div>
              )}
              {selectedPlt &&
                selectedPlt.map((list, i) => {
                  return (
                    <ul key={i} className="parent-list">
                      <li className="child-list">
                        {list.peg_nama}{" "}
                        <i
                          className="fa fa-trash pointer-mouse"
                          onClick={() => delSelected(list)}
                        ></i>
                      </li>
                    </ul>
                  );
                })}
            </div>
          </div>
          <hr />
          <div className="row reset-margin-row ">
            <div className="col-md-4 mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Kata Kunci"
                onChange={(e) => setQ(e.target.value)}
              />
            </div>
            <div className="col-md-4 mb-3"></div>

            <div className="col-md-4 tabs-header-modal space-table mb-3">
              <button className="btn btn-primary mr-2" onClick={savePenerima}>
                Simpan
              </button>
              {selected.length > 0 && (
                <button
                  className="btn btn-primary"
                  onClick={() => setSelected([])}
                >
                  Hapus Semua
                </button>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};
