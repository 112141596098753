import React, { Component, Fragment } from "react";
import Navbar from "components/header/navbar";
import Sidebar from "components/sidebar/sidebar";
import SubNavbarWrapper from "components/header/subNavbarWrapper";
import Footer from "components/footers/footer";
import Content from "pages/DashboardPage/cards/content";
import ContentWrapper from "components/body/contentWrapper";
import { connect } from "react-redux";
// import { store } from "redux/rootRedux/Store";

class Dashboard extends Component {
  render() {
    return (
      <Fragment>
        <div className="background-dark"></div>
        <Navbar />
        <Sidebar />
        <SubNavbarWrapper />
        <ContentWrapper>
          <Content />
        </ContentWrapper>
        <Footer />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  state,
});

export default connect(mapStateToProps, null)(Dashboard);
