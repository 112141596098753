import React, { Fragment } from "react";
import { Tab, Tabs } from "react-bootstrap";
import DaftarSuratSekretariat from "./daftarSuratSekretariat";
import DaftarSuratNonSekretariat from "./daftarSuratNonSekretariat";

const Content = (props) => {
  const { setKey, keysTab } = props;
  return (
    <Fragment>
      <Tabs
        id="controlled-tab-example"
        activeKey={keysTab}
        className="tabs-header"
        // tabs-link
        onSelect={(k) => setKey(k)}
      >
        <Tab
          eventKey="daftarSuratMasukNonSekretariat"
          title="Surat Masuk"
          className="tabs-link2"
        >
          <DaftarSuratNonSekretariat />
        </Tab>
        <Tab
          eventKey="daftarSuratMasukSekretariat"
          title="Surat Masuk Sekretariat"
          className="tabs-link"
        >
          <DaftarSuratSekretariat />
        </Tab>
      </Tabs>
    </Fragment>
  );
};

export default Content;
