/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { VictoryBar, VictoryChart, VictoryTheme } from "victory";
import Select from "react-select";
import { getRequest } from "config/axios";
import { useDispatch } from "react-redux";
import { checkError } from "redux/GeneralReducers/GeneralActions";
import { useEffect } from "react";
import moment from "moment";
import Layout from "layout";

function Content() {
  const [data, setData] = useState(null);
  const [date, setDate] = useState(new Date());
  const dispatch = useDispatch();

  let monthYear = moment(date).format("MMMM YYYY");
  const getData = () => {
    let month = date.getMonth() + 1;
    getRequest(`/egs/statistik/count?month=${month}&year=${date.getFullYear()}`)
      .then((res) => {
        setData(res.data.items);
      })
      .catch((err) => dispatch(checkError(err)));
  };

  useEffect(getData, [date]);

  return (
    <Layout
      subnavbar={{ type: "standart_header", title: "Tampilan Statistik" }}
    >
      <div className="form-row">
        <div className="col-md-10">
          <label htmlFor="sifat_penyampaian">Tanggal *</label>
          <div className="form-row">
            <div className="form-group input-group-ico">
              <DatePicker
                placeholderText="Tanggal"
                className="form-control"
                selected={date}
                onChange={(date) => setDate(date)}
                dateFormat="MMMM yyyy"
                showMonthYearPicker
              />
              <i className="fa fa-calendar-o"></i>
            </div>
          </div>
        </div>
      </div>
      <div className="box-wrapper">
        <BoxInfo
          title="Jumlah Surat Disposisi"
          date={monthYear}
          value={data && data.count_disposisi}
        />
        <BoxInfo
          title="Jumlah Surat Diterima"
          date={monthYear}
          value={data && data.count_receive}
        />
        <BoxInfo
          title="Jumlah Surat Diperiksa"
          date={monthYear}
          value={data && data.count_pemeriksa}
        />
        <BoxInfo
          title="Jumlah Surat Disetujui"
          date={monthYear}
          value={data && data.count_approve}
        />
        <BoxInfo
          title="Jumlah Surat Diteruskan"
          date={monthYear}
          value={data && data.count_forward}
        />
      </div>

      <div className="chart-wrapper">
        <Chart year={date.getFullYear()} />
      </div>
    </Layout>
  );
}

export default Content;

const BoxInfo = ({ title, date, value }) => {
  return (
    <div className="box-info">
      <div className="title">{title}</div>
      <span>{date}</span>
      <div className="value">{value}</div>
      <i className="fa fa-envelope"></i>
    </div>
  );
};

const Chart = ({ year }) => {
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [dataKlasifikasi, setDataKlasifikasi] = useState([
    { value: 0, label: "Pilih" },
  ]);
  const [idKlasifikasi, setIdKlasifikasi] = useState(0);
  let month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "Mei",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Okt",
    "Nov",
    "Des",
  ];

  const changeData = (data) => {
    return data.map((item) => ({
      ...item,
      month: month[item.month - 1],
    }));
  };

  const setOptions = (data) =>
    data.map((item) => ({ value: item._id, label: item.nama }));

  const getKlasifikasi = (q = "") => {
    getRequest(`egs/master/klasifikasi?q=${q}`)
      .then((res) => {
        let a = setOptions(res.data.items);
        setDataKlasifikasi(a);
      })
      .catch((err) => dispatch(checkError(err)));
  };

  const getData = (idKlasifikasi = 0, year) => {
    getRequest(`egs/statistik/klasifikasi?id=${idKlasifikasi}&year=${year}`)
      .then((res) => {
        let resData = changeData(res.data.items);
        setData(resData);
      })
      .catch((err) => dispatch(checkError(err)));
  };

  useEffect(() => getData(idKlasifikasi, year), [year]);
  useEffect(() => getData(idKlasifikasi, year), [idKlasifikasi]);
  useEffect(getKlasifikasi, []);
  return (
    <div className="my-chart">
      <div className="title">Data Analitik Klasifikasi Jenis Surat</div>
      {/* <div className="date">Lorem.</div> */}
      <div className="input-data m-tb-20">
        <Select
          placeholder="Pilih Pendidikan / Kesehatan / Inovasi / Peralatan / Lain-Lain"
          className="select-class"
          options={dataKlasifikasi}
          onChange={(e) => setIdKlasifikasi(e.value)}
        />
      </div>
      <div className="my-chart-body">
        <VictoryChart theme={VictoryTheme.grayscale} domainPadding={20}>
          {data && (
            <VictoryBar
              cornerRadius={{ top: 8, bottom: 8 }}
              style={{
                data: {
                  fill: "#c43a31",
                  width: 15,
                },
              }}
              data={data}
              width={900}
              x="month"
              y="value"
            />
          )}
        </VictoryChart>
      </div>
    </div>
  );
};
