import React from "react";
// import Navbar from "components/header/navbar";
// import Sidebar from "components/sidebar/sidebar";
// import SubNavbarWrapper from "components/header/subNavbarWrapper";
// import Footer from "components/footers/footer";
// import ContentWrapper from "components/body/contentWrapper";
import Content from "./cards/content";
// import SubNavbar from "./cards/subNavbar";
import Layout from "layout";

const MasterTabelArsip = () => {
  return (
    <Layout subnavbar={{ type: "standart_header", title: "Tabel Arsip" }}>
      <Content />
    </Layout>
  );
}

export default MasterTabelArsip;
