import React, { Fragment, useEffect, useState } from "react";
import { getRequest } from "config/axios";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { checkError, setLoading } from "redux/GeneralReducers/GeneralActions";
import { set_value } from "redux/SuratReducers/BuatSurat/BuatSuratActions";
import Loading from "components/body/Loading";

const ModalKodeKlasifikasi = (props) => {
  const [dataKlasifikasi, setDataKlasifikasi] = useState(null);
  const [q, setQ] = useState("");
  const [selectedKlasifikasi, setSelectedKlasifikasi] = useState(null);
  const dispatch = useDispatch();
  const { GeneralReducers } = useSelector((state) => state);

  const getList = (current_page = 1) => {
    dispatch(setLoading(true));
    getRequest(`egs/master/klasifikasi?q=${q}&page=${current_page}&per_page=5`)
      .then((res) => {
        dispatch(setLoading(false));
        setDataKlasifikasi(res.data.items);
      })
      .catch((err) => {
        dispatch(checkError(err));
      });
  };

  const select_klasifikasi = (selected) => {
    setSelectedKlasifikasi(selected);
  };

  useEffect(getList, [q]);

  return (
    <Fragment>
      <Modal
        size="lg"
        show={props.kodeModal}
        onHide={() => props.setKodeModal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header className="header-modal" closeButton>
          <Modal.Title
            className="title-modal"
            id="example-modal-sizes-title-lg"
          >
            KODE KLASIFIKASI
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row reset-margin-row mt-3">
            <div className="col-md-12 content-modal">
              <Loading isLoading={GeneralReducers.loading} />
              {dataKlasifikasi &&
                dataKlasifikasi.map((view, i) => {
                  return (
                    <List
                      key={i}
                      setKodeModal={props.setKodeModal}
                      select_klasifikasi={select_klasifikasi}
                      childs={view.childs}
                      data={view}
                      dataKlasifikasi={dataKlasifikasi}
                      selectedKlasifikasi={selectedKlasifikasi}
                    />
                  );
                })}
            </div>
          </div>
          <hr />
          <div className="row reset-margin-row ">
            <div className="col-md-4 mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Kata Kunci"
                onChange={(e) => setQ(e.target.value)}
              />
            </div>
            <div className="col-md-5 mb-3"></div>
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default ModalKodeKlasifikasi;

const List = ({
  data,
  childs,
  setKodeModal,
  select_klasifikasi,
  selectedKlasifikasi,
  dataKlasifikasi,
}) => {
  const dispatch = useDispatch();
  let child = null;
  const { BuatSuratReducers } = useSelector((state) => state);
  const { kepala_surat } = BuatSuratReducers;
  const setKlasifikasi = (data) => {
    dispatch(
      set_value({
        value: { ...kepala_surat, klasifikasi: data },
        name: "kepala_surat",
      })
    );
    setKodeModal(false);
  };
  if (childs) {
    child = childs.map((view, i) => (
      <List
        data={view}
        key={i}
        setKodeModal={setKodeModal}
        childs={view.childs}
        select_klasifikasi={select_klasifikasi}
        dataKlasifikasi={dataKlasifikasi}
        selectedKlasifikasi={selectedKlasifikasi}
      />
    ));
  }
  return (
    <ul style={{ listStyle: "none" }}>
      <li>
        <div className="nested-list">
          <div
            className={`pointer-mouse mt-1 ${
              data.active_selected && `active-selected`
            }`}
            onClick={() => setKlasifikasi(data)}
          >
            <i className="fa fa-chevron-right"></i> {data.kode} {data.nama}
          </div>
        </div>
        {child && child}
      </li>
    </ul>
  );
};
