import React, { Fragment, useCallback, useEffect, useState } from "react";
import Paginate from "components/footers/paginate";
import { useDispatch, useSelector } from "react-redux";
import {
  decrement,
  increment,
  setFirstPage,
  setLastPage,
} from "utils/pagination";
import { getListMethod } from "redux/SuratReducers/ListSuratMasuk/ListSuratAction";
import Loading from "components/body/Loading";
import ModalComponent from "./modals";
import SelectSatuanKerja from "components/body/selects/selectSatuanKerja";

const Content = () => {
  const dispatch = useDispatch();
  const { GeneralReducers, ListSuratReducers } = useSelector((state) => state);
  const [isOpen, setOpen] = useState(null);
  const [unitKerja, setUnitKerja] = useState({
    status: true,
    type_unit_kerja: 3,
  });
  const { loading } = GeneralReducers;
  const { items, meta } = ListSuratReducers.listMasterUnitKerja;

  const getList = (current_page = 1) => {
    dispatch(getListMethod(`pegawai-egs/api/satuan-kerja`, "listMasterSatuanKerja", current_page));
    dispatch(getListMethod(`pegawai-egs/api/unit-kerja`, "listMasterUnitKerja", current_page));
  };

  const changeFilterList = (e) => {
    dispatch(getListMethod(`pegawai-egs/api/unit-kerja`, "listMasterUnitKerja", 1, e.value));
  }

  const editUnitKerja = useCallback((item) => {
    setOpen(true);
    setUnitKerja({ ...unitKerja, ...item })
  }, [unitKerja]);

  useEffect(getList, []);

  return (
    <Fragment>
      <ModalComponent
        isOpen={isOpen}
        setOpen={setOpen}
        unitKerja={unitKerja}
        getList={getList}
        setUnitKerja={setUnitKerja}
      />
      <div className="content-title row">
        <div className="col-md-12 col-sm-12 mb-2">
          <a
            onClick={() => setOpen(true)}
            href="# "
            className="btn btn-sm btn-primary"
          >
            <i className="fa fa-plus"></i> Tambah Unit Kerja
          </a>
        </div>
        <div className="col-md-12 col-sm-12">
          <SelectSatuanKerja
            placeholder="Pilih SKPD"
            className="m-b-5"
            list="listMasterSatuanKerja"
            sourceReducer="ListSuratReducers"
            // defaultValue={{ value: pegawai.unit_kerja_id, label: pegawai?.unitKerja?.name }}
            onChange={changeFilterList}
          />
        </div>
      </div>
      <div className="content-body">
        <Loading isLoading={loading} />
        <table className="table table-striped table-list">
          <thead>
            <tr>
              <td>Satuan Kerja</td>
              <td>Unit Kerja</td>
              <td>Kode</td>
              <td>Opsi</td>
            </tr>
          </thead>
          <tbody>
            {items &&
              items.map((item, i) => {
                return (
                  <tr key={i}>
                    <td>
                      {item.SatuanKerja.name}
                    </td>
                    <td>{item.name}</td>
                    <td>
                      {item.code}
                    </td>
                    <td>
                      <button className="btn btn-sm btn-default" onClick={() => editUnitKerja(item)}>
                        <i className="fa fa-pencil"></i>
                      </button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      {meta && (
        <Paginate
          increment={() => increment(meta.current_page, getList)}
          meta={meta}
          decrement={() => decrement(meta.current_page, getList)}
          setLastPage={() => setLastPage(meta.last_page, getList)}
          setFirstPage={() => setFirstPage(meta.from, getList)}
        />
      )}
    </Fragment>
  );
};

export default Content;
