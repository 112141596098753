import React, { Fragment, useEffect, useState } from "react";
import { checkError, setLoading } from "redux/GeneralReducers/GeneralActions";

import EditKontrak from "./EditKontrak";
import Loading from "components/body/Loading";
import ModalComponent from "./modals";
import Select from "react-select";
import { filterMaster } from "utils/filter-master"
import getListFromRedux from 'utils/list-surat-helper';
import { getRequest } from "config/axios";
import { useDispatch } from "react-redux";

const Content = () => {
  const dispatch = useDispatch();
  const [isOpenEdit, setOpenEdit] = useState(false);
  const { GeneralReducers } = getListFromRedux();
  const [isOpenAdd, setOpenAdd] = useState(null);
  const { loading } = GeneralReducers;
  const [dataSkpd, setDataSkpd] = useState(null);
  const [tenagaKontrak, setTenagaKontrak] = useState(null);
  const [idTenagaKontrak, setIdTenagaKontrak] = useState(null);
  const [idSkpd, setIdSkpd] = useState(0);
 
  const getSkpd = () => {
    dispatch(setLoading(true));
    getRequest(`egs/pegawai-egs/api/satuan-kerja`)
      .then((res) => {
        dispatch(setLoading(false));
        filterMaster(setDataSkpd,res.data);
      })
      .catch((err) => dispatch(checkError(err)));
  };

  const getTenagaKontrak = (id= idSkpd) => {
    dispatch(setLoading(true));
    getRequest(`egs/pegawai-egs/api/pegawai-kontrak?satuan_kerja_id=${id}`)
      .then((res) => {
        setTenagaKontrak(res.data.items)
        dispatch(setLoading(false));
      })
      .catch((err) => dispatch(checkError(err)));
  };
  const onSelectSkpd = (e) => {
    setIdSkpd(e.value);
  };
  const onSelectIdDetailKontrak = id => {
    setIdTenagaKontrak(id)
    setOpenEdit(true)
  }
  useEffect(getSkpd, []);  
  useEffect(getTenagaKontrak, [idSkpd]);


  return (
    <Fragment>
      {
        isOpenAdd &&       <ModalComponent
              isOpenAdd={isOpenAdd}
              setOpenAdd={setOpenAdd}
              idSkpd={idSkpd}
              idTenagaKontrak={idTenagaKontrak}
              getTenagaKontrak={getTenagaKontrak}
            />
      }
      {
        isOpenEdit &&       <EditKontrak
              isOpenEdit={isOpenEdit}
              setOpenEdit={setOpenEdit}
              idSkpd={idSkpd}
              idTenagaKontrak={idTenagaKontrak}
              getTenagaKontrak={getTenagaKontrak}
            />
      }
      
      <div className="content-title row">
        <div className="col-md-12 col-sm-12 mb-2">
          <a
            onClick={() => setOpenAdd(true)}
            href="# "
            className="btn btn-sm btn-primary"
          >
            <i className="fa fa-plus"></i> Tambah Tenaga Kontrak
          </a>
        </div>
        <div className="col-md-12 col-sm-12">
            <Select
            placeholder="Pilih SKPD"
            className="m-l-5 m-b-5"
            options={dataSkpd}
            onChange={(e) => onSelectSkpd(e)}
            />
        </div>
      </div>
      <div className="content-body">
        <Loading isLoading={loading} />
        <table className="table table-striped table-list">
          <thead>
            <tr>
              <td>Nama</td>
              <td>NIK</td>
              <td>Satuan Kerja</td>
              <td>Unit Kerja</td>
              <td>Jabatan</td>
              <td>Edit</td>
            </tr>
          </thead>
          { <tbody>
            {tenagaKontrak &&
              tenagaKontrak.map((list, i) => {
                return (
                  <tr key={i}>
                    <td>{list.name}</td>
                    <td>{list.nik}</td>
                    <td>{list.satuanKerja.name}</td>
                    <td>{list.unitKerja.name}</td>
                    <td>-</td>
                    {/* <td>
                      <i className="fa fa-toggle-on"></i>
                    </td> */}
                    <td>
                      <a href="# " onClick={() => onSelectIdDetailKontrak(list.id)}>
                        <i className="fa fa-edit"></i>
                      </a>
                    </td>
                  </tr>
                );
              })
            }
          </tbody> }
        </table>
      </div>
    </Fragment>
  );
};

export default Content;
