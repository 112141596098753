import { getRequest } from "config/axios";
import { setLoading } from "redux/GeneralReducers/GeneralActions";
import { store } from "redux/rootRedux/Store";
import { SET_OPTION_SELECT, SET_OPTION_DEFAULT } from "redux/rootRedux/TypeActions";

export const set_option_select = (data) => ({
	type: SET_OPTION_SELECT,
	payload: data,
});
export const set_option_default = () => ({
	type: SET_OPTION_DEFAULT,
});

const filterDouble = (e) => {
	return e.filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);
};

export const getListSelect = (
	link,
	name,
	satuan_kerja_id = 0
) => {
	return (dispatch) => {
		dispatch(setLoading(true));
		const { start_date, end_date } = store.getState().ListSuratReducers;
		const satuan_kerja = store.getState().LoginReducers;
		const satuan_kerja_id_store = satuan_kerja.userInfo.data_pegawai.satuan_kerja.satuan_kerja_id || satuan_kerja_id;
		getRequest(
			`egs/${link}?satuan_kerja_id=${satuan_kerja_id_store}&start_date=${start_date}&end_date=${end_date}&page=1&per_page=10`
		)
			.then((res) => {
				dispatch(setLoading(false));
				const resultFilter = filterDouble(res.data.items);
				dispatch(
					set_option_select({ name, value: { ...res.data, items: resultFilter } })
				);
			})
			.catch((err) => console.log("error get list select", err));
	};
};
