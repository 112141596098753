import moment from "moment";
import DatePicker from "react-datepicker";
import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getListMethod,
  set_default,
  set_list,
} from "redux/SuratReducers/ListSuratMasuk/ListSuratAction";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";

function SubNavbar(props) {
  const dispatch = useDispatch();
  const { ListSuratReducers } = useSelector((state) => state);
  const {
    type,
    title,
    keysTab,
    print_btn,
    path = null,
    reset_btn = true,
  } = props;

  const { start_date, end_date, q } = ListSuratReducers;

  const set_date = (date, key) => {
    let new_date = moment(date).format("YYYY-MM-DD");
    dispatch(set_list({ name: key, value: new_date }));
  };

  const find_by_word = (data) => {
    dispatch(set_list({ name: "q", value: data }));
  };

  const handleFind = (keys) => {
    if (keys === "daftarSuratMasukNonSekretariat") {
      dispatch(getListMethod("mail", "daftarSuratMasukNonSekretariat"));
    } else if (keys === "daftarSuratMasukSekretariat") {
      dispatch(
        getListMethod("mail-sekretariat", "daftarSuratMasukSekretariat")
      );
    } else if (keys === "persetujuan") {
      dispatch(getListMethod("persetujuan/mail", "listPersetujuan"));
    } else if (keys === "telusuri") {
      dispatch(getListMethod("telusuri/mail", "listTelusuri"));
    } else if (keys === "konsep") {
      dispatch(getListMethod("konsep/mail", "listKonsep"));
    } else if (keys === "terkirim-internal") {
      dispatch(getListMethod("terkirim/mail", "listTerkirimInternal"));
    } else if (keys === "reject") {
      dispatch(getListMethod("reject/mail", "listSuratBatal"));
    } else if (keys === "terkirim-external") {
      dispatch(getListMethod("terkirim/mail-sekretariat", "listTerkirimExternal"));
    } else if (keys === "listLogs") {
      dispatch(getListMethod("esign-activity", keys));
    } else if (keys === "listBankSurat") {
      dispatch(getListMethod("master/bank-mail-keluar", keys));
    } else if (keys === "listBankSuratKeluar") {
      dispatch(getListMethod("master/bank-mail-keluar", keys));
    } else if (keys === "listBankSuratMasuk") {
      dispatch(getListMethod("master/bank-mail-masuk-internal", keys));
    } else if (keys === "listBankSuratMasukSekretariat") {
      dispatch(getListMethod("master/bank-mail-masuk", keys));
    } else if (keys === "listMasterSatuanKerja") {
      dispatch(getListMethod("pegawai-egs/api/satuan-kerja", keys));
    } else if (keys === "listMasterUnitKerja") {
      dispatch(getListMethod(`pegawai-egs/api/unit-kerja/?data`, keys));
    } else if (keys === "listMasterPegawai") {
      dispatch(getListMethod("pegawai-egs/api/pegawai", keys));
    } else if (keys === "listMasterJabatan") {
      dispatch(getListMethod("pegawai-egs/api/jabatan", keys));
    }
  };

  const handleReset = () => {
    dispatch(set_default());
    handleFind(keysTab);
  };

  useEffect(handleReset, [title]);
  useEffect(handleReset, [keysTab]);
  return (
    <Fragment>
      {type === "standart_header" ? (
        <div className="label-form-sub-nav m-0-10">{title}</div>
      ) : (
        <>
          <div className="label-form-sub-nav m-0-10">{title}</div>
          <div className="input-form-sub-nav m-0-10">
            <div className="input-date-sub-nav">
              <DatePicker
                placeholderText="Dari"
                onChange={(e) => set_date(e, "start_date")}
                value={start_date}
              />
              <div className="icon-input-date-sub-nav">
                <i className="fa fa-calendar-o"></i>
              </div>
            </div>
            <div className="input-date-sub-nav">
              <DatePicker
                placeholderText="Hingga"
                onChange={(e) => set_date(e, "end_date")}
                value={end_date}
              />
              <div className="icon-input-date-sub-nav">
                <i className="fa fa-calendar-o"></i>
              </div>
            </div>
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder="Cari Berdasarkan perihal"
                value={q}
                onChange={(e) => find_by_word(e.target.value)}
              />
              <div className="input-group-append">
                <button
                  className="btn btn-primary"
                  type="button"
                  id="button-addon2"
                  onClick={() => handleFind(keysTab)}
                >
                  Cari
                </button>
              </div>
            </div>
          </div>
          <div className="ex-btn-form-sub-nav m-0-10">
            {reset_btn &&
              <a href="# " onClick={handleReset}>
                <i className="fa fa-rotate-right"></i>
              </a>
            }
            {print_btn &&
              <Link to={{
                pathname: `${path}${keysTab}`,
              }} rel="noopener noreferrer" className="m-l-10">
                <i className="fa fa-print"></i>
              </Link>
            }
          </div>
        </>
      )}
    </Fragment>
  );
}

export default SubNavbar;
