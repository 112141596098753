import Buttons from "components/body/buttons/Button";
import { delRequest } from "config/axios";
import React, { Fragment } from "react";
import { Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { setLoading, setStatus } from "redux/GeneralReducers/GeneralActions";

const ModalLihatFoto = (props) => {
  const dispatch = useDispatch();
  const { GeneralReducers } = useSelector((state) => state);
  const delKopSurat = (id) => {
    dispatch(setLoading(true));
    delRequest(`egs/master/kop-surat/${id}`)
      .then((res) => {
        dispatch(setLoading(false));
        dispatch(
          setStatus({
            status: "success kop-surat",
            msg: "Data berhasil di hapus",
          })
        );
        props.setModalFoto(false);
      })
      .catch((err) => {
        dispatch(setLoading(false));
        props.setModalFoto(false);
        dispatch(setStatus({ status: "fail", msg: "Data gagal dihapus" }));
      });
  };
  return (
    <Fragment>
      <Modal
        size="md"
        show={props.modalFoto}
        onHide={() => props.setModalFoto(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header className="header-modal" closeButton>
          <Modal.Title
            className="title-modal"
            id="example-modal-sizes-title-lg"
          >
            Lihat Berkas
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row reset-margin-row">
            <div className="col-md-12 content-modal">
              <div className="container-fluid">
                {props.files &&
                  props.files.map((view, i) => {
                    return (
                      <img
                        key={i}
                        src={view.file_url}
                        width="100%"
                        alt={view.file_name}
                        className="rounded mx-auto d-block"
                      />
                    );
                  })}
              </div>
            </div>
          </div>
          <hr />
          <div className="row reset-margin-row ">
            <div className="col-md-12 text-right mb-3">
              <Buttons
                className="btn btn-primary"
                children="Simpan"
                disabled={GeneralReducers.loading}
                loading={GeneralReducers.loading}
                onClick={() => delKopSurat(props.idSkpd)}
              >
                <i className="fa fa-trash"></i> Hapus
              </Buttons>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default ModalLihatFoto;
