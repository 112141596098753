import React, { Fragment, useState } from "react";
import Buttons from "components/body/buttons/Button";
import { Modal } from "react-bootstrap";
import SelectSatuanKerja from "components/body/selects/selectSatuanKerja";
import { postRequest } from 'config/axios'
import { useDispatch } from "react-redux";
import { getListSelect, set_option_select } from "redux/SelectReducers/SelectAction";
import { set_option_default } from "redux/SelectReducers/SelectAction";

const ModalComponent = ({ isOpen, setOpen }) => {
  const dispatch = useDispatch()
  const [pegawai, setPegawai] = useState({ status: true })
  const [unitKerjaValue, setUnitKerjaValue] = useState("")
  const [jabatanValue, setJabatanValue] = useState("")
  const postPegawai = () => {
    postRequest('egs/pegawai-egs/api/pegawai', { ...pegawai }).
      then(res => {
        console.log("response post pegawai", res)
        setOpen(false)
      }).catch(error => console.log("error post pegawai", error))
  }

  const changeSatuanKerja = (e) => {
    dispatch(set_option_select({ name: "unitKerja", value: {} }))
    dispatch(set_option_select({ name: "jabatan", value: {} }))
    setUnitKerjaValue(null)
    setJabatanValue(null)
    setPegawai({ ...pegawai, satuan_kerja_id: e.value })
    dispatch(getListSelect(`pegawai-egs/api/unit-kerja`, "unitKerja", e.value));
  }
  const changeUnitKerja = (e) => {
    setPegawai({ ...pegawai, unit_kerja_id: e.value });
    setUnitKerjaValue({ label: e.label, value: e.value });
    setJabatanValue(null)
    dispatch(getListSelect(`pegawai-egs/api/jabatan`, "jabatan", e.value));
  }

  const changeJabatan = (e) => {
    setPegawai({ ...pegawai, jabatan_id: e.value })
    setJabatanValue({ label: e.label, value: e.value });
  }
  console.log("data pegawai", pegawai)
  return (
    <Fragment>
      <Modal
        size="md"
        show={isOpen}
        onHide={() => {
          setOpen(false);
          dispatch(set_option_default());
          setUnitKerjaValue(null)
          setJabatanValue(null)
        }}
        aria-labelledby="example-modal-sizes-title-lg"
        animation={false}
      >
        <Modal.Header className="header-modal" closeButton>
          <Modal.Title
            className="title-modal"
            id="example-modal-sizes-title-lg"
          >
            Tambah Pegawai
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row reset-margin-row">
            <div className="col-md-12 content-modal">
              <div className="container-fluid">
                <div className="form-group">
                  <label htmlFor="nama">Nama*</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => setPegawai({ ...pegawai, name: e.target.value })}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="nip">NIP*</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => setPegawai({ ...pegawai, nip: e.target.value })}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="satuan_kerja">Satuan Kerja*</label>
                  <SelectSatuanKerja
                    placeholder="Pilih Satuan Kerja"
                    className="m-b-5"
                    list="listMasterSatuanKerja"
                    sourceReducer="ListSuratReducers"
                    onChange={changeSatuanKerja}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="unit_kerja">Unit kerja*</label>
                  <SelectSatuanKerja
                    placeholder="Pilih Unit Kerja"
                    className="m-b-5"
                    list="unitKerja"
                    value={unitKerjaValue}
                    sourceReducer="ListOptionReducers"
                    onChange={changeUnitKerja}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="jabatan">Jabatan*</label>
                  <SelectSatuanKerja
                    placeholder="Pilih Jabatan"
                    className="m-b-5"
                    list="jabatan"
                    value={jabatanValue}
                    sourceReducer="ListOptionReducers"
                    onChange={changeJabatan}
                  />
                </div>

              </div>
            </div>
          </div>
          <hr />
          <div className="row reset-margin-row ">
            <div className="col-md-6 mb-3">
              <Buttons
                className="btn btn-danger mr-2"
                children="Hapus"
              />
            </div>
            <div className="col-md-6 text-right mb-3">
              <Buttons
                onClick={() => setOpen(false)}
                className="btn btn-default mr-2"
                children="Batal"
              />
              <Buttons
                // onClick={createLokasi}
                className="btn btn-primary"
                children="Simpan"
                // loading={GeneralReducers.loading}
                onClick={postPegawai}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default ModalComponent;
