import React, { Fragment, useEffect, useState } from "react";
import { checkError, setLoading } from "redux/GeneralReducers/GeneralActions";

import Buttons from "components/body/buttons/Button";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { filterMaster } from "utils/filter-master";
import { getRequest } from "config/axios";
// import SelectSatuanKerja from "components/body/selects/selectSatuanKerja";
import { postRequest } from 'config/axios'
import { useDispatch } from "react-redux";

const ModalComponent = ({ isOpenAdd, setOpenAdd, getTenagaKontrak }) => {
  const dispatch = useDispatch();
  const [pegawai, setPegawai] = useState({ status: true })
  const [dataUnitKerja, setDataUnitKerja] = useState(null);
  const [dataJabatan] = useState([{ label: 'Tenaga Kontrak', value: 99999 }]);
  const [dataSkpd, setDataSkpd] = useState(null);
  const [id, setId] = useState("");


  const postPegawai = () => {
    postRequest('egs/pegawai-egs/api/pegawai-kontrak', { ...pegawai, })
      .then(res => {
        console.log("response post pegawai", res)
        setOpenAdd(false)
        getTenagaKontrak(id)
      }).catch(error => console.log("error post pegawai", error))
  }

  const getSkpd = () => {
    dispatch(setLoading(true));
    getRequest(`egs/pegawai-egs/api/satuan-kerja`)
      .then((res) => {
        dispatch(setLoading(false));
        filterMaster(setDataSkpd,res.data);
      })
      .catch((err) => dispatch(checkError(err)));
  };
  const getUnitKerja = (e) => {
    setPegawai({ ...pegawai, satuan_kerja_id: e.value })
    setId(e.value)
    dispatch(setLoading(true));
    getRequest(`egs/pegawai-egs/api/unit-kerja/?satuan_kerja_id=${id}`)
      .then((res) => {
        dispatch(setLoading(false));
        filterMaster(setDataUnitKerja,res.data);
      })
      .catch((err) => dispatch(checkError(err)));
  };

  // const getJabatan = () => {
  //   dispatch(setLoading(true));
  //   getRequest(`egs/pegawai-egs/api/jabatan/`)
  //     .then((res) => {
  //       dispatch(setLoading(false));
  //       filterMaster(setDataJabatan,res.data);
  //     })
  //     .catch((err) => dispatch(checkError(err)));
  // };
 
  useEffect(getSkpd, [isOpenAdd]);  
  // useEffect(getJabatan, [isOpenAdd]);
  return (
    <Fragment>
      <Modal
        size="md"
        show={isOpenAdd}
        onHide={() => setOpenAdd(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        animation={false}
      >
        <Modal.Header className="header-modal" closeButton>
          <Modal.Title
            className="title-modal"
            id="example-modal-sizes-title-lg"
          >
            Tambah Pegawai
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row reset-margin-row">
            <div className="col-md-12 content-modal">
              <div className="container-fluid">
                <div className="form-group">
                  <label htmlFor="nama">Nama*</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => setPegawai({ ...pegawai, name: e.target.value })}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="nik">NIK*</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => setPegawai({ ...pegawai, nik: e.target.value })}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="satuan_kerja">Satuan Kerja*</label>
                  <Select
                    placeholder="Pilih Satuan Kerja"
                    className="m-b-5"
                    options={dataSkpd}
                    onChange={(e) => getUnitKerja(e)}
                  />
                </div>
                {dataUnitKerja && <div className="form-group">
                  <label htmlFor="unit_kerja">Unit kerja*</label>
                  <Select
                    placeholder="Pilih Unit Kerja"
                    className="m-b-5"
                    options={dataUnitKerja}
                    onChange={(e) => setPegawai({ ...pegawai, unit_kerja_id: e.value })}
                  />
                </div>}
                <div className="form-group">
                  <label htmlFor="jabatan">Jabatan*</label>
                  <Select
                    placeholder="Pilih Jabatan"
                    className="m-b-5"
                    options={dataJabatan}
                    onChange={(e) => setPegawai({ ...pegawai, jabatan_id: e.value })}
                  />
                </div>

              </div>
            </div>
          </div>
          <hr />
          <div className="row reset-margin-row ">
            <div className="col-md-6 mb-3">
              <Buttons
                className="btn btn-danger mr-2"
                children="Hapus"
              />
            </div>
            <div className="col-md-6 text-right mb-3">
              <Buttons
                onClick={() => setOpenAdd(false)}
                className="btn btn-default mr-2"
                children="Batal"
              />
              <Buttons
                // onClick={createLokasi}
                className="btn btn-primary"
                children="Simpan"
                // loading={GeneralReducers.loading}
                onClick={postPegawai}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default ModalComponent;
