import React from "react";

const NextBtn = ({ to }) => {
  return (
    <a href={`#${to}`} id="toTabContent">
      Selanjutnya
    </a>
  );
};

export default NextBtn;
