import Input from "components/body/inputs/Input";
import InputsWithLabel from "components/body/inputs/InputsWithLabel";
import NextBtn from "components/body/buttons/nextBtn";
import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { set_value } from "redux/SuratReducers/BuatSurat/BuatSuratActions";
import ModalKegiatan from "./ModalKegiatan";
import ModalKodeKlasifikasi from "./ModalKodeKlasifikasi";

const KepalaSurat = () => {
  const [lgShow, setLgShow] = useState(false);
  const [kodeModal, setKodeModal] = useState(false);
  const {
    BuatSuratReducers: { kepala_surat },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const input_name = "kepala_surat";

  return (
    <Fragment>
      <div className="form-group">
        <InputsWithLabel
          value={kepala_surat.perihal}
          label="Perihal *"
          action={(e) => dispatch(set_value({
            value: { ...kepala_surat, perihal: e.target.value },
            name: input_name,
          })
          )}
        />
      </div>
      <div className="form-row">
        <div className="form-group col-md-8">
          <InputsWithLabel
            type="number"
            label="Lampiran"
            value={kepala_surat.lampiran.value}
            action={(e) =>
              dispatch(set_value({
                value: {
                  ...kepala_surat,
                  lampiran: {
                    ...kepala_surat.lampiran,
                    value: e.target.value,
                  },
                },
                name: input_name,
              })
              )}
          />
        </div>
        <div className="form-group col-md-4">
          <label htmlFor="jabatan" style={{ marginTop: 18 }}></label>
          <select
            className="form-control"
            value={kepala_surat.lampiran.type}
            onChange={(e) =>
              dispatch(
                set_value({
                  value: {
                    ...kepala_surat,
                    lampiran: {
                      ...kepala_surat.lampiran,
                      type: e.target.value,
                    },
                  },
                  name: input_name,
                })
              )
            }
          >
            {/* <option value="">Pilih</option> */}
            <option value="Lembar">Lembar</option>
            <option value="Rangkap">Rangkap</option>
          </select>
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="jabatan">
          <a href="# " onClick={() => setLgShow(!lgShow)}>
            Nama Kegiatan *
          </a>
        </label>
        <Input type="text" disabled={true} value={kepala_surat.kegiatan ? kepala_surat.kegiatan.nama_kegiatan : ""} />
      </div>
      <div className="form-group">
        <label htmlFor="jabatan">
          <a href="# " onClick={() => setKodeModal(!kodeModal)}>
            Kode Klasifikasi *
          </a>
        </label>
        <Input type="text" disabled={true} value={kepala_surat.klasifikasi ? kepala_surat.klasifikasi.nama : ""} />
      </div>
      <div className="form-row">
        <div className="form-group col-md-6">
          <label htmlFor="sifat_penyampaian">Sifat (Penyampaian)</label>
          <select
            className="form-control"
            value={kepala_surat.sifat.penyampaian}
            onChange={(e) =>
              dispatch(
                set_value({
                  value: {
                    ...kepala_surat,
                    sifat: {
                      ...kepala_surat.sifat,
                      penyampaian: e.target.value,
                    },
                  },
                  name: input_name,
                })
              )
            }
          >
            <option value="-">Pilih</option>
            <option value="Biasa">Biasa</option>
            <option value="Cepat">Cepat</option>
          </select>
        </div>
        <div className="form-group col-md-6">
          <label htmlFor="sifat_penyampaian">Sifat (Pengamanan)</label>
          <select
            className="form-control"
            value={kepala_surat.sifat.pengamanan}
            onChange={(e) =>
              dispatch(
                set_value({
                  value: {
                    ...kepala_surat,
                    sifat: {
                      ...kepala_surat.sifat,
                      pengamanan: e.target.value,
                    },
                  },
                  name: input_name,
                })
              )
            }
          >
            <option value="-">Pilih</option>
            <option value="Biasa">Biasa</option>
            <option value="Rahasia">Rahasia</option>
          </select>
        </div>
      </div>
      <ModalKegiatan lgShow={lgShow} setLgShow={setLgShow} />
      <ModalKodeKlasifikasi kodeModal={kodeModal} setKodeModal={setKodeModal} />
      <div className="col-md-12 flex-space-between to-right">
        <NextBtn to="pengirim" />
      </div>
    </Fragment>
  );
};

export default KepalaSurat;
