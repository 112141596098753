import Content from "./cards/content";
import React from "react";
import Layout from "layout";

export function LogsPage() {
  return (
    <Layout subnavbar={{ title: "Daftar Log", keysTab: "listLogs" }}>
      <Content />
    </Layout>
  );
}
