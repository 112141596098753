import moment from "moment";
import React, { Fragment } from "react";
import { getRequest } from "config/axios";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { checkError } from "redux/GeneralReducers/GeneralActions";

export const Content = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [dataList, setDataList] = useState(null);
  const { NotificationReducers } = useSelector((state) => state);
  const { notifCount } = NotificationReducers;
  const getNotif = (per_page = 10) => {
    getRequest(`egs/notification?page=1&per_page=${per_page}`)
      .then((res) => {
        setDataList(res.data);
      })
      .catch((err) => dispatch(checkError(err)));
  };

  const goTo = (kategori) => {
    let url;
    if (kategori === "persetujuan") {
      url = `/surat-keluar/persetujuan`;
    } else if (kategori === "konsep") {
      url = `/surat-keluar/konsep`;
    } else if (kategori === "surat-masuk") {
      url = `/surat-masuk/daftar-surat`;
    }
    history.push(url);
  };

  useEffect(getNotif, [notifCount]);
  return (
    <Fragment>
      <div className="content-title">
        <div>
          <i className="fa fa-bell"></i> NOTIFIKASI
        </div>
      </div>
      <div className="content-body container-fluid">
        <div>
          <ul className="notif-page-list no-list">
            {dataList
              ? dataList.items.map((list, i) => {
                  return (
                    <li
                      key={i}
                      className="pointer-mouse"
                      onClick={() => goTo(list.data.kategori)}
                    >
                      {list.data.perihal}{" "}
                      <b>
                        <i className="fa fa-user"></i> {list.data.penerima}
                      </b>{" "}
                      - <i className="fa fa-calendar"></i>{" "}
                      {moment(list.data.tanggal).format("dddd, LL, HH:MM")}
                    </li>
                  );
                })
              : "Tidak ada notifikasi !"}
          </ul>
        </div>
      </div>
    </Fragment>
  );
};
