import React, { Fragment, useState, useEffect } from "react";
import Paginate from "components/footers/paginate";
import ModalContent from "./Modal";
import { getRequest } from "config/axios";
import { useDispatch, useSelector } from "react-redux";
import { setLoading, setStatus } from "redux/GeneralReducers/GeneralActions";
import {
  decrement,
  increment,
  setFirstPage,
  setLastPage,
} from "utils/pagination";
import { Button } from "react-bootstrap";
import Loading from "components/body/Loading";

const Content = () => {
  const [modal, setModal] = useState({ modal: false, penyimpanan: {} });
  const dispatch = useDispatch();
  let autoNumber = 0;
  const { GeneralReducers } = useSelector((state) => state);
  const [dataTabelArsip, setDataTabelArsip] = useState(null);
  const { loading, status, msg } = GeneralReducers;

  const getList = (current_page = 1, q = "") => {
    dispatch(setLoading(true));
    getRequest(`egs/arsip?page=${current_page}&per_page=5&q=${q}`)
      .then((res) => {
        console.log("objecres datat", res.data)
        dispatch(setLoading(false));
        setDataTabelArsip(res.data);
      })
      .catch((err) => {
        dispatch(setLoading(false));
        if (err.request.status === 401) {
          dispatch(setStatus({ status: "401", msg: "Unauthorization" }));
        } else if (err.message === "Network Error") {
          dispatch(setStatus({ status: "401", msg: err.message }));
        } else {
          dispatch(setStatus({ status: "fail", msg: "Gagal mengambil data" }));
        }
      });
  };

  useEffect(getList, []);

  return (
    <Fragment>
      <ModalContent
        data={modal}
        getList={getList}
        setModal={setModal}
      />
      <div className="content-title">
        <div>
          <a
            onClick={() =>
              setModal({
                modal: !modal.modal,
                action: "Tambah",
              })
            }
            href="# "
            className="btn btn-sm btn-primary"
          >
            <i className="fa fa-plus"></i> Tambah Arsip
          </a>
        </div>
        <div>
          <input
            placeholder="Search"
            type="text"
            className="form-control"
            onChange={(e) => {
              console.log("find", e.target.value)
              getList(1, e.target.value)
            }}
          />
        </div>
      </div>
      <div className="content-body">
        <Loading isLoading={loading} />
        {status === "success tabel arsip" && (
          <>
            <div className="alert alert-success">
              {msg}
              <a
                href="# "
                className="pull-right"
                onClick={() => dispatch(setStatus({ status: null, msg: null }))}
              >
                <i className="fa fa-close"></i>
              </a>
            </div>
          </>
        )}
        <table className="table table-striped table-list">
          <thead>
            <tr>
              <td>No</td>
              <td>Jenis Arsip</td>
              <td>Nama penyimpanan</td>
              <td>Nomor Dokument</td>
              <td>Tahun</td>
              <td>Nama Arsip</td>
              <td>Lihat</td>
              <td>Unduh</td>
              <td>Edit</td>
              <td>Hapus</td>
            </tr>
          </thead>
          <tbody>
            {dataTabelArsip && dataTabelArsip.items.map((item, i) => {
              autoNumber += 1;
              return (
                <tr key={i}>
                  <td>{autoNumber}</td>
                  <td>{item.jenis_arsip.nama}</td>
                  <td>{item.penyimpanan?.kode}</td>
                  <td>{item.no_dokumen}</td>
                  <td>
                    {item.tahun}
                  </td>
                  <td>
                    {item.nama_arsip}
                  </td>
                  <td>
                    <Button size="sm" variant="default" onClick={() => {
                      setModal({
                        modal: true,
                        action: "Lihat",
                        tabelArsip: item,
                      })
                    }}>
                      <i className="fa fa-print"></i>
                    </Button>
                  </td>
                  <td>
                    <Button size="sm" variant="default" onClick={() => {
                      const link = document.createElement('a');
                      link.setAttribute("target", "_blank");
                      link.download = `${item.no_dokumen}`;
                      link.href = item.file;
                      link.click();
                    }}>
                      <i className="fa fa-download"></i>
                    </Button>
                  </td>
                  <td>
                    <Button size="sm" variant="default" onClick={() => {
                      setModal({
                        modal: true,
                        action: "Ubah",
                        tabelArsip: item,
                      })
                    }}>
                      <i className="fa fa-pencil"></i>
                    </Button>
                  </td>
                  <td>
                    <Button size="sm" variant="default" onClick={() => {
                      setModal({
                        modal: true,
                        action: "Delete",
                        tabelArsip: item,
                      })
                    }}>
                      <i className="fa fa-trash"></i>
                    </Button>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>

        {/* END SKPD */}
      </div>
      {dataTabelArsip && (
        <Paginate
          meta={dataTabelArsip.meta}
          increment={() =>
            increment(dataTabelArsip.meta.current_page, getList)
          }
          decrement={() =>
            decrement(dataTabelArsip.meta.current_page, getList)
          }
          setLastPage={() =>
            setLastPage(dataTabelArsip.meta.last_page, getList)
          }
          setFirstPage={() => setFirstPage(dataTabelArsip.meta.from, getList)}
        />
      )}
    </Fragment>
  );
};

export default Content;

