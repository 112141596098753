import React, { Fragment } from "react";
// import Paginate from "components/footers/paginate";
import { withRouter } from "react-router-dom";
import moment from "moment";

// import { useDispatch } from "react-redux";

const RiwayatCatatan = (props) => {
  const notes = props.riwayatCatatan;

  return (
    <Fragment>
      <hr />
      <div className="row">
        <div className="col-md-6">
          {/* <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Search"
              aria-describedby="button-addon2"
            />
            <div className="input-group-append btn btn-outline-secondary">
              <i className="fa fa-search"></i>
            </div>
          </div> */}
        </div>
        <div className="col-md-6">
          {/* <Paginate /> */}
          <br />
        </div>
      </div>
      <div className="row">
        {notes &&
          notes.map((list, i) => {
            return (
              <div
                key={i}
                className="col-md-12 item-content-table m-b-5 m-l-15"
              >
                <div className="content-table-card">
                  <img
                    src={list.photo_url}
                    alt="images"
                    width="60"
                    className="content-table-images"
                  />
                  <div className="content-table-text">
                    <div className="content-table-text-row">
                      <span className="label-table">Dari</span>
                      <span>
                        : {list.data_pegawai.unit_kerja.unit_kerja_nama}
                      </span>
                    </div>
                    <div className="content-table-text-row">
                      <span className="label-table">Nama</span>
                      <span>
                        : {list.data_pegawai.peg_nama} -{" "}
                        {list.data_pegawai.peg_nip}
                      </span>
                    </div>
                    <div className="content-table-text-row">
                      <span className="label-table">Catatan</span>
                      <span>
                        :{" "}
                        <span className="badge badge-success">
                          {list.action.text}
                        </span>{" "}
                        {list.action.keterangan}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="content-table-card mr-5">
                  {moment(list.action.created_at).format("LLL")}
                </div>
              </div>
            );
          })}
        <div className="col-md-12 line-horizontal"></div>
      </div>
    </Fragment>
  );
};

export default withRouter(RiwayatCatatan);
