import React from "react";
import { Link } from "react-router-dom";
import "../../styles/content/login.scss";

const ForgetPassword = () => {
  return (
    <div className="container-login">
      <img src={process.env.PUBLIC_URL + '/assets/img/logo.svg'} alt="Logo" />
      <div className="form-wrapper">
        <div className="form-title">
          <span>Lupa Kata Sandi ?</span>
        </div>
        <div className="form">
          <div class="form-group">
            <label for="exampleInputEmail1">Alamat Email</label>
            <input
              type="email"
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Alamat Email"
            />
            <small id="emailHelp" class="form-text text-muted"></small>
          </div>

          <Link to="/dashboard" class="btn col-md-6 btn-primary">
            Kirim
          </Link>
          <Link to="/" class="btn col-md-6 btn-default">
            Batal
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
