import React from "react";
const Loading = ({ children, colSpan, isLoading }) => {
  if (isLoading) {
    return (
      <span className="loading-bg">
        <i className="fa fa-spinner fa-pulse fa-fw"></i>
      </span>
    );
  } else {
    return "";
  }
};

export default Loading;
