import { getRequest } from "config/axios";
import { setLoading } from "redux/GeneralReducers/GeneralActions";
import { getCount } from "redux/Notifications/NotificationActions";
import { store } from "redux/rootRedux/Store";
import { SET_LIST, SET_DEFAULT } from "redux/rootRedux/TypeActions";
import { set_option_default } from "redux/SelectReducers/SelectAction";

export const set_list = (data) => ({
  type: SET_LIST,
  payload: data,
});

export const set_default = () => {
  return (dispatch) => dispatch({ type: SET_DEFAULT });
};

const filterDouble = (e) => {
  return e.filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);
};
export const getListMethod = (
  link,
  name,
  current_page = 1,
  satuan_kerja_id = null,
) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    const { start_date, end_date, q, jenis_surat, status_surat } = store.getState().ListSuratReducers;
    const satuan_kerja = store.getState().LoginReducers;
    let satuan_kerja_id_store = satuan_kerja_id;
    if (name === "daftarSuratMasukNonSekretariat" || name === "daftarSuratMasukSekretariat") {
      satuan_kerja_id_store = satuan_kerja_id;
    } else {
      satuan_kerja_id_store = satuan_kerja.userInfo.data_pegawai.satuan_kerja.satuan_kerja_id || satuan_kerja_id;
    }
    getRequest(
      `egs/${link}?satuan_kerja_id=${satuan_kerja_id_store}&start_date=${start_date}&end_date=${end_date}&q=${q}&page=${current_page}&per_page=10&jenis_surat=${jenis_surat}&status_surat=${status_surat}`
    )
      .then((res) => {
        dispatch(setLoading(false));
        const resultFilter = filterDouble(res.data.items);
        dispatch(
          set_list({ name, value: { ...res.data, items: resultFilter } })
        );
        dispatch(set_option_default());
        dispatch(getCount());
      })
      .catch((err) => {
        // dispatch(checkError(err))
        console.log("error get list", err)
      });
  };
};
