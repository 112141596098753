import React, { forwardRef, Fragment } from 'react'

function InputsWithLabel({
  value,
  label,
  action,
  type = "text",
  disabled = false,
  id,
  error = false,
  success = false,
  placeholder = "",
  onKeyPress = () => null
}, ref) {
  return (
    <Fragment>
      <label htmlFor={id}>{label}</label>
      <input
        type={type}
        name={id}
        id={id}
        disabled={disabled}
        placeholder={placeholder}
        className={`form-control ${error && `input-error`} ${success && `input-success`} `}
        value={value}
        onChange={action}
        onKeyPress={onKeyPress}
        ref={ref}
      />
    </Fragment>
  )
}

export default forwardRef(InputsWithLabel)
