import React, { Fragment } from "react";
import Navbar from "components/header/navbar";
import Sidebar from "components/sidebar/sidebar";
import SubNavbarWrapper from "components/header/subNavbarWrapper";
import Footer from "components/footers/footer";
import ContentWrapper from "components/body/contentWrapper";
import Content from "./cards/content";
import SubNavbar from "components/header/subNavbar";

const MasterSatuanKerjaPage = () => {
  return (
    <Fragment>
      <div className="background-dark"></div>
      <Navbar />
      <Sidebar />
      <SubNavbarWrapper>
        <SubNavbar title="Master Satuan Kerja" keysTab="listMasterSatuanKerja_1" />
      </SubNavbarWrapper>
      <ContentWrapper>
        <Content />
      </ContentWrapper>
      <Footer />
    </Fragment>
  );
}

export { MasterSatuanKerjaPage };
