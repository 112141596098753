import React, { Fragment } from "react";

const CardInfo = (props) => {
  return (
    <Fragment>
      <div className="col-md wrapper-card-info">
        <div className="title-card">{props.title}</div>
        <div className="info-card">
          <div className="info-item">
            <img className="info-images" src={process.env.PUBLIC_URL + `assets/img/icon/${props.icon}.svg`} alt="Info-Images" />
          </div>
          {props.data &&
            props.data.map((list, i) => {
              return (
                <div key={i} className="info-item">
                  <div className="info-item-text">{list.label}</div>
                  <h3 className="info-item-number">{list.value}</h3>
                </div>
              );
            })}
        </div>
      </div>
    </Fragment>
  );
};

export default CardInfo;
