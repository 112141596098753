import { firebase } from "../../firebase";
import React from "react";
import { withRouter } from "react-router-dom";

export const Unsupport = withRouter((props) => {
  if (firebase.messaging.isSupported()) {
    props.history.push("/");
  }
  const openDownload = () => {
    window.open("https://api.codemagic.io/artifacts/b8e43696-31a1-4b43-8687-86c68ddc54e5/1ade79e1-a77a-434e-be03-7e41865fb45c/app-debug.apk", "_blank")
  }

  return (
    <div className="container m-t-20 ">
      <center>
        <b>
          Browser anda tidak support untuk mengakses EGS, silahkan gunakan
          browser chrome atau firefox.
        </b>
        <div className="row m-t-10">
          <div className="col-md-12">
            <img src="/assets/img/ChromeFirefox.svg" width="250" alt="" />
          </div>
        </div>
        <b>OR</b>
        <div className="row">
          <div className="col-md-12">
            <img src="/assets/img/GooglePlay.svg" onClick={openDownload} width="150" alt="" />{" "}
          </div>
        </div>
      </center>
    </div>
  );
});
