import React, { Fragment, memo, useEffect, useRef, useState } from "react";
import moment from "moment";
import { getRequest } from "config/axios";
import { useDispatch } from "react-redux";
import { checkError } from "redux/GeneralReducers/GeneralActions";

const TampilanSurat = (props) => {
  moment.locale("id");
  const dispatch = useDispatch()
  const refIframe = useRef()
  const detail = props.details;
  const [kopUrl, setKopUrl] = useState(detail.esign_document)

  const reloadPdf = (status) => {
    status && refIframe.current.replaceWith(refIframe.current)
  }

  const kopToggle = async (id) => {
    try {
      const res = await getRequest(`egs/set-without-kop/${id}`)
      setKopUrl(res.data.items)
      console.log("dalam functin kop toggle", kopUrl)
      refIframe.current.replaceWith(refIframe.current)
    } catch (error) {
      dispatch(checkError(error))
    }
  }

  useEffect(() => {
    reloadPdf(true);
    return () => reloadPdf(false)
  }, [props.keyofTabs]);

  console.log("tampil surat", kopUrl)
  return (
    <Fragment>
      {detail.bentuk_surat !== "softcopy" &&
        <div className="v-center p-tb-10">
          kop surat : {' '}
          {kopUrl?.no_kop ?
            <span onClick={() => kopToggle(detail?.id)} className="m-l-15 pointer fa fa-toggle-off"></span>
            : <span onClick={() => kopToggle(detail?.id)} className="m-l-15 pointer fa fa-toggle-on"></span>}
        </div>
      }
      <div className="content-body">

        <iframe
          ref={refIframe}
          title="pdf"
          src={detail?.bentuk_surat === "softcopy" ? detail?.attachment?.signedFile : kopUrl?.url}
          type="application/pdf"
          style={{ width: "100%", height: "90vh" }}
        >
        </iframe>
      </div>
    </Fragment>
  );
};

export default memo(TampilanSurat);
