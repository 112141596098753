import {
  EDIT_SURAT_KELUAR,
  RESET_VALUE,
  SET_VALUE,
} from "redux/rootRedux/TypeActions";

const GlobalState = {
  status_surat: [],
  kategori_surat: ["internal"],
  tanda_tangan: false,
  referensi: { dataLengkap: [], id: [] },
  tipe_surat: "",
  no_surat_req: "",
  kepala_surat: {
    perihal: "",
    lampiran: {
      type: "Lembar",
      value: "",
    },
    kegiatan: null,
    klasifikasi: null,
    sifat: {
      pengamanan: "-",
      penyampaian: "-",
    },
  },
  pengirim: {
    user: {
      data_pegawai: {
        jabatan_nama: "",
        peg_nama: "",
        peg_nip: "",
        unit_kerja: {
          unit_kerja_nama: "",
        },
        business_organisasi: "",
      },
      peg_nip: null,
    },
  },
  penerima: {
    type_tanda_tangan: "tanda tangan digital",
    user: [],
    plt: [],
    user_eksternal: [],
  },
  tembusan: {
    user: [],
    plt: [],
    user_eksternal: [],
  },
  pemeriksa: {
    user: [],
    plt: [],
  },
  initId: "",
  isi_surat: "",
  lampiran: [],
  info_jadwal: {
    tanggal_mulai: new Date(),
    tanggal_berakhir: new Date(),
    tempat: "",
  },
  bentuk_surat: "text-editor",
  attachment: {
    originalFile: "",
    signedFile: ""
  }
};

export const BuatSuratReducers = (state = GlobalState, action) => {
  switch (action.type) {
    case SET_VALUE:
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case RESET_VALUE:
      return GlobalState;
    case EDIT_SURAT_KELUAR:
      return {
        ...action.payload,
      };
    default:
      return state;
  }
};
