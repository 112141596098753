import {
  SET_TOKEN,
  SET_USER_INFO,
  SET_TOKEN_FCM,
  SET_MENU,
} from "../rootRedux/TypeActions";

const GlobalState = {
  token: null,
  refresh_token: null,
  isLogin: false,
  userInfo: null,
  menus: [],
  routers: [],
  fcm_token: null,
};

const LoginReducers = (state = GlobalState, action) => {
  switch (action.type) {
    case SET_TOKEN:
      return {
        ...state,
        token: action.payload.access_token,
        refresh_token: action.payload.refresh_token,
      };
    case SET_USER_INFO:
      return {
        ...state,
        isLogin: action.payload.isLogin,
        userInfo: action.payload.data,
      };
    case SET_MENU:
      return {
        ...state,
        menus: action.payload.menus,
        routers: action.payload.routers,
      };
    case SET_TOKEN_FCM:
      return {
        ...state,
        fcm_token: action.payload,
      };
    default:
      return state;
  }
};

export default LoginReducers;
