// import { createStore, applyMiddleware } from "redux";
// import RootReducers from "./RootReducers";
// import thunk from "redux-thunk";

// const store = createStore(RootReducers, applyMiddleware(thunk));

// export default store;

import { createStore, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import RootReducers from "./RootReducers";
import thunk from "redux-thunk";

const persistConfig = {
  key: "root",
  storage,
  blacklist: [
    "BuatSuratReducers",
    "BuatSuratMasukReducers",
    "ListSuratReducers",
    "NotificationReducers",
  ], // which reducer wan't to store
};

const persistedReducer = persistReducer(persistConfig, RootReducers);
const store = createStore(persistedReducer, applyMiddleware(thunk));
// const store = createStore(RootReducers, applyMiddleware(thunk));
const persistor = persistStore(store);
export { persistor, store };
