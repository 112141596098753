import { getRequest } from "config/axios";
import { checkError } from "redux/GeneralReducers/GeneralActions";
import { SET_NOTIF, RESET_NOTIF, SET_BADGE } from "redux/rootRedux/TypeActions";

export const setNotifikasi = (data) => {
  return (dispatch) => {
    dispatch({ type: SET_NOTIF, payload: data });
  };
};

export const setBadge = (data) => {
  return (dispatch) => {
    dispatch({ type: SET_BADGE, payload: data });
  };
};

export const resetNotif = (kategori) => {
  return (dispatch) => {
    dispatch({ type: RESET_NOTIF, payload: kategori });
  };
};

export const getCount = () => {
  return (dispatch) => {
    getRequest(`/egs/dashboard/count`)
      .then((res) => {
        let surat_masuk =
          res.data.items.count_internal.havent_read +
          res.data.items.count_sekretariat.havent_read;
        let total_notif =
          res.data.items.count_internal.havent_read +
          res.data.items.count_sekretariat.havent_read +
          res.data.items.count_persetujuan.havent_read +
          res.data.items.count_draft.havent_read;

        dispatch(
          setBadge({
            surat_masuk,
            persetujuan: res.data.items.count_persetujuan.havent_read,
            konsep: res.data.items.count_draft.havent_read,
            total_notif,
          })
        );
      })
      .catch((err) => dispatch(checkError(err)));
  };
};

