import React, { Fragment } from "react";
import { postRequest, putRequest } from "config/axios";
import { Modal } from "react-bootstrap";
import Buttons from "components/body/buttons/Button";

const ModalComponent = ({ isOpen, setOpen, satuan_kerja, setSatuanKerja, getList }) => {
  const submitSatuanKerja = () => {
    postRequest('egs/pegawai-egs/api/satuan-kerja', satuan_kerja)
      .then(res => {
        setOpen(!isOpen)
        getList()
        console.log("respon satuan-kerja", res)
      }).catch(err => console.log("error submit satuan kerja", err))
  }
  const updateSatuanKerja = (id) => {
    putRequest(`egs/pegawai-egs/api/satuan-kerja/${id}`, satuan_kerja)
      .then(res => {
        setOpen(!isOpen)
        getList()
        console.log("respon satuan-kerja", res)
      }).catch(err => console.log("error submit satuan kerja", err))
  }
  // const deleteSatuanKerja = (id) => {
  //   putRequest(`egs/pegawai-egs/api/satuan-kerja/${id}`, satuan_kerja)
  //     .then(res => {
  //       setOpen(!isOpen)
  //       getList()
  //       console.log("respon satuan-kerja", res)
  //     }).catch(err => console.log("error submit satuan kerja", err))
  // }

  return (
    <Fragment>
      <Modal
        size="md"
        show={isOpen}
        onHide={() => {
          setOpen(false)
          setSatuanKerja({ status: true })
        }}
        aria-labelledby="example-modal-sizes-title-lg"
        animation={false}
      >
        <Modal.Header className="header-modal" closeButton>
          <Modal.Title
            className="title-modal"
            id="example-modal-sizes-title-lg"
          >
            Tambah Satuan Kerja
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row reset-margin-row">
            <div className="col-md-12 content-modal">
              <div className="container-fluid">
                <div className="form-group">
                  <div className="form-group">
                    <label htmlFor="kode_satuan_kerja">Kode Satuan Kerja*</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) => setSatuanKerja({ ...satuan_kerja, code: e.target.value })}
                      defaultValue={satuan_kerja?.code}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="satuan_kerja">Satuan Kerja*</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) => setSatuanKerja({ ...satuan_kerja, name: e.target.value })}
                      defaultValue={satuan_kerja?.name}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="alamat">Alamat*</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={e => setSatuanKerja({ ...satuan_kerja, address: e.target.value })}
                      defaultValue={satuan_kerja?.address}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="kode_ref">Kode Referensi*</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={e => setSatuanKerja({ ...satuan_kerja, sapk_id: e.target.value })}
                      defaultValue={satuan_kerja?.sapk_id}
                    />
                  </div>
                  <div className="form-group">
                    <div>Status</div>
                    <div style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "start"
                    }}>
                      <input
                        type="radio"
                        id="aktif"
                        name="status"
                        defaultChecked={satuan_kerja.status === true}
                        value={true}
                        onChange={(e) => setSatuanKerja({ ...satuan_kerja, status: satuan_kerja.status })}
                      />
                      <label style={{ marginLeft: "5px", marginRight: "8px", marginTop: "8px" }} htmlFor="aktif">Aktif</label>
                      <input
                        type="radio"
                        id="non-aktif"
                        name="status"
                        defaultChecked={satuan_kerja.status === false}
                        value={false}
                        onChange={(e) => setSatuanKerja({ ...satuan_kerja, status: !satuan_kerja.status })}
                      />
                      <label style={{ marginLeft: "5px", marginTop: "8px" }} htmlFor="non-aktif">Non-Aktif</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="row reset-margin-row ">
            <div className="col-md-6 mb-3">
              <Buttons
                className="btn btn-danger mr-2"
                children="Hapus"
              />
            </div>
            <div className="col-md-6 text-right mb-3">
              <Buttons
                onClick={() => setOpen(false)}
                className="btn btn-default mr-2"
                children="Batal"
              />
              {satuan_kerja.id ?
                <Buttons
                  onClick={() => updateSatuanKerja(satuan_kerja.id)}
                  className="btn btn-primary"
                  children="Ubah"
                // loading={GeneralReducers.loading}
                />
                :
                <Buttons
                  onClick={submitSatuanKerja}
                  className="btn btn-primary"
                  children="Simpan"
                // loading={GeneralReducers.loading}
                />
              }
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default ModalComponent;
