import { getRequest } from "config/axios";
import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import { Modal, Tab, Tabs, Table } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { checkError } from "redux/GeneralReducers/GeneralActions";

const ModalInfo = (props) => {
  const [key, setKey] = React.useState("penerima");
  const [list, setList] = useState(null);
  const dispatch = useDispatch();
  const id = props.match.params.id;

  const getList = () => {
    getRequest(`egs/mail-info/${id}`)
      .then((res) => {
        setList(res.data);
      }).catch((err) => dispatch(checkError(err, "detail-error")));
  };

  useEffect(getList, []);
  
  return (
    <Fragment>
      <Modal
        size="md"
        show={props.lgShow}
        onHide={() => props.setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header className="header-modal" closeButton>
          <Modal.Title
            className="title-modal"
            id="example-modal-sizes-title-lg"
          >
            INFO
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row reset-margin-row">
            <div className="col-md-12 content-modal">
              <Tabs
                id="controlled-tab-example"
                activeKey={key}
                className="tabs-header-modal"
                onSelect={(k) => setKey(k)}
              >
                <Tab
                  eventKey="penerima"
                  title="Penerima"
                  className="tabs-item-modal"
                >
                  {/* <div className="title-tab-second bg-white no-border-bottom text-right">
                    <span className="mr-3">1 dari 10</span>
                    <i className="fa fa-chevron-left mr-3"></i>
                    <i className="fa fa-chevron-right"></i>
                  </div> */}
                  <Table striped bordered hover className="table-modal-content">
                    <thead>
                      <tr>
                        <th>Nama</th>
                        <th>Terima</th>
                        <th>Baca</th>
                        <th>Disposisi</th>
                      </tr>
                    </thead>
                    <tbody>
                      {list &&
                        list.items.penerima.map((list, i) => {
                          return (
                            <tr key={i}>
                              <td>{list.nama}</td>
                              <td>
                                {list.status_terima ? (
                                  <span className="badge badge-info">
                                    Sudah
                                  </span>
                                ) : (
                                  <span className="badge badge-warning">
                                    Belum
                                  </span>
                                )}
                              </td>
                              <td>
                                {list.status_baca ? (
                                  <span className="badge badge-info">
                                    Sudah
                                  </span>
                                ) : (
                                  <span className="badge badge-warning">
                                    Belum
                                  </span>
                                )}
                              </td>
                              <td>
                                {list.status_disposisi ? (
                                  <span className="badge badge-info">
                                    Sudah
                                  </span>
                                ) : (
                                  <span className="badge badge-warning">
                                    Belum
                                  </span>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </Tab>
                <Tab eventKey="tembusan" title="Tembusan">
                  {/* <div className="title-tab-second bg-white no-border-bottom text-right">
                    <span className="mr-3">1 dari 10</span>
                    <i className="fa fa-chevron-left mr-3"></i>
                    <i className="fa fa-chevron-right"></i>
                  </div> */}
                  <Table striped bordered hover className="table-modal-content">
                    <thead>
                      <tr>
                        <th>Nama</th>
                        <th>Terima</th>
                        <th>Baca</th>
                      </tr>
                    </thead>
                    <tbody>
                      {list &&
                        list.items.tembusan.map((list, i) => {
                          return (
                            <tr key={i}>
                              <td>{list.nama}</td>
                              <td>
                                {list.status_terima ? (
                                  <span className="badge badge-info">
                                    Sudah
                                  </span>
                                ) : (
                                  <span className="badge badge-warning">
                                    Belum
                                  </span>
                                )}
                              </td>
                              <td>
                                {list.status_baca ? (
                                  <span className="badge badge-info">
                                    Sudah
                                  </span>
                                ) : (
                                  <span className="badge badge-warning">
                                    Belum
                                  </span>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </Tab>
              </Tabs>
            </div>
          </div>
          <hr />
          <div className="row reset-margin-row ">
            <div className="col-md-12 text-right mb-3">
              <button
                onClick={() => props.setLgShow(false)}
                className="btn btn-primary mr-2"
              >
                Tutup
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default withRouter(ModalInfo);
