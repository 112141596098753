import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import Paginate from 'components/footers/paginate';
import { useSelector } from 'react-redux';
import {
  decrement,
  increment,
  setFirstPage,
  setLastPage,
} from 'utils/pagination';

const ContentToViewMasukSekretariat = () => {
  const { ListSuratReducers } = useSelector(
    (state) => state
  );
  const { items, meta } = ListSuratReducers.listBankSuratMasukSekretariat;
  console.log("listBankSuratMasukSekretariat", items)
  return (
    <div className="view-print__content">
      <div className="content-wrapper">
        <div className='content-body'>
          <table className='table table-striped table-list'>
            <thead>
              <tr>
                <td width='130px'>Tanggal Terima</td>
                <td>Pengirim</td>
                <td>Nomor Surat</td>
                <td>Hal</td>
                <td>Disposisi</td>
              </tr>
            </thead>
            <tbody>
              {items &&
                items.map((list, i) => {
                  return (
                    <tr key={i}>
                      <td>
                        <Link to={`/bank-surat/list/detail/${list.id}`}>
                          {moment(list.tanggal).format('LL')}
                        </Link>
                      </td>
                      <td>{list.dari}</td>
                      <td>{list.no_surat}</td>
                      <td>{list.hal}</td>
                      <td>
                        {list.disposisi &&
                          <>
                            {list.disposisi.peg_nama}
                            <p>{list.disposisi.jabatan_nama}</p>
                            {list.disposisi.aksi.map((item, i) => {
                              return (
                                <p key={i}>
                                  <span className='badge badge-primary'>
                                    {item}
                                  </span>
                                </p>
                              )
                            })}
                          </>
                        }
                      </td>

                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        {meta && (
          <Paginate
            meta={meta}
            increment={() => increment(meta.current_page, null)}
            decrement={() => decrement(meta.current_page, null)}
            setLastPage={() => setLastPage(meta.last_page, null)}
            setFirstPage={() => setFirstPage(meta.from, null)}
          />
        )}
      </div>
    </div>
  );
};

export default ContentToViewMasukSekretariat;
