import React, { Fragment, useEffect, useState } from "react";
import Paginate from "components/footers/paginate";
import { useDispatch, useSelector } from "react-redux";
import {
  decrement,
  increment,
  setFirstPage,
  setLastPage,
} from "utils/pagination";
import { getListMethod } from "redux/SuratReducers/ListSuratMasuk/ListSuratAction";
import Loading from "components/body/Loading";
import ModalComponent from "./modals";
import SelectSatuanKerja from "components/body/selects/selectSatuanKerja";
import { Button } from "react-bootstrap";

const Content = () => {
  const dispatch = useDispatch();
  const { GeneralReducers, ListSuratReducers } = useSelector((state) => state);
  const [isOpen, setOpen] = useState(null);
  const [jabatan, setJabatan] = useState({
    status: true
  })
  const { loading } = GeneralReducers;
  const { items, meta } = ListSuratReducers.listMasterJabatan;

  const getList = (current_page = 1) => {
    dispatch(getListMethod(`pegawai-egs/api/satuan-kerja`, "listMasterSatuanKerja", current_page));
    dispatch(getListMethod(`pegawai-egs/api/jabatan`, "listMasterJabatan", current_page));
    dispatch(getListMethod(`pegawai-egs/api/unit-kerja/?data`, "listMasterUnitKerja", current_page));
  };

  const changeFilterList = (e) => {
    dispatch(getListMethod(`pegawai-egs/api/jabatan`, "listMasterJabatan", 1, e.value));
  }

  const editItem = (item) => {
    setOpen(true);
    setJabatan({ ...item })
  }

  useEffect(getList, []);

  return (
    <Fragment>
      <ModalComponent
        isOpen={isOpen}
        setOpen={setOpen}
        jabatan={jabatan}
        getList={getList}
        setJabatan={setJabatan}
      />
      <div className="content-title row">
        <div className="col-md-12 col-sm-12 mb-2">
          <a
            onClick={() => setOpen(true)}
            href="# "
            className="btn btn-sm btn-primary"
          >
            <i className="fa fa-plus"></i> Tambah Jabatan
          </a>
        </div>
        <div className="col-md-12 col-sm-12">
          <SelectSatuanKerja
            placeholder="Pilih SKPD"
            className="m-b-5"
            list="listMasterSatuanKerja"
            sourceReducer="ListSuratReducers"
            // defaultValue={{ value: pegawai.unit_kerja_id, label: pegawai?.unitKerja?.name }}
            onChange={changeFilterList}
          />
        </div>
      </div>
      <div className="content-body">
        <Loading isLoading={loading} />
        <table className="table table-striped table-list">
          <thead>
            <tr>
              <td>Satuan Kerja</td>
              <td>Jabatan</td>
              <td>Opsi</td>
            </tr>
          </thead>
          <tbody>
            {items && items.map(((list, i) => {
              return (
                <tr>
                  <td>
                    {list.satuanKerja.name}
                  </td>
                  <td>{list.name}</td>
                  <td>
                    <Button size="sm" variant="default" onClick={() => editItem(list)}>
                      <i className="fa fa-pencil"></i>
                    </Button>
                  </td>
                </tr>
              )
            }))}
          </tbody>
        </table>
      </div>
      {meta && (
        <Paginate
          increment={() => increment(meta.current_page, getList)}
          meta={meta}
          decrement={() => decrement(meta.current_page, getList)}
          setLastPage={() => setLastPage(meta.last_page, getList)}
          setFirstPage={() => setFirstPage(meta.from, getList)}
        />
      )}
    </Fragment>
  );
};

export default Content;
