import React, { Fragment, useState } from "react";
import { postRequest } from "config/axios";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setLoading, setStatus } from "redux/GeneralReducers/GeneralActions";
import Buttons from "components/body/buttons/Button";
import Select from "react-select";

const ModalTambahLokasi = (props) => {
  const dispatch = useDispatch();
  const { SuratReducers, GeneralReducers } = useSelector((state) => state);

  const [selectedSkpd, setSkpd] = useState(null);

  const createLokasi = () => {
    dispatch(setLoading(true));
    postRequest(`egs/master/kop-surat`, {
      kode_skpd: selectedSkpd.value,
      satuan_kerja_nama: selectedSkpd.label,
    })
      .then((res) => {
        props.setModal(false);
        props.getList();
        dispatch(setLoading(false));
        setSkpd(null);
        dispatch(
          setStatus({
            status: "success kop-surat",
            msg: "Data berhasil ditambah",
          })
        );
      })
      .catch((err) => {
        dispatch(setLoading(false));
        dispatch(setStatus({ status: "fail", msg: "Data gagal ditambah" }));
        if (err.request.status === 401) {
          dispatch(setStatus({ status: "401", msg: "Unauthenticated" }));
        }
      });
  };

  return (
    <Fragment>
      <Modal
        size="md"
        show={props.modal}
        onHide={() => props.setModal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        animation={false}
      >
        <Modal.Header className="header-modal" closeButton>
          <Modal.Title
            className="title-modal"
            id="example-modal-sizes-title-lg"
          >
            Tambah Lokasi
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row reset-margin-row">
            <div className="col-md-12 content-modal">
              <div className="container-fluid">
                <div className="form-group">
                  <label htmlFor="lokasi">Lokasi*</label>
                  {/* <select
                    className="form-control"
                    onChange={(e) => setKodeSkpd(e.target.value)}
                  >
                    <option>Pilih Lokasi</option>
                    <option value="30.00.11">30.00.11</option>
                  </select> */}
                  <Select
                    defaultValue={selectedSkpd}
                    options={SuratReducers.skpd}
                    onChange={(e) => setSkpd(e)}
                  />
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="row reset-margin-row ">
            <div className="col-md-12 text-right mb-3">
              <Buttons
                onClick={() => props.setModal(false)}
                className="btn btn-default mr-2"
                children="Batal"
              />
              <Buttons
                onClick={createLokasi}
                className="btn btn-primary"
                children="Simpan"
                loading={GeneralReducers.loading}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default ModalTambahLokasi;
