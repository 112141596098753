import React, { Fragment } from 'react'

function Radio({ id, checked, label, name, onChange, value, onKeyPress }, ...props) {
  return (
    <Fragment>
      <input
        type="radio"
        id={id}
        name={name}
        className="custom-control-input"
        checked={checked}
        onChange={onChange}
        value={value}
      />
      <label
        className="custom-control-label"
        htmlFor={id}
      >
        {label}
      </label>
    </Fragment>
  )
}

export default Radio
