import { Form, Modal } from "react-bootstrap";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { set_value_surat_masuk } from "redux/SuratReducers/BuatSuratMasuk/BuatSuratMasukAction";
import useNoSurat from "custom-hook/useNosurat";

const ImageProccessing = (props) => {
    const [image, setImage] = useState("");
    const [noSurat, hal] = useNoSurat(image);
    // console.log(noSurat[0].split(" ")[1])
    const dispatch = useDispatch();
    const { BuatSuratMasukReducers } = useSelector(
      (state) => state
    );
    const {
     
      kepala_surat,
     
    } = BuatSuratMasukReducers;
    useEffect(() => {
       if(noSurat !== 'loading' && noSurat !== '.') {
        dispatch(
                set_value_surat_masuk({
                  value: noSurat?.[0].split(" ")?.[1],
                  name: "no_surat",
                  // perihal: 'hal[0].split(" ").slice(1).join(" ")'
                }),
              )
              dispatch(
                set_value_surat_masuk({
                  value: { ...kepala_surat, perihal: hal?.[0].split(" ").slice(1).join(" ") },
                  name: "kepala_surat",
                })
              )
              props.setShow(false)
           setImage("")
       }
       if(noSurat !== 'loading') {
        dispatch(
                set_value_surat_masuk({
                  value: noSurat?.[0].split(" ")?.[2],
                  name: "no_surat",
                  // perihal: 'hal[0].split(" ").slice(1).join(" ")'
                }),
              )
              dispatch(
                set_value_surat_masuk({
                  value: { ...kepala_surat, perihal: hal?.[0].split(" ").slice(1).join(" ") },
                  name: "kepala_surat",
                })
              )
              props.setShow(false)
           setImage("")
       }
    }, [noSurat])
  return (
    <Fragment>
      <Modal
        size="lg"
        show={props.show}
        onHide={() => props.setShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
       <Modal.Body className="p-3">
       <Form.Group controlId="formFile" className="mb-3">
        <Form.Label>Hanya Mendukung Gambar</Form.Label>
        <Form.Control type="file" onChange={(e) => setImage(e.target.files[0])} />
       {image ? <p> sedang menganalisa gambar... </p> : '' }
       </Form.Group>
       </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default ImageProccessing;