import React, { Fragment } from "react";
import moment from "moment";
import { EditableInput } from "components/body/inputs/EditableInput";

const DataSurat = (props) => {
  const detail = props.details;
  const tembusans = detail && [...detail.tembusan.user, ...detail.tembusan.plt];
  const penerimas = detail && [...detail.penerima.user, ...detail.penerima.plt, ...detail.penerima.user_eksternal];
  console.log("props data surat", props)
  return (
    <Fragment>
      <div className="form-group">
        <hr />
      </div>
      <table className="table table-striped font-12">
        <tbody>
          <tr>
            <td>Tanggal Surat</td>
            <td className="editable-form">
              :
              {detail && (
                <EditableInput
                  getDetails={props.getDetails}
                  editable="tanggal_surat"
                  id={detail._id}
                  value={{
                    tanggal_surat: detail.created_at,
                    nomor_surat: detail.no_surat || "Surat Belum Terkirim",
                  }}
                />
              )}
            </td>
          </tr>
          <tr>
            <td>Nomor Surat Keluar</td>
            <td className="editable-form">
              :
              {detail && (
                <EditableInput
                  getDetails={props.getDetails}
                  editable="nomor_surat"
                  id={detail._id}
                  value={{
                    tanggal_surat: detail.created_at,
                    nomor_surat: detail.no_surat || "Surat Belum Terkirim",
                  }}
                />
              )}
              {/* <input
                type="search"
                disabled={!isEditableNomor}
                defaultValue={
                  
                }
              />
              {isSpinned ? (
                <i className="fa fa-spinner fa-spin"></i>
              ) : isEditableNomor ? (
                <i className="fa fa-save" onClick={saveValue}></i>
              ) : (
                <i className="fa fa-pencil" onClick={editNomorSurat}></i>
              )} */}
              {/* {(detail && detail.no_surat) || "Surat Belum Terkirim"} */}
            </td>
          </tr>
          <tr>
            <td>Dari </td>
            <td>
              :{" "}
              {detail && (
                <span className="tootletip-hover">
                  {detail.pengirim.user.data_pegawai.peg_nama} -{" "}
                  {detail.pengirim.user.data_pegawai.jabatan_nama}
                  <span className="tootletip">
                    {detail.pengirim.user.data_pegawai.peg_nip}
                  </span>
                </span>
              )}
            </td>
          </tr>

          <tr>
            <td>Kepada </td>
            <td>
              :
              <ol style={{ marginTop: "-20px" }}>
                {detail &&
                  penerimas.map((list, i) => (
                    <li key={i} className="tootletip-hover">
                      {list.data_pegawai?.peg_nama ?? list}
                      {list.data_pegawai?.is_plt
                        ? `PLT - ${list.data_pegawai?.data_plt?.jabatan_nama}`
                        : list.data_pegawai?.jabatan_nama}
                      <br />
                      <span className="tootletip">
                        {list.data_pegawai?.peg_nip}
                      </span>
                    </li>
                  ))}
              </ol>
            </td>
          </tr>
          <tr>
            <td>Tembusan </td>
            <td>
              :
              <ol style={{ marginTop: "-20px" }}>
                {detail &&
                  tembusans.map((list, i) => (
                    <li key={i} className="tootletip-hover">
                      {list.data_pegawai.peg_nama} -{" "}
                      {list.data_pegawai.is_plt
                        ? `PLT - ${list.data_pegawai.data_plt.jabatan_nama}`
                        : list.data_pegawai.jabatan_nama}
                      <br />
                      <span className="tootletip">
                        {list.data_pegawai.peg_nip}
                      </span>
                    </li>
                  ))
                }
                {detail?.tembusan?.user_eksternal?.map((list, i) => (
                  <li key={i} className="tootletip-hover">
                    {list}
                  </li>
                ))
                }
              </ol>
            </td>
          </tr>
          <tr>
            <td>Hal </td>
            <td>: {detail && detail.kepala_surat.perihal}</td>
          </tr>
          <tr>
            <td>Kode Klasifikasi </td>
            <td>
              :{" "}
              {detail &&
                `${detail.kepala_surat.klasifikasi
                  ? detail.kepala_surat.klasifikasi.kode
                  : "-"
                } / ${detail.kepala_surat.klasifikasi
                  ? detail.kepala_surat.klasifikasi.nama
                  : "-"
                }`}
            </td>
          </tr>
          <tr>
            <td>Pokok Kegiatan </td>
            <td>
              :{" "}
              {detail && detail.kepala_surat.kegiatan
                ? detail.kepala_surat.kegiatan.nama_kegiatan
                : "-"}
            </td>
          </tr>
          <tr>
            <td>Sifat Penyampaian</td>
            <td>: {detail && detail.kepala_surat.sifat.penyampaian}</td>
          </tr>
          <tr>
            <td>Sifat Pengamanan</td>
            <td>: {detail && detail.kepala_surat.sifat.pengamanan}</td>
          </tr>
          <tr>
            <td>Keterangan </td>
            <td>: - </td>
          </tr>
          <tr>
            <td>Di Entry Oleh </td>
            <td>: {detail && detail.user.user_name}</td>
          </tr>
        </tbody>
      </table>
    </Fragment>
  );
};

const DataSuratSekretariat = (props) => {
  const detail = props.details;
  return (
    <Fragment>
      <div className="form-group">
        <hr />
      </div>
      <table className="table table-striped font-12">
        <tbody>
          <tr>
            <td>Hal </td>
            <td>: {detail && detail.kepala_surat.perihal}</td>
          </tr>
          <tr>
            <td>Tanggal Kirim</td>
            <td>: {detail && moment(detail.tanggal_terima).format("LL")}</td>
          </tr>
          <tr>
            <td>Nomor Surat Keluar</td>
            <td>: {detail && detail.no_surat}</td>
          </tr>
          <tr>
            <td>Resi Surat Keluar</td>
            <td>: {detail && detail.resi}</td>
          </tr>
          <tr>
            <td>Tanggal Surat Keluar</td>
            <td>: {detail && moment(detail.tanggal_surat).format("LL")}</td>
          </tr>
          <tr>
            <td>Dari </td>
            <td>: {detail && detail.dari}</td>
          </tr>
          <tr>
            <td>Kepada </td>
            <td>
              :
              <span className="tootletip-hover">
                {detail && detail.penerima.selected_kepala_login.peg_nama} -
                {detail && detail.penerima.selected_kepala_login.jabatan_nama}
                <span className="tootletip">
                  {detail && detail.penerima.selected_kepala_login.peg_nip}
                </span>
              </span>
            </td>
          </tr>
          <tr>
            <td>Tembusan </td>
            <td>
              :
              {detail && (
                <ol style={{ marginTop: "-23px" }}>
                  {detail.tembusan &&
                    detail.tembusan.user.map((list, i) => (
                      <li key={i} className="tootletip-hover">
                        {list.data_pegawai.peg_nama}-{" "}
                        {list.data_pegawai.jabatan_nama}
                        <span className="tootletip">
                          {list.data_pegawai.peg_nip}
                        </span>
                        <br />{" "}
                      </li>
                    ))}
                  {detail.tembusan &&
                    detail.tembusan.plt.map((list, i) => (
                      <li key={i} className="tootletip-hover">
                        {list.data_pegawai.peg_nama} -{" "}
                        {list.data_pegawai.jabatan_nama}
                        <span className="tootletip">
                          {list.data_pegawai.peg_nip}
                        </span>
                        <br />{" "}
                      </li>
                    ))}
                </ol>
              )}
            </td>
          </tr>

          <tr>
            <td>Kode Klasifikasi </td>
            <td>
              :
              {detail &&
                ` ${detail.kepala_surat.klasifikasi
                  ? detail.kepala_surat.klasifikasi.kode
                  : "-"
                } / ${detail.kepala_surat.klasifikasi
                  ? detail.kepala_surat.klasifikasi.nama
                  : "-"
                }`}
            </td>
          </tr>
          <tr>
            <td>Sifat Penyampaian</td>
            <td>: {detail && detail.kepala_surat.sifat.penyampaian}</td>
          </tr>
          <tr>
            <td>Sifat Pengamanan</td>
            <td>: {detail && detail.kepala_surat.sifat.pengamanan}</td>
          </tr>
          <tr>
            <td>Keterangan </td>
            <td>: - </td>
          </tr>
          <tr>
            <td>Di Entry Oleh </td>
            <td>: {detail && detail.user.user_name}</td>
          </tr>
        </tbody>
      </table>
    </Fragment>
  );
};

export { DataSurat, DataSuratSekretariat };
