import React, { Fragment } from "react";
import Paginate from "components/footers/paginate";
import { useDispatch, useSelector } from "react-redux";
import {
  decrement,
  increment,
  setFirstPage,
  setLastPage,
} from "utils/pagination";
import { getListMethod } from "redux/SuratReducers/ListSuratMasuk/ListSuratAction";
import Loading from "components/body/Loading";
import { useEffect } from "react";
import moment from "moment";

const Content = () => {
  const dispatch = useDispatch();
  const { GeneralReducers, ListSuratReducers } = useSelector(
    (state) => state
  );

  const { loading } = GeneralReducers;
  const { items, meta } = ListSuratReducers.listLogs;
  // let satuan_kerja_id =
  // LoginReducers.userInfo.data_pegawai.satuan_kerja.satuan_kerja_id || 0;
  const getList = (current_page = 1) => {
    dispatch(getListMethod(`esign-activity`, "listLogs", current_page));
  };
  useEffect(getList, []);
  return (
    <Fragment>
      <div className="content-title"></div>
      <div className="content-body">
        <Loading isLoading={loading} />
        <table className="table table-striped table-list">
          <thead>
            <tr>
              <td>User</td>
              <td>Time</td>
              <td>IP</td>
              <td>Aksi</td>
              <td>Status</td>
            </tr>
          </thead>
          <tbody>
            {items &&
              items.map((list, i) => {
                return (
                  <tr key={i}>
                    <td>{list.user_log}</td>
                    <td>{moment(list.time).format("LL h:m")}</td>
                    <td>{list.ip}</td>
                    <td>
                      <a href={list.urlDoc} target="_blank" rel="noopener noreferrer">
                        {list.urlDoc}
                      </a>
                    </td>
                    <td>
                      <span className="badge badge-primary">
                        {list.status_log.text}
                      </span>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      {meta && (
        <Paginate
          meta={meta}
          increment={() => increment(meta.current_page, getList)}
          decrement={() => decrement(meta.current_page, getList)}
          setLastPage={() => setLastPage(meta.last_page, getList)}
          setFirstPage={() => setFirstPage(meta.from, getList)}
        />
      )}
    </Fragment>
  );
};

export default Content;
