import React, { Fragment, useState, useEffect } from "react";
import Paginate from "components/footers/paginate";
import ModalJenisArsip from "./ModalJenisArsip";
import { getRequest } from "config/axios";
import { useDispatch, useSelector } from "react-redux";
import { setLoading, setStatus } from "redux/GeneralReducers/GeneralActions";
import {
  decrement,
  increment,
  setFirstPage,
  setLastPage,
} from "utils/pagination";
import { Button } from "react-bootstrap";
import Loading from "components/body/Loading";

const Content = () => {
  const [modal, setModal] = useState({ modal: false, penyimpanan: {} });
  const dispatch = useDispatch();
  let autoNumber = 0;
  const { GeneralReducers } = useSelector((state) => state);
  const [dataJenisArsip, setjenisArsip] = useState(null);
  const { loading, status, msg } = GeneralReducers;

  const getList = (current_page = 1, q = "") => {
    dispatch(setLoading(true));
    getRequest(`egs/master/arsip/jenis-arsip?page=${current_page}&per_page=5&q${q}`)
      .then((res) => {
        dispatch(setLoading(false));
        setjenisArsip(res.data);
        console.log("objecres datat", res.data)
      })
      .catch((err) => {
        dispatch(setLoading(false));
        if (err.request.status === 401) {
          dispatch(setStatus({ status: "401", msg: "Unauthorization" }));
        } else if (err.message === "Network Error") {
          dispatch(setStatus({ status: "401", msg: err.message }));
        } else {
          dispatch(setStatus({ status: "fail", msg: "Gagal mengambil data" }));
        }
      });
  };

  useEffect(getList, []);

  return (
    <Fragment>
      <ModalJenisArsip
        data={modal}
        getList={getList}
        setModal={setModal}
      />
      <div className="content-title">
        <div>
          <a
            onClick={() =>
              setModal({
                modal: !modal.modal,
                action: "Tambah",
              })
            }
            href="# "
            className="btn btn-sm btn-primary"
          >
            <i className="fa fa-plus"></i> Tambah Jenis Arsip
          </a>
        </div>
        <div>
          <input
            placeholder="Search"
            type="text"
            className="form-control"
            onChange={(e) => {
              console.log("find", e.target.value)
              getList(1, e.target.value)
            }}
          />
        </div>
      </div>
      <div className="content-body">
        {status === "success penyimpanan" && (
          <>
            <div className="alert alert-success">
              {msg}
              <a
                href="# "
                className="pull-right"
                onClick={() => dispatch(setStatus({ status: null, msg: null }))}
              >
                <i className="fa fa-close"></i>
              </a>
            </div>
          </>
        )}
        {/* {loading && (
          <center colSpan="2">
            <i className="fa fa-spinner fa-pulse fa-fw"></i>
          </center>
        )} */}
        {/* SKPD */}
        <div
          className="list-parent-child"
          style={{ padding: "0 50px", fontWeight: "bold" }}
        >
          <div>Nama</div>
          <div>Aksi</div>
        </div>
        <div style={{ paddingRight: "20px" }}>

          <div className="content-body">
            <Loading isLoading={loading} />
            {status === "success jenis arsip" && (
              <>
                <div className="alert alert-success">
                  {msg}
                  <a
                    href="# "
                    className="pull-right"
                    onClick={() => dispatch(setStatus({ status: null, msg: null }))}
                  >
                    <i className="fa fa-close"></i>
                  </a>
                </div>
              </>
            )}
            <table className="table table-striped table-list">
              <thead>
                <tr>
                  <td>No</td>
                  <td>ID</td>
                  <td>Jenis Arsip</td>
                  <td>Edit</td>
                  <td>Hapus</td>
                </tr>
              </thead>
              <tbody>
                {dataJenisArsip && dataJenisArsip.items.map((item, i) => {
                  autoNumber += 1;
                  return (
                    <tr key={i}>
                      <td>{autoNumber}</td>
                      <td>{item.kode}</td>
                      <td>
                        {item.nama}
                      </td>
                      <td>
                        <Button size="sm" variant="default" onClick={() => {
                          setModal({
                            modal: true,
                            action: "Ubah",
                            jenisArsip: item,
                          })
                        }}>
                          <i className="fa fa-pencil"></i>
                        </Button>
                      </td>
                      <td>
                        <Button size="sm" variant="default" onClick={() => {
                          setModal({
                            modal: true,
                            action: "Delete",
                            jenisArsip: item,
                          })
                        }}>
                          <i className="fa fa-trash"></i>
                        </Button>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>

        {/* END SKPD */}
      </div>
      {dataJenisArsip && (
        <Paginate
          meta={dataJenisArsip.meta}
          increment={() =>
            increment(dataJenisArsip.meta.current_page, getList)
          }
          decrement={() =>
            decrement(dataJenisArsip.meta.current_page, getList)
          }
          setLastPage={() =>
            setLastPage(dataJenisArsip.meta.last_page, getList)
          }
          setFirstPage={() => setFirstPage(dataJenisArsip.meta.from, getList)}
        />
      )}
    </Fragment>
  );
};

export default Content;