import React from "react";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { set_value_surat_masuk } from "redux/SuratReducers/BuatSuratMasuk/BuatSuratMasukAction";

function InfoJadwal() {

  const dispatch = useDispatch();
  const defaultValueOfInfoJadwal = {
    tanggal_mulai: new Date(),
    tanggal_berakhir: new Date(),
    tempat: "",
  };

  const {
    BuatSuratMasukReducers: { info_jadwal = defaultValueOfInfoJadwal },
  } = useSelector((state) => state);

  let startDateTime = new Date(info_jadwal.tanggal_mulai);
  let EndDateTime = new Date(info_jadwal.tanggal_berakhir);

  return (
    <div className="form-row">
      <div className="col-md-12">
        <div>Info Jadwal (Jika Ada)</div>
        <label htmlFor="sifat_penyampaian">Tanggal (Mulai - Selesai)</label>
        <div className="form-row">
          <div className="form-group input-group-ico">
            <DatePicker
              placeholderText="Dari"
              className="form-control"
              style={{ width: "100%" }}
              dateFormat="d MMMM yyyy - HH:mm"
              timeIntervals={15}
              onChange={(e) => {
                dispatch(
                  set_value_surat_masuk({
                    value: { ...info_jadwal, tanggal_mulai: e },
                    name: "info_jadwal",
                  })
                );
              }}
              showTimeSelect
              selectsStart
              selected={startDateTime}
              startDate={startDateTime}
              endDate={EndDateTime}
            />
            <i className="fa fa-calendar-o"></i>
          </div>
          <div className="form-group input-group-ico">
            <DatePicker
              placeholderText="Hingga"
              className="form-control"
              dateFormat="d MMMM yyyy - HH:mm"
              timeIntervals={15}
              onChange={(e) => {
                dispatch(
                  set_value_surat_masuk({
                    value: { ...info_jadwal, tanggal_berakhir: e },
                    name: "info_jadwal",
                  })
                );
              }}
              showTimeSelect
              selectsEnd
              selected={EndDateTime}
              startDate={startDateTime}
              endDate={EndDateTime}
              minDate={startDateTime}
            />
            <i className="fa fa-calendar-o"></i>
          </div>
        </div>
      </div>
      <div className="form-group col-md-12 m-t-10">
        <label htmlFor="jabatan">Tempat *</label>
        <input
          type="text"
          className="form-control"
          defaultValue={info_jadwal.tempat}
          onChange={(e) =>
            dispatch(
              set_value_surat_masuk({
                value: { ...info_jadwal, tempat: e.target.value },
                name: "info_jadwal",
              })
            )
          }
        />
      </div>
    </div>
  );
}


export default InfoJadwal;
