/* eslint-disable array-callback-return */
import {
  SET_TOKEN_FCM,
  SET_TOKEN,
  SET_USER_INFO,
  SET_MENU,
} from "../rootRedux/TypeActions";
import { getRequest, setTokenToHeader, postRequest } from "config/axios";
import {
  checkError,
  setLoading,
  setStatus,
  Unauthorized,
} from "redux/GeneralReducers/GeneralActions";
import { getSkpd } from "redux/SuratReducers/SuratAction";
import { store } from "redux/rootRedux/Store";
import { messaging } from "../../firebase";
import { getCount } from "redux/Notifications/NotificationActions";

export const set_token = (data) => ({
  type: SET_TOKEN,
  payload: data,
});

export const set_user_info = (data) => ({
  type: SET_USER_INFO,
  payload: data,
});

export const set_menus = (data) => ({
  type: SET_MENU,
  payload: data,
});

export const set_token_fcm = (data) => ({
  type: SET_TOKEN_FCM,
  payload: data,
});

export const login = (credential) => {
  return (dispatch) => {
    let fcm_token = store.getState().LoginReducers.fcm_token;
    // console.log("credential", { ...credential, fcm_token });
    postRequest("/api/egs/token", {
      ...credential,
      fcm_token,
    })
      .then((res) => {
        setTokenToHeader(
          `${res.data.data.token_type} ${res.data.data.access_token}`
        );
        dispatch(
          set_token({
            access_token: res.data.data.access_token,
            refresh_token: res.data.data.refresh_token,
          })
        );
        dispatch(getUser(credential.username));
        dispatch(getMenus());
        dispatch(getCount());
      })
      .catch((err) => {
        console.log("ERROR LOGIN", err);
        console.log("ERROR LOGIN MESSAGE", err.message);

        console.log("ERROR REQ LOGIN", err.request);
        console.log("ERROR RES LOGIN", err.response);
        dispatch(setLoading(false));
        if (err.request.status === 401 || err.request.status === 400) {
          dispatch(setStatus({ status: "error", msg: err.request.data.diagnostics.message }));
        } else if (err.response) {
          dispatch(setStatus({ status: "error", msg: err.response.data.diagnostics.message }));
        } else {
          dispatch(setStatus({ status: "error", msg: err.message }));
        }
      });
  };
};

export const get_logout = (data) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    postRequest(`/api/egs/revoke`, { fcm_token: data })
      .then((res) => {
        console.log("respon logout", res);
        dispatch(
          set_token({
            access_token: null,
            refresh_token: null,
          })
        );
        dispatch(set_user_info({ data: null, isLogin: false }));
        dispatch(setStatus({ status: null, msg: null }));
        dispatch(setLoading(false));
        dispatch(Unauthorized(false));
        console.log("call logout");
      })
      .catch((err) => {
        dispatch(setLoading(false));
        if (err.request.status === 401 || err.request.status === 400) {
          dispatch(setStatus({ status: "error", msg: "Login Gagal" }));
        } else {
          dispatch(setStatus({ status: "error", msg: err.message }));
        }
      });
  };
};

export const logout_with_del_token_fcm = () => {
  return (dispatch) => {
    // console.log("logout ka");
    dispatch(setLoading(true));
    const { fcm_token } = store.getState().LoginReducers;

    messaging
      .deleteToken()
      .then((res) => {
        // console.log("resposn delete token", res);
        dispatch(get_logout(fcm_token));
        // console.log("token yang di delete", fcm_token);
        dispatch(
          set_token({
            access_token: null,
            refresh_token: null,
          })
        );
        dispatch(set_user_info({ data: null, isLogin: false }));
        dispatch(setStatus({ status: null, msg: null }));
        dispatch(setLoading(false));
        dispatch(Unauthorized(false));
        console.log("call logout");
      })
      .catch((err) => {
        dispatch(setLoading(false));
        console.log("error delete token", err);
      });
  };
};

export const getUser = (username) => {
  return (dispatch) => {
    getRequest(`/api/egs/user?username=${username}`)
      .then((res) => {
        // dispatch(set_user_info(res.data));
        // console.log("respon get user info", res);
        dispatch(set_user_info({ data: res.data.data, isLogin: true }));
        dispatch(getSkpd());
        if (res.data.data) {
          dispatch(setLoading(false));
        }
      })
      .catch((err) => {
        console.log("ERROR GET USER INFO", err);
        console.log("ERROR GET USER MESSAGE", err.message);

        console.log("ERROR GET USER INFO", err.response);
        dispatch(setLoading(false));
        dispatch(
          setStatus({ status: "error", msg: "Gagal mengambil data profile" })
        );
      });
  };
};

const setRoute = (links) => {
  return (dispatch) => {
    let routers = [];
    links.map((item) => {
      routers.push({ component: item.name, path: item.path });
      item.sub_link.map((item2) => {
        routers.push({ component: item2.component, path: item2.path });
        item2.route_include.map((item3) =>
          routers.push({ component: item3.component, path: item3.path })
        );
      });
    });
    dispatch(set_menus({ menus: links, routers }));
  };
};

export const getMenus = () => {
  return (dispatch) => {
    getRequest(`/egs/master/menu`)
      .then((res) => {
        dispatch(setRoute(res.data.items));
      })
      .catch((err) => dispatch(checkError(err)));
  };
};
