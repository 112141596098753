import { getRequest, postFileRequest, postRequest } from "config/axios";
import { validateSuratKeluar } from "utils/validate";
import {
  checkError,
  setLoading,
  setStatus,
} from "redux/GeneralReducers/GeneralActions";
import {
  SET_VALUE,
  RESET_VALUE,
  EDIT_SURAT_KELUAR,
} from "redux/rootRedux/TypeActions";

export const set_value = (data) => ({
  type: SET_VALUE,
  payload: data,
});

export const reset_value = () => ({
  type: RESET_VALUE,
});

export const edit_surat_keluar = (data) => ({
  type: EDIT_SURAT_KELUAR,
  payload: data,
});

const filterNip = (params) =>
  params.map(({ data_pegawai: { peg_nip } }) => peg_nip);

// const uploadFileForSoftCopy = (attachment, initId) => {
//   const data = new FormData();
//   data.append('file', attachment.originalFile)
//   data.append('name', initId)
//   postRequest(`/egs/mail/pdf`, data)
//     .then(res => {
//       console.log("respon upload files", res);
//       dispatch(set_value({ value: { ...attachment, signedFile: res.data.items.url }, name: "attachment" }))
//       saveSurat()
//     }).catch(err => console.log("error post file", err))
// }

export const submitSuratKeluar = (data) => {
  return async (dispatch) => {
    const status = validateSuratKeluar(data);
    const { pemeriksa, penerima, tembusan, attachment, initId } = data;
    dispatch(setLoading(true));
    if (status.error) {
      dispatch(
        setStatus({
          status: "alert-surat-keluar",
          msg: status.msg,
        })
      );
      dispatch(setLoading(false));
      return new Promise((res, rej) => {
        rej("gagal");
      });
    } else {
      const dataPemeriksaUser = filterNip(pemeriksa.user);
      const dataPenerima = filterNip(penerima.user);
      const dataPenerimaPlt = filterNip(penerima.plt);
      const dataTembusan = filterNip(tembusan.user);
      const dataTembusanPlt = filterNip(tembusan.plt);
      const readytoSend = {
        ...data,
        pemeriksa: { user: [...dataPemeriksaUser] },
        penerima: {
          ...data.penerima,
          user: dataPenerima,
          plt: dataPenerimaPlt,
        },
        tembusan: {
          ...data.tembusan,
          user: dataTembusan,
          plt: dataTembusanPlt,
        },
      };
      console.log('ready to send', readytoSend)
      const fetched = await fetch(attachment.signedFile).then(e => e.blob())

      const files = new FormData();
      files.append('file', fetched, initId)
      files.append('originalFile', attachment.originalFile)
      files.append('name', initId)

      return postFileRequest(`/egs/mail/pdf`, files)
        .then(async res => {
          console.log("respon upload files", res);
          // dispatch(set_value({ value: { ...attachment, signedFile: res.data.items.url }, name: "attachment" }))
          let attachmentFilesTo = { ...readytoSend, attachment: { originalFile: res.data.items.originalFile, signedFile: res.data.items.url } }

          console.log("LAST FILE TO SEND", attachmentFilesTo);
          return postRequest(`egs/mail`, attachmentFilesTo)
            .then((res) => {
              dispatch(setLoading(false));
              dispatch(reset_value());
              dispatch(
                setStatus({ status: "success surat-keluar", msg: "Success" })
              );
              return new Promise((respon) => {
                respon(res.data.items.id);
              });
            })
            .catch((err) => {
              dispatch(checkError(err, "alert-surat-keluar"));
              return new Promise((res, rej) => {
                rej(err);
              });
            });
        }).catch(err => console.log("error post file", err))

    }
  };
};

export const editSuratKeluar = (id) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    getRequest(`egs/mail/${id}`)
      .then((res) => {
        dispatch(setLoading(false));
        dispatch(edit_surat_keluar(res.data.items));
      })
      .catch((err) => dispatch(checkError(err, "get-detail-edit-error")));
  };
};

export const updateSuratKeluar = (id, data) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    return postRequest(`egs/riw-catatan/create/${id}`, data)
      .then((res) => {
        dispatch(setLoading(false));
        return new Promise((res) => {
          res(true);
        });
      })
      .catch((err) => {
        dispatch(checkError(err, "alert-surat-keluar"));
        return new Promise((res, rej) => {
          rej(err);
        });
      });
  };
};
