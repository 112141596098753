import React, { Fragment, useState } from "react";
import { Link, useHistory, withRouter } from "react-router-dom";
import ModalInfo from "./cards/ModalInfo";
import { useSelector } from "react-redux";

function SubNavbar(props) {
  const [lgShow, setLgShow] = useState(false);
  const { GeneralReducers, BuatSuratMasukReducers: { data_pengirim: { nomor_wa } } } = useSelector((state) => state);

  const history = useHistory();

  const {
    wa_btn = false,
    info_btn = false,
    print_action = null,
  } = props;

  const { id, tipe } = props.match.params;
  const path = props.match.path.split("/");
  const kategori = path[2];

  return (
    <Fragment>
      <ModalInfo lgShow={lgShow} setLgShow={setLgShow} />
      <div className="label-form-sub-nav m-0-10">
        <Link
          to="# "
          onClick={() => history.goBack()}
          className="btn btn-default"
        >
          <i className="fa fa-arrow-left"></i> Kembali
        </Link>
      </div>
      <div className="ex-btn-form-sub-nav m-0-10">
        {wa_btn &&
          <a
            href={`https://wa.me/${nomor_wa}`}
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-sm btn-success m-r-5"
          >
            <i className="fa fa-whatsapp"></i> Hubungi Via WhatsApp
          </a>
        }
        {info_btn &&
          <a
            href="# "
            onClick={() => setLgShow(!lgShow)}
            className="btn btn-sm btn-info m-r-5"
          >
            <i className="fa fa-info"></i> INFO
          </a>
        }
        {print_action &&
          <Link to={{
            pathname: `${print_action.path}`,
          }} rel="noopener noreferrer" className="m-l-10">
            <i className="fa fa-print"></i> {print_action.label}
          </Link>
        }
        {kategori === "terkirim" && (
          <>
            <a
              href={`https://wa.me/${nomor_wa}`}
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-sm btn-success m-r-5"
            >
              <i className="fa fa-whatsapp"></i> Hubungi Via WhatsApp
            </a>
            <a
              href="# "
              onClick={() => setLgShow(!lgShow)}
              className="btn btn-sm btn-info m-r-5"
            >
              <i className="fa fa-info"></i> INFO
            </a>
          </>
        )}
        {kategori === "persetujuan" || kategori === "konsep"
          ? GeneralReducers.status !== "detail-error" && (
            <Link
              to={`/surat-keluar/${kategori}/detail/edit/${id}`}
              className="btn btn-default"
            >
              <i className="fa fa-pencil"></i> EDIT
            </Link>
          )
          : tipe === "non-sekretariat" && ""}
      </div>
    </Fragment>
  );
}

export default withRouter(SubNavbar);
