import { combineReducers } from "redux";
// import LoginReducers from "../loginRedux/LoginReducers";
// import { GeneralReducers } from "redux/GeneralReducers/GeneralReducers";
// import { SuratReducers } from "redux/SuratReducers/SuratReducers";
// import { BuatSuratReducers } from "redux/SuratReducers/BuatSurat/BuatSuratReducers";
// import { BuatSuratMasukReducers } from "redux/SuratReducers/BuatSuratMasuk/BuatSuratMasukReducers";
// import { ListSuratReducers } from "redux/SuratReducers/ListSuratMasuk/ListSuratReducers";
// import { NotificationReducers } from "redux/Notifications/NotificationReducers";
import {
  LoginReducers,
  GeneralReducers,
  SuratReducers,
  BuatSuratReducers,
  BuatSuratMasukReducers,
  ListSuratReducers,
  NotificationReducers,
  ListOptionReducers
} from "redux/rootRedux";

const RootReducers = combineReducers({
  LoginReducers,
  GeneralReducers,
  SuratReducers,
  BuatSuratReducers,
  BuatSuratMasukReducers,
  ListSuratReducers,
  NotificationReducers,
  ListOptionReducers
});

export default RootReducers;
