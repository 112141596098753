/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState, useCallback } from "react";
import { getRequest } from "config/axios";
import { Modal, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { checkError, setLoading } from "redux/GeneralReducers/GeneralActions";
import { set_value } from "redux/SuratReducers/BuatSurat/BuatSuratActions";
import Loading from "components/body/Loading";
import { debounce } from "lodash";

const ModalKegiatan = (props) => {
  const [listKegiatan, setListKegiatan] = useState(null);
  const [q, setQ] = useState("");
  const [selectedKegiatan, setSelectedKegiatan] = useState(null);
  const dispatch = useDispatch();
  const {
    GeneralReducers,
    LoginReducers: {
      userInfo: {
        data_pegawai: {
          satuan_kerja: { kode_skpd },
        },
      },
    },
    BuatSuratReducers,
  } = useSelector((state) => state);

  const { kepala_surat } = BuatSuratReducers;
  const getKegiatan = (page = 1, q = "") => {
    dispatch(setLoading(true));
    setSelectedKegiatan(null);
    getRequest(`api/master/kegiatan?kode_skpd=${kode_skpd}&q=${q}&page=${page}&per_page=10`)
      .then((res) => {
        setListKegiatan(res.data);
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(checkError(err));
      });
  };

  const debounceSave = useCallback(
    debounce((current_page, q) => getKegiatan(current_page, q), 1000),
    []
  );

  const select_kegiatan = (item) => {
    const list = listKegiatan.data.map((list) => {
      return item.kode_kegiatan === list.kode_kegiatan
        ? {...list, active_selected: true, }
        : { ...list, active_selected: false };
    });
    setListKegiatan({ ...listKegiatan, data: list });
    setSelectedKegiatan(item);
  };

  const setKegiatan = () => {
    dispatch(set_value({
        value: { ...kepala_surat, kegiatan: selectedKegiatan },
        name: "kepala_surat",
    }));
    props.setLgShow(false);
  };

  useEffect(() => debounceSave(1, q), [q]);

  return (
    <Fragment>
      <Modal
        size="lg"
        show={props.lgShow}
        onHide={() => props.setLgShow(false)}
        onShow={() => setQ("")}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header className="header-modal" closeButton>
          <Modal.Title
            className="title-modal"
            id="example-modal-sizes-title-lg"
          >
            Nama Kegiatan
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row reset-margin-row">
            <div className="col-md-12 content-modal">
              <div className="title-tab-second bg-white no-border-bottom text-right">
                <span className="mr-3">
                  page {listKegiatan && listKegiatan.current_page} dari{" "}
                  {listKegiatan && listKegiatan.last_page}
                </span>
                <i
                  className="fa fa-chevron-left mr-3 pointer-mouse"
                  onClick={() => {
                    listKegiatan.current_page > 1 &&
                      getKegiatan(listKegiatan.current_page - 1, q);
                  }}
                ></i>
                <i
                  className="fa fa-chevron-right pointer-mouse"
                  onClick={() => {
                    listKegiatan.current_page < listKegiatan.last_page &&
                      getKegiatan(listKegiatan.current_page + 1, q);
                  }}
                ></i>
              </div>
              <Loading isLoading={GeneralReducers.loading} />
              <Table striped bordered hover className="table-modal-content">
                <thead>
                  <tr>
                    <th>Kode Kegiatan</th>
                    <th>Nama Kegiatan</th>
                  </tr>
                </thead>
                <tbody>
                  {listKegiatan &&
                    listKegiatan.data.map((view, i) => {
                      return (
                        <tr
                          key={i}
                          onClick={() => select_kegiatan(view)}
                          className={`pointer-mouse ${
                            view.active_selected && `active-selected`
                          }`}
                        >
                          <td>{view.unit_id}</td>
                          <td>{view.nama_kegiatan}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </div>
          </div>
          <hr />
          <div className="row reset-margin-row ">
            <div className="col-md-4 mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Kata Kunci"
                value={q}
                onChange={(e) => setQ(e.target.value)}
              />
            </div>
            <div className="col-md-5 mb-3"></div>
            <div className="col-md-3 tabs-header-modal space-table mb-3">
              {selectedKegiatan && (
                <button
                  onClick={setKegiatan}
                  className="btn col-md-12 btn-primary mr-2"
                >
                  Oke
                </button>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default ModalKegiatan;
