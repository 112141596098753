import React from "react";

const Buttons = ({ children, loading, ...rest }) => {
  return (
    <button {...rest}>
      {loading ? <i className="fa fa-spinner fa-pulse fa-fw"></i> : children}
    </button>
  );
};

export default Buttons;
