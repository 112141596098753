import { postRequest } from "config/axios";
import { validateSuratMasuk } from "utils/validate";
import {
  checkError,
  setLoading,
  setStatus,
} from "redux/GeneralReducers/GeneralActions";
import {
  RESET_VALUE_SURAT_MASUK,
  SET_SURAT_MASUK,
  SET_VALUE_SURAT_MASUK,
} from "redux/rootRedux/TypeActions";

export const set_value_surat_masuk = (payload) => ({
  type: SET_VALUE_SURAT_MASUK,
  payload,
});

export const reset_value_surat_masuk = () => ({
  type: RESET_VALUE_SURAT_MASUK,
});

export const set_surat_masuk = (payload) => ({
  type: SET_SURAT_MASUK,
  payload
});

const filterNip = (params) =>
  params.map(({ data_pegawai: { peg_nip } }) => peg_nip);

export const submitSuratMasuk = (data) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    const status = validateSuratMasuk(data);
    const {penerima, tembusan} = data;
    if (status.error) {
      dispatch(setStatus({
          status: "alert-surat-masuk",
          msg: status.msg,
      }));
      dispatch(setLoading(false));
    } else {
      const dataPenerima = filterNip(penerima.user);
      const dataPenerimaPlt = filterNip(penerima.plt);
      const dataTembusan = filterNip(tembusan.user);
      const dataTembusanPlt = filterNip(tembusan.plt);
      let dataSurat = {
        ...data,
        penerima: {
          user: dataPenerima,
          plt: dataPenerimaPlt,
        },
        tembusan: {
          user: dataTembusan,
          plt: dataTembusanPlt,
        },
      };
      postRequest(`egs/mail`, dataSurat)
        .then((res) => {
          dispatch(setLoading(false));
          dispatch(reset_value_surat_masuk());
          dispatch(
            setStatus({ status: "success surat-masuk", msg: "Success" })
          );
        })
        .catch((err) => dispatch(checkError(err)));
    }
  };
};
