import React from 'react'

function LostConnection() {
  return (
    <div className="notifConnection" style={{ display: "none" }}>
        You lost connection ! Please, check your connection.
    </div>
  )
}

export default LostConnection
