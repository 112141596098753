import React, { Fragment, useEffect } from "react";
import Paginate from "components/footers/paginate";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import { getListMethod } from "redux/SuratReducers/ListSuratMasuk/ListSuratAction";
import {
  decrement,
  increment,
  setFirstPage,
  setLastPage,
} from "utils/pagination";
import Loading from "components/body/Loading";

const Content = () => {
  const dispatch = useDispatch();

  const {
    GeneralReducers,
    ListSuratReducers,
    NotificationReducers,
  } = useSelector((state) => state);

  const { items, meta } = ListSuratReducers.listPersetujuan;
  const { persetujuan_count } = NotificationReducers;
  const { loading } = GeneralReducers;

  const getList = (current_page = 1) => {
    dispatch(
      getListMethod("persetujuan/mail", "listPersetujuan", current_page)
    );
  };

  useEffect(getList, [persetujuan_count]);

  return (
    <Fragment>
      <div className="content-title">
      </div>
      <div className="content-body">
        <Loading isLoading={loading} />
        <table className="table table-striped table-list">
          <thead>
            <tr>
              <td>Tgl Dibuat</td>
              <td>Dari</td>
              <td>Hal</td>
              <td>Status</td>
            </tr>
          </thead>
          <tbody>
            {items &&
              items.map((list, i) => {
                return (
                  <tr key={i}>
                    <td>
                      <Link to={`/surat-keluar/persetujuan/detail/${list.id}`}>
                        {moment(list.tanggal).format("LL")}{" "}
                        {!list.is_read && (
                          <span className="badge badge-success">unread</span>
                        )}
                      </Link>
                    </td>
                    <td>{list.dari}</td>
                    <td>{list.hal}</td>
                    <td>
                      <i className="badge-status badge-success">
                        {list.status}
                      </i>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      {meta && (
        <Paginate
          meta={meta}
          increment={() => increment(meta.current_page, getList)}
          decrement={() => decrement(meta.current_page, getList)}
          setLastPage={() => setLastPage(meta.last_page, getList)}
          setFirstPage={() => setFirstPage(meta.from, getList)}
        />
      )}
    </Fragment>
  );
};

export default Content;
