import moment from 'moment';
// import { useEffect } from 'react';
import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Paginate from 'components/footers/paginate';
import { useSelector, useDispatch } from 'react-redux';
// import { getListMethod } from 'redux/SuratReducers/ListSuratMasuk/ListSuratAction';
import {
  decrement,
  increment,
  setFirstPage,
  setLastPage,
} from 'utils/pagination';
import { checkError, setLoading } from 'redux/GeneralReducers/GeneralActions';
import { getRequest } from 'config/axios';

const ViewRiwayatMasuk = (props) => {
  const [list, setList] = useState(null);
  const [no_surat, setNomor] = useState(null);
  const { ListSuratReducers, LoginReducers: { userInfo: { data_pegawai: { satuan_kerja } } } } = useSelector(
    (state) => state
  );
  const { items, meta } = ListSuratReducers.listBankSuratMasuk;
  const dispatch = useDispatch()

  const getListDetail = () => {
    dispatch(setLoading(true));
    getRequest(`egs/mail/${props.match.params.id}`)
      .then((res) => {
        console.log("get detail in riwayat detail", res)
        dispatch(setLoading(false));
        setNomor(res.data.items.no_surat);
        gatheringList(res.data.items.disposisi);
      })
      .catch((err) => {
        dispatch(checkError(err, "detail-error"));
      });
  };

  const gatheringList = (items) => {
    const newList = [...items.user, ...items.plt];
    setList(newList);
  }

  useEffect(getListDetail, []);
  console.log("get list of riwayat", satuan_kerja)
  return (
    <div className="view-print__content" >
      <div className="content-wrapper">
        Nomor surat : <b> {no_surat} </b>
        <br />{satuan_kerja.satuan_kerja_nama}
        <br />
        <br />
        <div className='content-body'>
          {list &&
            list.map((list, i) => {
              return (
                <div key={i} className="col-md-12 item-content-table font-12" style={{ width: '100vw' }}>
                  <div className="content-table-card">
                    <img
                      src={list.photo_url}
                      alt="images"
                      width="60"
                      className="content-table-images"
                    />
                    <div className="content-table-text">
                      <div className="content-table-text-row">
                        <span className="label-table">Dari</span>
                        <span>: {list.action.dari && list.action.dari}</span>
                      </div>
                      <div className="content-table-text-row">
                        <span className="label-table">Kepada</span>
                        <span>: {list.data_pegawai.peg_nama}</span>
                      </div>
                      {list.action.is_disposisi && (
                        <div className="content-table-text-row">
                          <span className="label-table">Petunjuk</span>
                          <span>
                            :{" "}
                            {list.action.text.map((list, i) => {
                              return (
                                <span key={i} className="inline-display">
                                  {list}
                                </span>
                              );
                            })}
                          </span>
                        </div>
                      )}

                      <div className="content-table-text-row">
                        <span className="label-table">Tentang</span>
                        <span>: {list.action.keterangan}</span>
                      </div>
                    </div>
                  </div>
                  <div className="content-table-card mr-5">
                    {moment(list.action.created_at).format("LL, HH:MM:ss")}
                  </div>
                </div>
              );
            })}
        </div>
        {meta && (
          <Paginate
            meta={meta}
            increment={() => increment(meta.current_page, null)}
            decrement={() => decrement(meta.current_page, null)}
            setLastPage={() => setLastPage(meta.last_page, null)}
            setFirstPage={() => setFirstPage(meta.from, null)}
          />
        )}
      </div>
    </div>
  );
};

export default withRouter(ViewRiwayatMasuk);
