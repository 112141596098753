import React, { Fragment, useEffect, useState } from "react";
import { checkError, setLoading } from "redux/GeneralReducers/GeneralActions";

import Buttons from "components/body/buttons/Button";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { filterMaster } from "utils/filter-master";
import { getRequest } from "config/axios";
// import SelectSatuanKerja from "components/body/selects/selectSatuanKerja";
import { putRequest } from 'config/axios'
import { useDispatch } from "react-redux";

const EditKontrak = ({ isOpenEdit, setOpenEdit, idTenagaKontrak, getTenagaKontrak }) => {
  const dispatch = useDispatch();
  const [dataUnitKerja, setDataUnitKerja] = useState(null);
  const [dataJabatan] = useState([{ label: 'Tenaga Kontrak', value: 99999 }]);
  const [dataSkpd, setDataSkpd] = useState(null);
  const [id, setId] = useState("");
  const [name, setName] = useState("")
  const [nik, setNik] = useState("")
  const [satuanKerja, setSatuanKerja]= useState("")
  const [unitKerja, setUnitKerja] = useState("")
  // const [jabatan, setJabatan] = useState("")


  const editPegawaiKontrak = () => {
    console.log(name,nik,satuanKerja.value,unitKerja.value)
    const data = {
    satuan_kerja_id: satuanKerja.value,
    jabatan_id: 99999,
    unit_kerja_id: unitKerja.value,
    nik: nik,
    name: name,
    status: true
}
    putRequest(`egs/pegawai-egs/api/pegawai-kontrak/${idTenagaKontrak}`, data)
      .then(res => {
        console.log("response post pegawai", res)
        setOpenEdit(false)
        getTenagaKontrak(id)
      }).catch(error => console.log("error post pegawai", error))
  }

  const getSkpd = () => {
    dispatch(setLoading(true));
    getRequest(`egs/pegawai-egs/api/satuan-kerja`)
      .then((res) => {
        dispatch(setLoading(false));
        filterMaster(setDataSkpd,res.data);
      })
      .catch((err) => dispatch(checkError(err)));
  };
  const getUnitKerja = (e) => {
    setSatuanKerja(e)
    setId(e.value)
    dispatch(setLoading(true));
    getRequest(`egs/pegawai-egs/api/unit-kerja/?satuan_kerja_id=${id}`)
      .then((res) => {
        dispatch(setLoading(false));
        filterMaster(setDataUnitKerja,res.data);
      })
      .catch((err) => dispatch(checkError(err)));
  };


  const getDetailKontrak = () => {
    dispatch(setLoading(true));
    getRequest(`egs/pegawai-egs/api/pegawai-kontrak/${idTenagaKontrak}`)
      .then((res) => {
        dispatch(setLoading(false));
        setName(res.data.items.name)
        setNik(res.data.items.nik)
        setId(res.data.items.satuanKerja.id)
        setSatuanKerja({label:res.data.items.satuanKerja.name, value:res.data.items.satuanKerja.id})
        setUnitKerja({label:res.data.items.unitKerja.name, value:res.data.items.unitKerja.id})
        setDataUnitKerja([{label:res.data.items.unitKerja.name, value:res.data.items.unitKerja.id}])
        // setJabatan(res.data.items.jabatan?.id)
      })
      .catch((err) => dispatch(checkError(err)));
  };
  useEffect(getSkpd, [isOpenEdit]);  
  // useEffect(getJabatan, [isOpenAdd]);
  useEffect(getDetailKontrak, [isOpenEdit]);
  return (
    <Fragment>
      <Modal
        size="md"
        show={isOpenEdit}
        onHide={() => setOpenEdit(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        animation={false}
      >
        <Modal.Header className="header-modal" closeButton>
          <Modal.Title
            className="title-modal"
            id="example-modal-sizes-title-lg"
          >
            Edit Pegawai
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row reset-margin-row">
            <div className="col-md-12 content-modal">
              <div className="container-fluid">
                <div className="form-group">
                  <label htmlFor="nama">Nama*</label>
                  <input
                    type="text"
                    className="form-control"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="nik">NIK*</label>
                  <input
                    type="text"
                    className="form-control"
                    value={nik}
                    onChange={(e) => setNik(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="satuan_kerja">Satuan Kerja*</label>
                  <Select
                    placeholder="Pilih Satuan Kerja"
                    className="m-b-5"
                    value={satuanKerja}
                    options={dataSkpd}
                    onChange={(e) => getUnitKerja(e)}
                  />
                </div>
                 <div className="form-group">
                  <label htmlFor="unit_kerja">Unit kerja*</label>
                  <Select
                    placeholder="Pilih Unit Kerja"
                    className="m-b-5"
                    value={unitKerja}
                    options={dataUnitKerja}
                    onChange={(e) => setUnitKerja(e)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="jabatan">Jabatan*</label>
                  <Select
                    placeholder="Pilih Jabatan"
                    className="m-b-5"
                    jabatan
                    value={{ label: 'Tenaga Kontrak', value: 99999 }}
                    options={dataJabatan}
                  />
                </div>

              </div>
            </div>
          </div>
          <hr />
          <div className="row reset-margin-row ">
            <div className="col-md-6 mb-3">
              <Buttons
                className="btn btn-danger mr-2"
                children="Hapus"
              />
            </div>
            <div className="col-md-6 text-right mb-3">
              <Buttons
                onClick={() => setOpenEdit(false)}
                className="btn btn-default mr-2"
                children="Batal"
              />
              <Buttons
                // onClick={createLokasi}
                className="btn btn-primary"
                children="Simpan"
                // loading={GeneralReducers.loading}
                onClick={editPegawaiKontrak}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default EditKontrak;
