import moment from 'moment';
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import Paginate from 'components/footers/paginate';
import { useSelector } from 'react-redux';

import {
  decrement,
  increment,
  setFirstPage,
  setLastPage,
} from 'utils/pagination';

const ContentToViewKeluar = (props) => {
  const { ListSuratReducers } = useSelector(
    (state) => state
  );

  const { items, meta } = ListSuratReducers.listBankSuratKeluar;
  console.log("print to view", props)
  return (
    <div className="view-print__content">
      <div className="content-wrapper">
        <div className='content-body'>
          <table className='table table-striped table-list'>
            <thead>
              <tr>
                <td width='130px'>Tanggal Di Buat</td>
                <td>Pengirim</td>
                <td>Hal</td>
                <td>Status</td>
                <td>Jenis Surat</td>
              </tr>
            </thead>
            <tbody>
              {items &&
                items.map((list, i) => {
                  return (
                    <tr key={i}>
                      <td>
                        <Link to={`/bank-surat/list/detail/${list.id}`}>
                          {moment(list.tanggal).format('LL')}
                        </Link>
                      </td>
                      <td>
                        <b>{list.pengirim.peg_nama}</b>
                        <p>{list.pengirim.peg_jabatan}</p>
                        {list.dari}
                      </td>
                      <td>{list.hal}</td>
                      <td>
                        <span className='badge badge-primary'>
                          {list.status.text}
                        </span>
                      </td>
                      <td>{list.status_mail}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        {meta && (
          <Paginate
            meta={meta}
            increment={() => increment(meta.current_page, null)}
            decrement={() => decrement(meta.current_page, null)}
            setLastPage={() => setLastPage(meta.last_page, null)}
            setFirstPage={() => setFirstPage(meta.from, null)}
          />
        )}
      </div>
    </div>
  );
};

export default withRouter(ContentToViewKeluar);
