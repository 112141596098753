import React, { Fragment } from "react";
// import { Link } from "react-router-dom";

const LampiranSurat = (props) => {
  const detail = props.details;
  // const openInNewTab = (url) => {
  //   const win = window.open(url, "_blank");
  //   win.focus();
  // };

  return (
    <Fragment>
      {/* <div className="content-title">
        <div className="content-filter">Lampiran Surat</div>
      </div> */}
      <hr />
      <div className="content-body">
        <div className="row reset-row">
          <div className="col-md-3">Dokumen Surat / Fax : </div>
          <div className="col-md-9">
            {detail &&
              detail.lampiran.map((list, i) => {
                return (
                  <div key={i}>
                    <a
                      href={list.file_url}
                      download
                      target="_blank"
                      rel="noopener noreferrer"
                    // onClick={() => openInNewTab(list.file_url)}
                    >
                      <span className="badge badge-success">
                        {list.file_name}
                      </span>
                    </a>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default LampiranSurat;
