import React, { Fragment } from "react";
import Paginate from "components/footers/paginate";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getListMethod } from "redux/SuratReducers/ListSuratMasuk/ListSuratAction";
import {
  decrement,
  increment,
  setFirstPage,
  setLastPage,
} from "utils/pagination";
import Loading from "components/body/Loading";
import moment from "moment";

const Content = () => {
  const dispatch = useDispatch();
  const { GeneralReducers, ListSuratReducers } = useSelector((state) => state);
  const { loading } = GeneralReducers;
  const { items, meta } = ListSuratReducers.listTerkirimInternal;

  const getList = (current_page = 1) => {
    dispatch(
      getListMethod("terkirim/mail", "listTerkirimInternal", current_page)
    );
  };

  return (
    <Fragment>
      <div className="content-title">
      </div>
      <div className="content-body">
        <Loading isLoading={loading} />
        <table className="table table-striped table-list">
          <thead>
            <tr>
              <td>Nomor Surat</td>
              <td>Tgl Surat</td>
              <td>Kepada</td>
              <td>Hal</td>
            </tr>
          </thead>
          <tbody>
            {items &&
              items.map((list, i) => {
                return (
                  <tr key={i}>
                    <td>
                      <Link to={`/surat-keluar/terkirim/detail/${list.id}`}>
                        01/SK/MKS/III/2020
                      </Link>
                    </td>
                    <td>{moment(list.tanggal).format("LL")}</td>
                    <td>{list.kepada}</td>
                    <td>{list.hal}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      {meta && (
        <Paginate
          increment={() => increment(meta.current_page, getList)}
          meta={meta}
          decrement={() => decrement(meta.current_page, getList)}
          setLastPage={() => setLastPage(meta.last_page, getList)}
          setFirstPage={() => setFirstPage(meta.from, getList)}
        />
      )}
    </Fragment>
  );
};

export default Content;
