import React, { useState } from "react";
import Layout from "layout";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import { getRequest } from "config/axios";
import { checkError } from "redux/GeneralReducers/GeneralActions";

export function ProfilePage() {
  const { LoginReducers } = useSelector((state) => state);
  const [month, setMonth] = useState(new Date())
  const [isLoading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const {
    peg_nama,
    peg_nip,
    jabatan_nama,
  } = LoginReducers.userInfo.data_pegawai;
  const { user_name, photo_url } = LoginReducers.userInfo;

  const handleRekap = async (date1) => {
    setLoading(true)
    const newMonth = new Date(date1).getMonth()
    try {
      const req = await getRequest(`egs/rekap-mail/${newMonth}`);
      console.log("request", req)
      window.open(req.data.items, "_blank")
      setLoading(false)
    } catch (error) {
      setLoading(false)
      dispatch(checkError(error))
    }
  }

  return (
    <Layout subnavbar={{ type: "standart_header", title: "Personal Info" }}>
      <div className="content-title">
        <div>
          <i className="fa fa-bell"></i> Profile
        </div>
      </div>
      <div className="content-body container-fluid">
        <div className="info-board">
          <div className="row">
            <img
              src={photo_url}
              className="rounded mx-auto d-block"
              width="150px"
              alt="..."
            />
            <div className="col-md-12 m-t-20">
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="nama">Nama Pengguna</label>
                  <input
                    type="text"
                    id="nama"
                    value={user_name}
                    className="form-control"
                    disabled
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="kata_sandi">NIP</label>
                  <input
                    type="text"
                    id="kata_sandi"
                    value={peg_nip}
                    className="form-control"
                    disabled
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="nama_lengkap">Nama Lengkap</label>
                  <input
                    type="text"
                    id="nama_lengkap"
                    className="form-control"
                    disabled
                    value={peg_nama}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="jabatan">Jabatan</label>
                  <input
                    type="text"
                    className="form-control"
                    id="jabatan"
                    disabled
                    value={jabatan_nama}
                  />
                </div>
                <div className="form-group col-md-6 w-100">
                  <DatePicker
                    selected={month}
                    dateFormat="MM/yyyy"
                    className="form-control"
                    onChange={date => setMonth(date)}
                    showMonthYearPicker
                  />
                </div>
                <div className="form-group col-md-6">
                  <button type="button" onClick={() => handleRekap(month)} className="btn btn-primary btn-md">{isLoading ? <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i> : "Rekap Jadwal Surat"}</button>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
