import React from "react";
import "../../styles/footer/footer.scss";

function Footer() {
  return (
    <div className="footer">
      <div>
        Copyright <span dangerouslySetInnerHTML={{ __html: "&copy;" }} />
        {process.env.REACT_APP_YEAR} EGS Office Powered By{" "}
        {process.env.REACT_APP_COMPANY_NAME}
      </div>
    </div>
  );
}

export default Footer;
