import {
  SET_LOADING,
  SET_STATUS,
  UNAUTHORIZED,
} from "redux/rootRedux/TypeActions";

export const setLoading = (data) => ({
  type: SET_LOADING,
  payload: data,
});

export const set_status = (data) => ({
  type: SET_STATUS,
  payload: data,
});

export const Unauthorized = (data) => ({
  type: UNAUTHORIZED,
  payload: data,
});

export const setStatus = (data) => {
  return (dispatch) => {
    dispatch(set_status(data));
    if (data.status !== "401") {
      setTimeout(() => {
        dispatch(set_status({ status: null, msg: null }));
      }, 6000);
    }
  };
};

export const checkError = (error, tipe = "unknown") => {
  return (dispatch) => {
    console.log("Error", error);
    console.log("Error REQUEST", error.request);

    dispatch(setLoading(false));
    if (error.request) {
      if (error.request.status === 401) {
        dispatch(Unauthorized(true));
      } else if (error.response) {
        if (tipe === "detail-error") {
          dispatch(
            set_status({
              status: tipe,
              msg: `Error Message : ${error.response.data.diagnostics.message}`,
            })
          );
        } else {
          dispatch(
            setStatus({
              status: tipe,
              msg: `Terjadi Kesalahan ! Error Message : ${error.response.data.diagnostics.message}`,
            })
          );
        }
      } else {
        dispatch(
          set_status({
            status: "request-fail",
            msg: `Error Message: Check your connection !`,
          })
        );
      }
    } else {
      dispatch(
        setStatus({
          status: "fail",
          msg: "Something Wrong !",
        })
      );
    }
  };
};
