import React, { useEffect, useRef, useState } from 'react';
import Navmenu from './cards/navmenu';
import TabResult from './cards/tabs';
import ReCAPTCHA from "react-google-recaptcha";
import { getRequest } from 'config/axios';
import { useDispatch, useSelector } from 'react-redux';
import { checkError, setLoading } from 'redux/GeneralReducers/GeneralActions';
import { reset_value_surat_masuk } from 'redux/SuratReducers/BuatSuratMasuk/BuatSuratMasukAction';

const CariSuratPage = () => {
  const [isVerified, setIsVerified] = useState(false);
  const [data, setData] = useState(null)
  const [error, setError] = useState(false)
  const [notFound, setNotFound] = useState(false)
  const [cari, setCari] = useState(null)
  const [token, setToken] = useState(null)
  const chapchaRef = useRef(null);

  const dispatch = useDispatch()
  const { GeneralReducers: { loading }, BuatSuratMasukReducers } = useSelector(state => state)

  const onVerified = (token) => {
    if (token) {
      setIsVerified(!isVerified)
      setToken(token);
      setError(false)
    }
  }

  const onExpiredToken = () => {
    setError(false)
    setIsVerified(false)
    setToken(null)
    setNotFound(false)
  }

  const onTypeForm = (e, keys) => {
    setCari({ ...cari, [keys]: e.target.value })
  }

  const postSearch = async (e) => {
    e.preventDefault()
    dispatch(setLoading(true))
    setNotFound(false)
    // console.log("post message", cari?.nomor_surat, cari?.email)
    if (token && cari?.nomor_surat && cari?.email) {
      setError(false)
      try {
        const fetch = await getRequest(`egs/public/tracking-mail?resi=${cari.nomor_surat}&email=${cari.email}`)
        setData(fetch.data.items);
        console.log("fetch cari surat", fetch)
        fetch && dispatch(setLoading(false))
        return;
      } catch (error) {
        setNotFound(true)
        setData(null)
        dispatch(checkError(error))
        return;
      }
    }
    setError(true)
    setIsVerified(false)
    dispatch(setLoading(false))
  }
  console.log("buat surat masuk", BuatSuratMasukReducers)
  return (
    <div className="container-login">
      <Navmenu />
      <div className="left-content display-block">
        <div className="form-wrapper">
          <div className="title">
            <span>Selamat Datang di EGS - AMS</span>
            <span>Cari Surat</span>
          </div>
          {notFound &&
            <div className="alert alert-sm alert-warning">Surat tidak di temukan !</div>
          }
          {error &&
            <div className="alert alert-sm alert-danger">Lengkapi form dan captcha !</div>
          }
          {/* <form> */}
          <div className="input-wrapper">
            <label htmlFor="username">Masukkan nomor surat</label>
            <i className="fa fa-envelope"></i>
            <input
              type="text"
              autoComplete="off"
              id="username"
              defaultValue=""
              required
              aria-describedby="nomorSurat"
              placeholder="nomor surat"
              onChange={(e) => onTypeForm(e, 'nomor_surat')}
            />
          </div>
          <div className="input-wrapper">
            <label htmlFor="username">Email</label>
            <i className="fa fa-envelope"></i>
            <input
              type="text"
              autoComplete="off"
              id="email"
              defaultValue=""
              required
              aria-describedby="emailHelp"
              placeholder="email"
              onChange={(e) => onTypeForm(e, 'email')}
            />
          </div>
          <div className="capcha-wrapper">
            <ReCAPTCHA
              sitekey="6LeJFPwaAAAAAJYFuHUF1M79octgZauY0OAqrcyX"
              onChange={onVerified}
              ref={chapchaRef}
              onExpired={onExpiredToken}
            />
          </div>
          <div className="btn-wrapper">
            {loading ?
              <button disabled={true}>
                sedang melacak...
              </button>
              :
              <button type="button" onClick={postSearch}>
                Lacak
              </button>
            }
          </div>
          {/* </form> */}
        </div>
      </div>
      <div className="right-content">
        <div className="result-wrapper">
          <TabResult details={data} />
        </div>
      </div>
      <footer>
        <img src={process.env.PUBLIC_URL + '/assets/img/footer-logo.png'} className="footer-logo" alt="footer_logo" />
        <div className="text-footer">
          <span>PALLAKA STUDIO</span>
          <span> &copy; 2021 Pallaka Studio</span>
        </div>
        <div className="text-support">
          <div>Di dukung oleh</div>
          <img src={process.env.PUBLIC_URL + '/assets/img/logo-bsre.svg'} className="footer-foo" alt="footer_logo" />
        </div>
      </footer>
    </div>
  )
}

export default CariSuratPage
