import {
  SET_LOADING,
  SET_STATUS,
  UNAUTHORIZED,
} from "redux/rootRedux/TypeActions";

const GlobalState = {
  loading: false,
  status: null,
  unauthorized: false,
  msg: null,
};

export const GeneralReducers = (state = GlobalState, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SET_STATUS:
      return {
        ...state,
        status: action.payload.status,
        msg: action.payload.msg,
      };
    case UNAUTHORIZED:
      return {
        ...state,
        unauthorized: action.payload,
      };
    default:
      return state;
  }
};
