import Content from "./cards/content";
import React from "react";
import Layout from "layout";
import ContentToPrint from "./cards/contentToPrint";

export function BankSuratKeluar() {
  return (
    <Layout subnavbar={{
      print_btn: true,
      title: "Bank Surat Keluar",
      keysTab: "listBankSuratKeluar",
      path: "/bank-surat/list-surat/",
      ComponentToPrint: ContentToPrint,
    }}>
      <Content />
    </Layout>
  );
}
