import React, { Fragment, useEffect } from "react";
import KepalaSurat from "./kepalaSurat";
import Pengirim from "./pengirim";
import Penerima from "./penerima";
import Pemeriksa from "./pemeriksa";
import Referensi from "./referensi";
import IsiSurat from "./isiSurat";
import Lampiran from "./lampiran";
import InfoJadwal from "./infoJadwal";
import {
  editSuratKeluar,
  reset_value,
} from "redux/SuratReducers/BuatSurat/BuatSuratActions";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const Content = (props) => {
  const dispatch = useDispatch();
  const { GeneralReducers, BuatSuratReducers } = useSelector(state => state)

  const updateContent = () => {
    if (props.match.params.id) {
      dispatch(editSuratKeluar(props.match.params.id));
    } else {
      dispatch(reset_value());
    }
  };

  console.log("----", BuatSuratReducers)

  useEffect(updateContent, [props.match.params.id]);

  return (
    <Fragment>
      <div className="content-title">
        <div className="content-filter">
          {props.match.params.id ? "EDIT SURAT KELUAR" : "BUAT SURAT KELUAR"}
        </div>
      </div>
      {GeneralReducers.loading && (
        <div className="loading-submit">
          <i className="fa fa-spinner fa-pulse fa-fw fa-2x"></i>
        </div>
      )}
      {GeneralReducers.status === "alert-surat-keluar" && (
        <div className="alert alert-danger">{GeneralReducers.msg}</div>
      )}
      {/* {GeneralReducers.status === "request-fail" && (
        <div className="alert alert-danger">{GeneralReducers.msg}</div>
      )} */}
      <div className="content-body m-0-10">
        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
          <li className="nav-item" role="presentation">
            <a
              className="nav-link my-btn-tabs active"
              id="kepala-surat-tab"
              data-toggle="pill"
              href="#kepala-surat"
              role="tab"
              aria-controls="kepala-surat"
              aria-selected="true"
            >
              Kepala Surat
            </a>
          </li>
          <li className="nav-item" role="presentation">
            <a
              className="nav-link my-btn-tabs"
              id="pengirim-tab"
              data-toggle="pill"
              href="#pengirim"
              role="tab"
              aria-controls="pengirim"
              aria-selected="false"
            >
              Pengirim
            </a>
          </li>
          <li className="nav-item" role="presentation">
            <a
              className="nav-link my-btn-tabs"
              id="penerima-tab"
              data-toggle="pill"
              href="#penerima"
              role="tab"
              aria-controls="penerima"
              aria-selected="false"
            >
              Penerima
            </a>
          </li>
          <li className="nav-item" role="presentation">
            <a
              className="nav-link my-btn-tabs"
              id="pills-pemeriksa-tab"
              data-toggle="pill"
              href="#pemeriksa"
              role="tab"
              aria-controls="pemeriksa"
              aria-selected="false"
            >
              Pemeriksa
            </a>
          </li>
          <li className="nav-item" role="presentation">
            <a
              className="nav-link my-btn-tabs"
              id="pills-referensi-tab"
              data-toggle="pill"
              href="#referensi"
              role="tab"
              aria-controls="referensi"
              aria-selected="false"
            >
              Referensi
            </a>
          </li>
          <li className="nav-item" role="presentation">
            <a
              className="nav-link my-btn-tabs"
              id="pills-isi-surat-tab"
              data-toggle="pill"
              href="#isi-surat"
              role="tab"
              aria-controls="isi-surat"
              aria-selected="false"
            >
              Isi Surat
            </a>
          </li>
          <li className="nav-item" role="presentation">
            <a
              className="nav-link my-btn-tabs"
              id="pills-lampiran-tab"
              data-toggle="pill"
              href="#lampiran"
              role="tab"
              aria-controls="lampiran"
              aria-selected="false"
            >
              Lampiran
            </a>
          </li>
          <li className="nav-item" role="presentation">
            <a
              className="nav-link my-btn-tabs"
              id="pills-info-jadwal-tab"
              data-toggle="pill"
              href="#info-jadwal"
              role="tab"
              aria-controls="info-jadwal"
              aria-selected="false"
            >
              Info Jadwal
            </a>
          </li>
        </ul>
        <div className="tab-content" id="pills-tabContent">
          <div className="mt-5"></div>
          <div
            className="tab-pane fade show active"
            id="kepala-surat"
            role="tabpanel"
            aria-labelledby="kepala-surat-tab"
          >
            <KepalaSurat />
          </div>
          <div
            className="tab-pane fade"
            id="pengirim"
            role="tabpanel"
            aria-labelledby="pengirim-tab"
          >
            <Pengirim />
          </div>
          <div
            className="tab-pane fade"
            id="penerima"
            role="tabpanel"
            aria-labelledby="penerima-tab"
          >
            <Penerima />
          </div>
          <div
            className="tab-pane fade"
            id="pemeriksa"
            role="tabpanel"
            aria-labelledby="pills-pemeriksa-tab"
          >
            <Pemeriksa />
          </div>
          <div
            className="tab-pane fade"
            id="referensi"
            role="tabpanel"
            aria-labelledby="pills-referensi-tab"
          >
            <Referensi />
          </div>
          <div
            className="tab-pane fade"
            id="isi-surat"
            role="tabpanel"
            aria-labelledby="pills-isi-surat-tab"
          >
            <IsiSurat />
          </div>
          <div
            className="tab-pane fade"
            id="lampiran"
            role="tabpanel"
            aria-labelledby="pills-lampiran-tab"
          >
            <Lampiran />
          </div>
          <div
            className="tab-pane fade"
            id="info-jadwal"
            role="tabpanel"
            aria-labelledby="pills-info-jadwal-tab"
          >
            <InfoJadwal />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default withRouter(Content);
