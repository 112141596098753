import React, { Fragment } from "react";
import CardInfo from "./cardInfo";
import { getRequest } from "config/axios";
import { useDispatch } from "react-redux";
import { checkError } from "redux/GeneralReducers/GeneralActions";
import { useEffect } from "react";
import { useState } from "react";
import { setBadge } from "redux/Notifications/NotificationActions";
import moment from "moment";

const Content = () => {
  const dispatch = useDispatch();
  const [data, setdata] = useState({});
  const [viewMore, setViewMore] = useState(5);
  const [activity, setActivity] = useState([]);
  const getCount = () => {
    getRequest(`/egs/dashboard/count`)
      .then((res) => {
        setdata(res.data.items);
        let surat_masuk =
          res.data.items.count_internal.havent_read +
          res.data.items.count_sekretariat.havent_read;
        let total_notif =
          res.data.items.count_internal.havent_read +
          res.data.items.count_sekretariat.havent_read +
          res.data.items.count_persetujuan.havent_read +
          res.data.items.count_draft.havent_read;

        dispatch(
          setBadge({
            surat_masuk,
            persetujuan: res.data.items.count_persetujuan.havent_read,
            konsep: res.data.items.count_draft.havent_read,
            total_notif,
          })
        );
      })
      .catch((err) => dispatch(checkError(err)));
  };
  const { count_sekretariat, count_internal, count_disposisi, count_teruskan, count_persetujuan } = data;

  const getActivity = () => {
    getRequest(`egs/activity`)
      .then((res) => {
        setActivity(res.data.items);
      })
      .catch((err) => dispatch(checkError(err)));
  };

  const dateObj = () => {
    return activity.map((item) => moment(item.created_at).format("MM/DD/YYYY"));
  };

  const dateActivity = [...new Set(dateObj())];

  useEffect(getCount, []);
  useEffect(getActivity, []);
  console.log("respon dashboard", data)

  return (
    <Fragment>
      <div className="content-title">DASHBOARD</div>

      <div className="content-body container-fluid">
        <div className="row">
          {count_sekretariat && (
            <CardInfo
              title="Surat Masuk (Sekretariat)"
              icon="mail"
              data={[
                { label: "Total", value: count_sekretariat.total },
                { label: "Dibaca", value: count_sekretariat.read },
                { label: "Belum", value: count_sekretariat.havent_read },
              ]}
            />
          )}
          {count_internal && (
            <CardInfo
              title="Inbox Surat Masuk"
              icon="mail"
              data={[
                { label: "Total", value: count_internal.total },
                { label: "Dibaca", value: count_internal.read },
                { label: "Belum", value: count_internal.havent_read },
              ]}
            />
          )}
        </div>
        <div className="row">
          {count_teruskan && (
            <CardInfo
              title="Surat Diteruskan"
              icon="surat_diteruskan"
              data={[
                { label: "Jumlah", value: count_teruskan.total },
              ]}
            />
          )}
          {count_disposisi && (
            <CardInfo
              title="Surat Disposisi"
              icon="surat_disposisi"
              data={[
                { label: "Jumlah", value: count_disposisi.total },
              ]}
            />
          )}
          {count_persetujuan && (
            <CardInfo
              title="Surat Diperiksa"
              icon="surat_diperiksa"
              data={[
                { label: "Jumlah", value: count_persetujuan.total },
              ]}
            />
          )}
        </div>
        <div className="mt-3 mb-3">Aktvitas</div>
        <div className="info-board">
          <ul className="item-info-board">
            {dateActivity.length
              ? dateActivity.slice(0, 3).map((item, i) => {
                return (
                  <div key={i}>
                    <li className="title-info-board">
                      <i className="fa fa-calendar"> </i>{" "}
                      {moment(item).calendar(null, {
                        sameDay: "[Hari ini]",
                        lastDay: "LL",
                      })}
                    </li>
                    {activity
                      .filter(
                        (list) =>
                          moment(item).format("L") ===
                          moment(list.created_at).format("L")
                      )
                      .slice(0, viewMore)
                      .map((list, i) => {
                        return (
                          <li key={i}>
                            <ul className="sub-item-info-board">
                              <li>
                                <div>
                                  <span className="badge badge-primary">
                                    {list.title}
                                  </span>
                                </div>
                                <div>{list.body}</div>
                                <div className="classik-date">
                                  {moment(item).format("LL") ===
                                    moment(new Date()).format("LL")
                                    ? moment(list.created_at).fromNow()
                                    : moment(list.created_at).format("HH:MM")}
                                </div>
                              </li>
                            </ul>
                          </li>
                        );
                      })}
                    <a href="# " onClick={() => setViewMore(viewMore + 5)}>
                      View More
                    </a>
                  </div>
                );
              })
              : "Tidak ada aktivitas."}
          </ul>
        </div>
      </div>
    </Fragment>
  );
};

export default Content;
