import React, { Fragment } from "react";
import Paginate from "components/footers/paginate";
import { useDispatch, useSelector } from "react-redux";
import {
  decrement,
  increment,
  setFirstPage,
  setLastPage,
} from "utils/pagination";
import { Link } from "react-router-dom";
import { getListMethod } from "redux/SuratReducers/ListSuratMasuk/ListSuratAction";
import Loading from "components/body/Loading";
import { useEffect } from "react";
import moment from "moment";

const Content = () => {
  const dispatch = useDispatch();
  const {
    GeneralReducers,
    ListSuratReducers,
    NotificationReducers,
  } = useSelector((state) => state);

  const { konsep } = NotificationReducers;
  const { loading } = GeneralReducers;
  const { items, meta } = ListSuratReducers.listKonsep;
  const getKonsep = (current_page = 1) => {
    dispatch(getListMethod(`konsep/mail`, "listKonsep", current_page));
  };
  useEffect(getKonsep, [konsep]);
  return (
    <Fragment>
      <div className="content-title">
      </div>
      <div className="content-body">
        <Loading isLoading={loading} />
        <table className="table table-striped table-list">
          <thead>
            <tr>
              <td>Tanggal Dibuat</td>
              <td>Hal</td>
              <td>Status</td>
            </tr>
          </thead>
          <tbody>
            {items &&
              items.map((list, i) => {
                return (
                  <tr key={i}>
                    <td>
                      <Link to={`/surat-keluar/konsep/detail/${list.id}`}>
                        {moment(list.tanggal).format("LL")}
                      </Link>
                    </td>
                    <td>{list.hal}</td>
                    <td>
                      <span className="badge badge-success">{list.status}</span>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      {meta && (
        <Paginate
          meta={meta}
          increment={() => increment(meta.current_page, getKonsep)}
          decrement={() => decrement(meta.current_page, getKonsep)}
          setLastPage={() => setLastPage(meta.last_page, getKonsep)}
          setFirstPage={() => setFirstPage(meta.from, getKonsep)}
        />
      )}
    </Fragment>
  );
};

export default Content;
