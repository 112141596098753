function checkTandaTangan(tanda_tangan, { user_eksternal = [], user = [], plt = [] }) {
  return user_eksternal.length + user.length + plt.length;
  // return tanda_tangan ?
  //   user_eksternal.length :
  //   user.length + plt.length;
}

function isValid(dataSurat) {
  const alert_value = ["", " ", "-", 0, null, undefined];
  for (let keys in dataSurat) {
    if (alert_value.includes(dataSurat[keys])) {
      return { error: true, msg: `${keys} kosong !` };
    }
  }
  return { error: false, msg: "" };
}

export const validateSuratKeluar = (data) => {
  const {
    tanda_tangan,
    kepala_surat: {
      perihal,
      lampiran: { value },
      sifat: { pengamanan, penyampaian },
    },
    pengirim: {
      user: { peg_nip },
    },
    penerima,
    pemeriksa: { user },
  } = data;

  let penerimaCheck = checkTandaTangan(tanda_tangan, penerima);
  // console.log("penerima", penerimaCheck)
  const dataSurat = {
    "tanda tangan": tanda_tangan,
    perihal,
    lampiran: value,
    pengamanan,
    penyampaian,
    "Pengirim": peg_nip,
    penerima: penerimaCheck,
    pemeriksa: user.length
  };
  return isValid(dataSurat)

};

export const validateSuratMasuk = (data) => {
  const {
    no_surat,
    tahun_agenda,
    dari,
    kepala_surat: {
      perihal,
      lampiran: { value, type },
      sifat: { pengamanan, penyampaian },
    },
    penerima,
    data_pengirim: { nama, email, nomor_wa }
  } = data;
  let penerimaCheck = checkTandaTangan(false, penerima);
  let dataSurat = {
    "nomor surat": no_surat,
    "tahun agenda": tahun_agenda,
    dari,
    perihal,
    "lampiran": value,
    "tipe lampiran": type,
    pengamanan,
    penyampaian,
    penerima: penerimaCheck,
    "nama pengirim": nama,
    email,
    "nomor whatsapp": nomor_wa
  };

  return isValid(dataSurat)
};
