import React, { Fragment } from "react";
// import Paginate from "components/footers/paginate";

import { withRouter } from "react-router-dom";
import moment from "moment";

const RiwayatTindakan = (props) => {

  const listTindakan = props.details?.history;
  return (
    <Fragment>
      <div className="form-group">
        <hr />
      </div>
      {/* <div className="row">
        <div className="col-md-6 pull-left">
          <div className="input-group mb-3"></div>
        </div>
        <div className="col-md-6 pull-right">
          <Paginate />
        </div>
      </div> */}
      <div className="wrapper-table remove-scroll">
        <div className="row container">
          {listTindakan?.items?.user.map((list, i) => {
              return (
                <div key={i} className="col-md-12 item-content-table font-12">
                  <div className="content-table-card">
                    <img
                      src={list.photo_url}
                      alt="images"
                      width="60"
                      className="content-table-images"
                    />
                    <div className="content-table-text">
                      <div className="content-table-text-row">
                        <span className="label-table">Dari</span>
                        <span>: {list.action.dari && list.action.dari}</span>
                      </div>
                      <div className="content-table-text-row">
                        <span className="label-table">Kepada</span>
                        <span>: {list.data_pegawai.peg_nama}</span>
                      </div>
                      {list.action.is_disposisi && (
                        <div className="content-table-text-row">
                          <span className="label-table">Petunjuk</span>
                          <span>
                            :{" "}
                            {list.action.text.map((list, i) => {
                              return (
                                <span key={i} className="inline-display">
                                  {list}
                                </span>
                              );
                            })}
                          </span>
                        </div>
                      )}

                      <div className="content-table-text-row">
                        <span className="label-table">Tentang</span>
                        <span>: {list.action.keterangan}</span>
                      </div>
                    </div>
                  </div>
                  <div className="content-table-card mr-5">
                    {moment(list.action.created_at).format("LL, HH:MM:ss")}
                  </div>
                </div>
              );
            })}
          {/* <div className="col-md-12 line-horizontal"></div> */}
        </div>
      </div>
    </Fragment>
  );
};

export default withRouter(RiwayatTindakan);
