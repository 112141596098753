// import Paginate from "components/footers/paginate";
import { getRequest } from "config/axios";
import React, { Fragment } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { checkError, setLoading } from "redux/GeneralReducers/GeneralActions";

const Referensi = (props) => {
  const [lists, setList] = useState(null);
  const dispatch = useDispatch();

  const getList = () => {
    dispatch(setLoading(true));
    getRequest(`egs/referensi/mail/${props.match.params.id}?page=1&per_page=15`)
      .then((res) => {
        dispatch(setLoading(false));
        setList(res.data);
      }).catch((err) => dispatch(checkError(err, "detail-error")));
  };

  useEffect(getList, []);

  return (
    <Fragment>
      <div className="form-group"></div>
      <table className="table table-striped">
        <thead>
          <tr>
            <td>Nomor Surat</td>
            <td>Perihal</td>
          </tr>
        </thead>
        <tbody>
          {lists &&
            lists.items.map((list, i) => {
              return (
                <tr key={i}>
                  <td>{list.no_surat}</td>
                  <td>{list.hal}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
      {/* <Paginate /> */}
    </Fragment>
  );
};

export default withRouter(Referensi);
