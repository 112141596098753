import React, { Fragment } from 'react';

class ContentToPrint extends React.Component {
  // let satuan_kerja_id =
  //   LoginReducers.userInfo.data_pegawai.satuan_kerja.satuan_kerja_id || 0;
  // const getList = (current_page = 1) => {
  //   dispatch(
  //     getListMethod(
  //       `master/bank-mail-keluar`,
  //       'listBankSuratKeluar',
  //       current_page,
  //       satuan_kerja_id
  //     )
  //   );
  // };

  // useEffect(getList, []);
  render() {
    // const { items, meta } = this.props
    // console.log("objecthis.t", this.props)
    return (
      <Fragment>
        <div className='content-body'>

          <table className='table table-striped table-list' border="1">
            <thead>
              <tr>
                <td width='130px'>Tanggal Di Buat</td>
                <td>Pengirim</td>
                <td>Hal</td>
                <td>Status</td>
                <td>Jenis Surat</td>
              </tr>
            </thead>
            <tbody>
              {/* {items &&
                items.map((list, i) => {
                  return (
                    <tr key={i}>
                      <td>
                        <Link to={`/bank-surat/list/detail/${list.id}`}>
                          {moment(list.tanggal).format('LL')}
                        </Link>
                      </td>
                      <td>
                        <b>{list.pengirim.peg_nama}</b>
                        <p>{list.pengirim.peg_jabatan}</p>
                        {list.dari}
                      </td>
                      <td>{list.hal}</td>
                      <td>
                        <span className='badge badge-primary'>
                          {list.status.text}
                        </span>
                      </td>
                      <td>-</td>
                    </tr>
                  );
                })} */}
            </tbody>
          </table>
        </div>
        {/* {meta && (
          <Paginate
            meta={meta}
            increment={() => increment(meta.current_page, null)}
            decrement={() => decrement(meta.current_page, null)}
            setLastPage={() => setLastPage(meta.last_page, null)}
            setFirstPage={() => setFirstPage(meta.from, null)}
          />
        )} */}
      </Fragment>
    );
  }
};

export default ContentToPrint;