import { postRequest } from "config/axios";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { checkError, setLoading } from "redux/GeneralReducers/GeneralActions";

export function EditableInput({ value, editable, getDetails, id }) {
  const [isEditableNomor, setIsEditableNomor] = useState(false);
  const [data, setData] = useState({});
  const dispatch = useDispatch();
  const {
    LoginReducers: {
      userInfo: { user_level },
    },
  } = useSelector((state) => state);

  const editNomorSurat = () => {
    setIsEditableNomor(!isEditableNomor);
  };
  const saveValue = () => {
    setIsEditableNomor(!isEditableNomor);
    dispatch(setLoading(true));
    postRequest(`egs/master/update-mail/${id}`, data)
      .then((res) => {
        setIsEditableNomor(false);
        getDetails();
      })
      .catch((err) => dispatch(checkError(err)));
  };
  const newDate = moment(data.tanggal_surat).toDate();
  const changeValue = (e, name) => {
    console.log(e);
    setData({
      ...data,
      [name]: e,
    });
  };

  useEffect(() => {
    setData(value);
  }, [value]);
  return (
    <Fragment>
      {editable === "nomor_surat" ? (
        <input
          type="search"
          disabled={!isEditableNomor}
          defaultValue={data[editable]}
          onChange={(e) => {
            changeValue(e.target.value, "nomor_surat");
          }}
        />
      ) : (
        <DatePicker
          onChange={(val) => changeValue(val, "tanggal_surat")}
          selected={newDate}
          disabled={!isEditableNomor}
          dateFormat="dd MMMM yyyy"
        />
      )}
      {user_level === 8 && (
        <span>
          {isEditableNomor ? (
            <i className="fa fa-save" onClick={saveValue}></i>
          ) : (
            <i className="fa fa-pencil" onClick={editNomorSurat}></i>
          )}
        </span>
      )}
    </Fragment>
  );
}
