import React, { Fragment, useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import DataSurat from './dataSurat'
import InfoJadwal from './infoJadwal'
import LampiranSurat from './lampiran'
import RiwayatTindakan from './riwayatTindakan'

const TabResult = ({ details }) => {
  const [keysTab, setkeysTab] = useState("data-surat")
  return (
    <Fragment>
      <Tabs
        id="controlled-tab-example"
        activeKey={keysTab}
        className="tabs-header"
        // tabs-link
        onSelect={(k) => setkeysTab(k)}
      >
        <Tab
          eventKey="data-surat"
          title="Data Surat"
          className="tabs-link2"
        >

          <DataSurat details={details} />

        </Tab>
        <Tab
          eventKey="lampiran"
          title="lampiran"
          className="tabs-link"
        >
          <LampiranSurat details={details} />
        </Tab>
        <Tab
          eventKey="riwayat-tindakan"
          title="Riwayat Tindakan"
          className="tabs-link"
        >
          <RiwayatTindakan details={details} />
        </Tab>
        <Tab
          eventKey="info-surat"
          title="Info Surat"
          className="tabs-link"
        >
          <InfoJadwal data_jadwal={details?.info_jadwal} />
        </Tab>
      </Tabs>
    </Fragment>
  )
}

export default TabResult
