import React, { Fragment } from "react";
import Navbar from "components/header/navbar";
import Sidebar from "components/sidebar/sidebar";
import SubNavbarWrapper from "components/header/subNavbarWrapper";
import Footer from "components/footers/footer";
import ContentWrapper from "components/body/contentWrapper";
import { ContentViewSurat } from "./content";
import SubNavbar from "./subNavbar";

function DetailSurat() {
  return (
    <Fragment>
      <div className="background-dark"></div>
      <Navbar />
      <Sidebar />
      <SubNavbarWrapper>
        <SubNavbar />
      </SubNavbarWrapper>
      <ContentWrapper>
        <ContentViewSurat />
      </ContentWrapper>
      <Footer />
    </Fragment>
  );
}

export default DetailSurat;
