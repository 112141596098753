import React, { Fragment, useState, useEffect, useCallback } from "react";
import { getRequest } from "config/axios";
import { Modal, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { checkError, setLoading } from "redux/GeneralReducers/GeneralActions";
import { set_value } from "redux/SuratReducers/BuatSurat/BuatSuratActions";
import moment from "moment";
import Loading from "components/body/Loading";
import { debounce } from "lodash";
import PrevBtn from "components/body/buttons/prevBtn";
import NextBtn from "components/body/buttons/nextBtn";

const Referensi = () => {
  const [lgShow, setLgShow] = useState(false);
  const { BuatSuratReducers } = useSelector((state) => state);
  const { referensi } = BuatSuratReducers;

  return (
    <Fragment>
      <Modals lgShow={lgShow} setLgShow={setLgShow} />
      <div className="form-group">
        <label htmlFor="pemeriksa">
          <a href="# " onClick={() => setLgShow(!lgShow)}>
            Referensi *
          </a>
        </label>
        <div className="box-input">
          {referensi.dataLengkap.length > 0 &&
            referensi.dataLengkap.map((view, i) => {
              return <span key={i}>{view.hal}</span>;
            })}
        </div>
      </div>
      <div className="col-md-12 flex-space-between">
        <PrevBtn to="pemeriksa" />
        <NextBtn to="isi-surat" />
      </div>
    </Fragment>
  );
};

export default Referensi;

const Modals = (props) => {
  // moment.locale("id");

  const dispatch = useDispatch();
  const { GeneralReducers } = useSelector((state) => state);
  // const [q, setQ] = useState("");
  // const [DetailShow, setDetailShow] = useState(false);
  const [selected, setSelected] = useState([]);

  const [listReferensi, setListReferensi] = useState(null);
  const today = moment(new Date()).format("YYYY-MM-DD");
  const getReferensi = (current_page = 1, q = "") => {
    dispatch(setLoading(true));
    getRequest(
      `egs/mail?start_date=2020-10-01&end_date=${today}&q=${q}&page=${current_page}&per_page=10`
    )
      .then((res) => {
        // console.log("objecsssssssasssssst", res);
        setListReferensi(res.data);
        dispatch(setLoading(false));
      })
      .catch((err) => dispatch(checkError(err)));
  };

  const debounceSave = useCallback(
    debounce((current_page, q) => getReferensi(current_page, q), 1000),
    []
  );

  const addSelected = (data) => {
    let lists = selected.filter((list) => list.id !== data.id);
    setSelected([...lists, data]);
  };

  const delSelected = (data) => {
    let lists = selected.filter((list) => list.id !== data.id);
    setSelected(lists);
  };

  const setValue = (data) => {
    const _id = selected.map((list) => list.id);
    dispatch(
      set_value({
        value: { dataLengkap: selected, id: _id },
        name: "referensi",
      })
    );
    props.setLgShow(false);
  };

  useEffect(getReferensi, []);

  return (
    <Fragment>
      <Modal
        size="lg"
        show={props.lgShow}
        onHide={() => props.setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header className="header-modal" closeButton>
          <Modal.Title
            className="title-modal"
            id="example-modal-sizes-title-lg"
          >
            REFERENSI
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row reset-margin-row">
            <div className="col-md-8 content-modal">
              <div className="title-tab-second bg-white no-border-bottom text-right">
                <span className="mr-3">
                  {listReferensi && listReferensi.meta.current_page} dari{" "}
                  {listReferensi && listReferensi.meta.last_page}
                </span>
                <i
                  className="fa fa-chevron-left mr-3 pointer-mouse"
                  onClick={() => {
                    listReferensi.meta.current_page > 1 &&
                      getReferensi(listReferensi.meta.current_page - 1);
                  }}
                ></i>
                <i
                  className="fa fa-chevron-right pointer-mouse"
                  onClick={() => {
                    listReferensi.meta.current_page <
                      listReferensi.meta.last_page &&
                      getReferensi(listReferensi.meta.current_page + 1);
                  }}
                ></i>
              </div>
              <Loading isLoading={GeneralReducers.loading} />
              <Table striped bordered hover className="table-modal-content">
                <thead>
                  <tr>
                    <th>No. Surat</th>
                    <th>Perihal</th>
                  </tr>
                </thead>
                <tbody>
                  {listReferensi &&
                    listReferensi.items.map((view, i) => {
                      return (
                        <tr
                          className="pointer-mouse"
                          key={i}
                          onClick={() => addSelected(view)}
                        >
                          <td>{view.no_surat}</td>
                          <td>{view.hal}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </div>
            <div className="col-md-4 content-modal">
              <div className="title-tab-second">Pilihan</div>
              {selected &&
                selected.map((list, i) => {
                  return (
                    <ul key={i} className="parent-list">
                      <li className="child-list">
                        {list.no_surat}
                        <i
                          className="fa fa-trash pointer-mouse"
                          onClick={() => delSelected(list)}
                        ></i>
                      </li>
                    </ul>
                  );
                })}
            </div>
          </div>
          <hr />
          <div className="row reset-margin-row ">
            <div className="col-md-4 mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Kata Kunci"
                onChange={(e) => debounceSave(1, e.target.value)}
              />
            </div>
            <div className="col-md-5 mb-3"></div>
            <div className="col-md-3 tabs-header-modal space-table mb-3">
              <button
                onClick={setValue}
                className="btn col-md-12 btn-primary mr-2"
              >
                Oke
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};
