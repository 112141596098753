import React from "react";
import { Link, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

import "../../styles/content/login.scss";

const Register = () => {
  const { LoginReducers } = useSelector((state) => state);

  if (LoginReducers.isLogin) {
    return <Redirect to="dashboard" />;
  }
  return (
    <div className="container-login">
      <img src={process.env.PUBLIC_URL + '/assets/img/logo.svg'} alt="Logo" />
      <div className="form-wrapper">
        <div className="form-title">
          <span>Register</span>
        </div>
        <div className="form">
          <div class="form-group">
            <label for="exampleInputEmail1">Nama Pengguna</label>
            <input
              type="text"
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Nama Pengguna"
            />
            <small id="emailHelp" class="form-text text-muted"></small>
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="inputEmail4">Kata Sandi</label>
              <input
                type="password"
                class="form-control"
                id="inputEmail4"
                placeholder="Kata Sandi"
              />
            </div>
            <div class="form-group col-md-6">
              <label for="inputPassword4">Ulangi Kata Sandi</label>
              <input
                type="password"
                class="form-control"
                id="inputPassword4"
                placeholder="Ulangi Kata Sandi"
              />
            </div>
          </div>
          <div class="form-group">
            <label for="exampleInputEmail1">Nama Lengkap</label>
            <input
              type="text"
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Nama Lengkap"
            />
            <small id="emailHelp" class="form-text text-muted"></small>
          </div>
          <div className="text-footer-form-login">
            <div class="form-group form-check">
              <input
                type="checkbox"
                class="form-check-input"
                id="exampleCheck1"
              />
              <label class="form-check-label" for="exampleCheck1">
                Saya Setuju Dengan Syarat dan Ketentuan
              </label>
            </div>
          </div>
          <Link to="/dashboard" class="btn col-md-12 btn-primary">
            Submit
          </Link>
          <div className="footer-last">
            Sudah Terdaftar ? <Link to="/">Masuk</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
