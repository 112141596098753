import React, { Fragment } from 'react'
import moment from 'moment'

const DataSurat = ({ details }) => {
  // const {ta}
  return (
    <Fragment>
      <div className="form-group">
        <hr />
      </div>
      <div className="wrapper-table">
        <table className="table table-striped font-12">
          <tbody>
            <tr>
              <td>Tanggal Surat</td>
              <td className="editable-form">
                :
                {moment(details?.created_at).format('LL')}
              </td>
            </tr>
            <tr>
              <td>Nomor Surat Keluar</td>
              <td className="editable-form">
                :
                {details?.no_surat}
              </td>
            </tr>
            <tr>
              <td>Dari </td>
              <td>
                : {details?.dari}
              </td>
            </tr>

            <tr>
              <td>Kepada </td>
              <td>
                :
                <ol style={{ marginTop: "-20px", paddingLeft: '5px' }}>
                  <span className="tootletip-hover">
                    {details?.penerima.selected_kepala_login.peg_nama} -
                    {details?.penerima.selected_kepala_login.jabatan_nama}
                    <span className="tootletip">
                      {details?.penerima.selected_kepala_login.peg_nip}
                    </span>
                  </span>
                </ol>
              </td>
            </tr>
            <tr>
              <td>Tembusan </td>
              <td>
                :
                <ol style={{ marginTop: "-23px" }}>
                  {details?.tembusan.user.map((list, i) => (
                    <li key={i} className="tootletip-hover">
                      {list.data_pegawai.peg_nama}-{" "}
                      {list.data_pegawai.jabatan_nama}
                      <span className="tootletip">
                        {list.data_pegawai.peg_nip}
                      </span>
                      <br />{" "}
                    </li>
                  ))}
                  {details?.tembusan.plt.map((list, i) => (
                    <li key={i} className="tootletip-hover">
                      {list.data_pegawai.peg_nama} -{" "}
                      {list.data_pegawai.jabatan_nama}
                      <span className="tootletip">
                        {list.data_pegawai.peg_nip}
                      </span>
                      <br />{" "}
                    </li>
                  ))}
                </ol>
              </td>
            </tr>
            <tr>
              <td>Hal </td>
              <td>: {details?.kepala_surat.perihal}</td>
            </tr>
            <tr>
              <td>Kode Klasifikasi </td>
              <td>
                :
                {details &&
                  ` ${details.kepala_surat.klasifikasi
                    ? details.kepala_surat.klasifikasi.kode
                    : "-"
                  } / ${details.kepala_surat.klasifikasi
                    ? details.kepala_surat.klasifikasi.nama
                    : "-"
                  }`}
              </td>
            </tr>
            <tr>
              <td>Sifat Penyampaian</td>
              <td>: {details?.kepala_surat.sifat.penyampaian}</td>
            </tr>
            <tr>
              <td>Sifat Pengamanan</td>
              <td>: {details?.kepala_surat.sifat.pengamanan}</td>
            </tr>
            <tr>
              <td>Keterangan </td>
              <td>: - </td>
            </tr>
            <tr>
              <td>Di Entry Oleh </td>
              <td>: {details?.user.user_name}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </Fragment>
  )
}

export default DataSurat
