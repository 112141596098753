import React, { useState, Fragment } from "react";
import Navbar from "components/header/navbar";
import Sidebar from "components/sidebar/sidebar";
import SubNavbarWrapper from "components/header/subNavbarWrapper";
import Footer from "components/footers/footer";
import ContentWrapper from "components/body/contentWrapper";
import Content from "./cards/content";
import SubNavbar from "components/header/subNavbar";

const DaftarSuratMasuk = () => {
  const [key, setKey] = useState("daftarSuratMasukNonSekretariat");

  return (
    <Fragment>
      <div className="background-dark"></div>
      <Navbar />
      <Sidebar />
      <SubNavbarWrapper>
        <SubNavbar title="INBOX SURAT MASUK" keysTab={key} />
      </SubNavbarWrapper>
      <ContentWrapper>
        <Content keysTab={key} setKey={setKey} />
      </ContentWrapper>
      <Footer />
    </Fragment>
  );
};

export default DaftarSuratMasuk;
