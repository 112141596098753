import React, { Fragment } from "react";

import { Modal } from "react-bootstrap";

const ModalHakAkses = (props) => {
  return (
    <Fragment>
      <Modal
        size="lg"
        show={props.modal}
        onHide={() => props.setModal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header className="header-modal" closeButton>
          <Modal.Title
            className="title-modal"
            id="example-modal-sizes-title-lg"
          >
            Hak Akses
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row reset-margin-row">
            <div className="col-md-12 content-modal">
              <div className="container-fluid">
                <div className="text-row space-around">
                  <span>ID: {props.propsModal.nip}</span>
                  <span>Nama Lengkap: {props.propsModal.nama}</span>
                  <span>Jabatan: {props.propsModal.jabatan}</span>
                </div>
                <div>
                  <div className="title-text">Menu Akses</div>
                  <ul className="no-list display-table">
                    <li>
                      <i className="fa fa-envelope"></i> Surat Masuk
                      <ul className="no-list">
                        <li>
                          <input type="checkbox" />
                          <i className="fa fa-chevron-right"></i>
                          Buat Surat Masuk
                        </li>
                        <li>
                          <input type="checkbox" />
                          <i className="fa fa-chevron-right"></i>
                          Surat Masuk (Sekeretariat)
                        </li>
                        <li>
                          <input type="checkbox" />
                          <i className="fa fa-chevron-right"></i>
                          Inbox Surat Masuk
                        </li>
                        <li>
                          <input type="checkbox" />
                          <i className="fa fa-chevron-right"></i>
                          Terkirim
                        </li>
                      </ul>
                    </li>
                    <li>
                      <i className="fa fa-envelope"></i>
                      Surat Keluar
                      <ul className="no-list">
                        <li>
                          <input type="checkbox" />
                          <i className="fa fa-chevron-right"></i>
                          Buat Surat Keluar
                        </li>
                        <li>
                          <input type="checkbox" />
                          <i className="fa fa-chevron-right"></i>
                          Surat Keluar (Sekeretariat)
                        </li>
                        <li>
                          <input type="checkbox" />
                          <i className="fa fa-chevron-right"></i>
                          Pesetujuan
                        </li>
                        <li>
                          <input type="checkbox" />
                          <i className="fa fa-chevron-right"></i>
                          Telusuri
                        </li>
                        <li>
                          <input type="checkbox" />
                          <i className="fa fa-chevron-right"></i>
                          Konsep
                        </li>
                        <li>
                          <input type="checkbox" />
                          <i className="fa fa-chevron-right"></i>
                          Terkirim
                        </li>
                        <li>
                          <input type="checkbox" />
                          <i className="fa fa-chevron-right"></i>
                          Dibatalkan
                        </li>
                      </ul>
                    </li>
                    <li>
                      <i className="fa fa-server"></i>
                      Manajemen Master
                      <ul className="no-list">
                        <li>
                          <input type="checkbox" />
                          <i className="fa fa-chevron-right"></i>
                          Master Kop Surat
                        </li>
                        <li>
                          <input type="checkbox" />
                          <i className="fa fa-chevron-right"></i>
                          Master Klasifikasi
                        </li>
                        <li>
                          <input type="checkbox" />
                          <i className="fa fa-chevron-right"></i>
                          Master Pengguna
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="row reset-margin-row ">
            <div className="col-md-12 text-right mb-3">
              <button className="btn btn-default mr-2">Batal</button>
              <button className="btn btn-primary mr-2">Kirim</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default ModalHakAkses;
