import React, { Fragment, useState } from "react";
import { Link, useHistory, withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import {
  set_value,
  submitSuratKeluar,
  updateSuratKeluar,
} from "redux/SuratReducers/BuatSurat/BuatSuratActions";
import Modals from "components/modals/Modals";
import Buttons from "components/body/buttons/Button";
import {
  setLoading,
  set_status,
} from "redux/GeneralReducers/GeneralActions";
import { postFileRequest } from "config/axios";

function SubNavbar(props) {
  const dispatch = useDispatch();
  let history = useHistory();
  const [modals, setModals] = useState({
    isShow: false,
    status: null,
    action: {
      is_accepted: false,
      text: null,
      back_to_previous_level: false,
      keterangan: null,
      passphrase: null,
    },
  });
  const { BuatSuratReducers } = useSelector((state) => state);
  const { pemeriksa, attachment, initId } = BuatSuratReducers;
  const { id } = props.match.params;
  const path = props.match.path.split("/");
  const kategori = path[2];

  const actionBtn = (status) => {
    dispatch(
      submitSuratKeluar({ ...BuatSuratReducers, status_surat: [status] })
    ).then((res) => {
      console.log("respon after submit IIIIII", res)
      if (status === "draft") {
        history.push(`/surat-keluar/konsep/detail/${res}`);
      } else {
        history.push(`/surat-keluar/telusuri/detail/${res}`);
      }
    });
    // .catch((err) => console.log("gagal"));
  };

  // const uploadFileForSoftCopy = () => {
  //   const data = new FormData();
  //   data.append('file', attachment.originaFile)
  //   data.append('name', initId)
  //   postFileRequest(`/egs/mail/pdf`, data)
  //     .then(res => {
  //       console.log("respon upload files", res);
  //       dispatch(set_value({ value: { ...attachment, signedFile: res.data.items.url }, name: "attachment" }))
  //       saveSurat()
  //     }).catch(err => console.log("error post file", err))
  // }

  const saveSurat = () => {
    dispatch(submitSuratKeluar({ ...BuatSuratReducers })).then((res) => {
      history.push(`/surat-keluar/${kategori}/detail/${id}`);
    });
    // .catch((err) => dispatch(checkError(err)));
  };

  return (
    <Fragment>
      <div className="label-form-sub-nav m-0-10">
        {kategori === "persetujuan" && (
          <>
            {pemeriksa && pemeriksa.order_now === pemeriksa.user.length ? (
              <Link
                to="# "
                onClick={() =>
                  setModals({
                    ...modals,
                    status: "Setuju",
                    isShow: true,
                    action: {
                      text: "setuju",
                      keterangan: "Oke Setuju",
                      is_accepted: true,
                      back_to_previous_level: false,
                      passphrase: null,
                    },
                  })
                }
                className="btn btn-default"
              >
                <i className="fa fa-check"></i> SETUJU
              </Link>
            ) : (
              <Link
                to="# "
                onClick={() =>
                  setModals({
                    ...modals,
                    status: "Lanjutkan",
                    isShow: true,
                    action: {
                      text: "lanjutkan",
                      keterangan: "Oke Lanjutkan",
                      is_accepted: true,
                      back_to_previous_level: false,
                      passphrase: null,
                    },
                  })
                }
                className="btn btn-default"
              >
                <i className="fa fa-check"></i> LANJUTKAN
              </Link>
            )}

            <Link
              to="# "
              className="btn btn-default"
              onClick={() =>
                setModals({
                  ...modals,
                  status: "Tolak",
                  isShow: true,
                  action: {
                    text: "batalkan",
                    keterangan: "Batalkan",
                    is_accepted: false,
                    back_to_previous_level: false,
                    passphrase: null,
                  },
                })
              }
            >
              <i className="fa fa-times"></i> TOLAK
            </Link>
          </>
        )}
      </div>
      {/* <div className="line-vertical"></div> */}
      <div className="ex-btn-form-sub-nav m-0-10">
        {kategori !== "persetujuan" ? (
          <>
            <Link
              to="# "
              className="btn btn-default"
              onClick={() => actionBtn("pemeriksa")}
            >
              <i className="fa fa-send"></i> KIRIM
            </Link>

            <Link
              to="# "
              className="btn btn-default"
              onClick={() => actionBtn("draft")}
            // onClick={uploadFileForSoftCopy}
            >
              <i className="fa fa-save"></i> SIMPAN & LIHAT
            </Link>
          </>
        ) : (
          <>
            <Link
              to="# "
              className="btn btn-default"
              onClick={() => saveSurat()}
            >
              <i className="fa fa-save"></i> SIMPAN
            </Link>
            <Link
              to="# "
              className="btn btn-default"
              onClick={() =>
                setModals({
                  ...modals,
                  status: "Revisi Surat Keluar",
                  isShow: true,
                  action: {
                    keterangan: "Perbaikan",
                    text: "",
                    is_accepted: false,
                    back_to_previous_level: true,
                    passphrase: null,
                  },
                })
              }
            >
              <i className="fa fa-sign-out"></i> REVISI SURAT
            </Link>
          </>
        )}
      </div>
      <Modals show={modals} setShow={setModals} size="md" title={modals.status}>
        <ModalsAlert
          modals={modals}
          setModals={setModals}
          isPassphrase={
            pemeriksa.order_now === pemeriksa.user.length ? true : false
          }
        />
      </Modals>
    </Fragment>
  );
}

export default withRouter(SubNavbar);

const ModalsAlert = withRouter((props) => {
  const [state, setState] = useState(props.modals.action.keterangan);
  const [passphrase, setPassphrase] = useState(props.modals.action.passphrase);
  const id = props.match.params.id;
  const {
    GeneralReducers,
    BuatSuratReducers: { _id, tanda_tangan },
  } = useSelector((state) => state);
  const id_surat = _id;
  const dispatch = useDispatch();
  const history = useHistory();

  const submit = (isSetuju = false, backtoprevious = false) => {
    props.setModals({ ...props.modals, isShow: !props.modals.isShow });
    dispatch(
      updateSuratKeluar(id, {
        action: {
          ...props.modals.action,
          keterangan: state,
          passphrase: passphrase,
        },
      })
    ).then((res) => {
      dispatch(setLoading(false));
      console.log("respon pas setuju promise 1", props);
      console.log("respon pas setuju promise 2", res);
      console.log("respon pas setuju promise 3", id_surat);
      if (props.modals.action.text === "setuju") {
        dispatch(
          set_status({ status: "passphrase", msg: "Passphrase success !" })
        );
        history.push(`/surat-keluar/telusuri/detail/${id_surat}`);
      } else {
        history.push("/surat-keluar/persetujuan");
      }
    });
  };
  return (
    <>
      <div className="row reset-margin-row">
        <div className="col-md-12 content-modal">
          <div className="container-fluid">
            <div className="form-group">
              <label htmlFor="catatan">Beri Catatan</label>
              <textarea
                className="form-control"
                defaultValue={state}
                onChange={(e) => setState(e.target.value)}
              ></textarea>
              {!tanda_tangan && props.isPassphrase && props.modals.status === 'Setuju' ? (
                <>
                  <label htmlFor="catatan">Passphrase</label>
                  <input
                    type="text"
                    id="passphrase-input"
                    autoComplete="new-password"
                    data-lpignore="true"
                    name="passphrase"
                    className="form-control"
                    onChange={(e) => setPassphrase(e.target.value)}
                  />
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="row reset-margin-row ">
        <div className="col-md-12 text-right mb-3">
          <Buttons
            className="btn btn-primary"
            children="Simpan"
            disabled={GeneralReducers.loading}
            loading={GeneralReducers.loading}
            onClick={submit}
          >
            <i className="fa fa-send"></i> Oke
          </Buttons>
        </div>
      </div>
    </>
  );
});
