import "firebase/messaging";

import firebase from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyDhYDO5quPOBSIrpXcleCBBlLUVaWuX2eU",
  authDomain: "pallaka-ams.firebaseapp.com",
  databaseURL: "https://pallaka-ams.firebaseio.com",
  projectId: "pallaka-ams",
  storageBucket: "pallaka-ams.appspot.com",
  messagingSenderId: "573602816967",
  appId: "1:573602816967:web:2ed7bda29daba1b3d4afe2",
  measurementId: "G-6CC5665PBD",
};

firebase.initializeApp(firebaseConfig);
let messaging = null;
if (firebase.messaging.isSupported()) {
  console.log("supported browser");
  messaging = firebase.messaging();
} else {
  console.log("un supported browser");
  // window.location.pathname !== "/unsupport-browser" &&
  //   (window.location.href = "/unsupport-browser");
}

// messaging.onMessage((tes) => {
//   console.log("asssssssssssssssssssssss", tes);
// });
export { messaging, firebase };
