import React, { Fragment } from "react";
import Navbar from "components/header/navbar";
import Sidebar from "components/sidebar/sidebar";
import SubNavbarWrapper from "components/header/subNavbarWrapper";
import Footer from "components/footers/footer";
import ContentWrapper from "components/body/contentWrapper";
import { ContentDetailSurat } from "./content";
import SubNavbar from "./subNavbar";
import { withRouter } from "react-router-dom";

const SuratDetailList = (props) => {
  const { type, id } = props.match.params;

  const rules = {
    "masuk": {
      viewSurat: true,
      dataSurat: true,
      lampiran: true,
      riwayatTindakan: true,
      infoJadwal: true
    },
    "masuk-sekretariat": {
      dataSurat: true,
      lampiran: true,
      infoJadwal: true
    },
    "keluar": {
      viewSurat: true,
      dataSurat: true,
      lampiran: true,
      riwayatTindakan: true,
      infoJadwal: true
    },
  }

  const ruleSubNavbar = {
    "terkirim": {
      info_btn: true,
      wa_btn: true,
    },
    "masuk-sekretariat": {
      info_btn: true,
      wa_btn: true,
    },
    "masuk": {
      info_btn: false,
      wa_btn: false,
      print_action: { path: `/bank-surat/print-view-riwayat/${id}`, label: 'Cetak Riwayat' }
    },
  }

  console.log("detail props params", id)
  return (
    <Fragment>
      <div className="background-dark"></div>
      <Navbar />
      <Sidebar />
      <SubNavbarWrapper>
        <SubNavbar {...ruleSubNavbar[type]} />
      </SubNavbarWrapper>
      <ContentWrapper>
        <ContentDetailSurat
          {...rules[type]}
        />
      </ContentWrapper>
      <Footer />
    </Fragment>
  );
}

export default withRouter(SuratDetailList);

