import NextBtn from "components/body/buttons/nextBtn";
import PrevBtn from "components/body/buttons/prevBtn";
import React, { Fragment, Component } from "react";
import TextEditor from "./TextEditor";

class IsiSurat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editor: "",
    };
  }

  render() {
    return (
      <Fragment>
        <div className="form-group">
          <TextEditor />
        </div>
        <div className="col-md-12 flex-space-between">
          <PrevBtn to="referensi" />
          <NextBtn to="lampiran" />
        </div>
      </Fragment>
    );
  }
}

export default IsiSurat;
