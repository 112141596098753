import React, { Component, Fragment } from "react";
import Navbar from "components/header/navbar";
import Sidebar from "components/sidebar/sidebar";
import SubNavbarWrapper from "components/header/subNavbarWrapper";
import Footer from "components/footers/footer";
import ContentWrapper from "components/body/contentWrapper";
import Content from "./content";
import SubNavbar from "components/header/subNavbar";

export default class Terkirim extends Component {
  render() {
    return (
      <Fragment>
        <div className="background-dark"></div>
        <Navbar />
        <Sidebar />
        <SubNavbarWrapper>
          <SubNavbar title="SURAT MASUK TERKIRIM" keysTab="terkirim-external" />
        </SubNavbarWrapper>
        <ContentWrapper>
          <Content />
        </ContentWrapper>
        <Footer />
      </Fragment>
    );
  }
}
