import { store as stores } from "react-notifications-component";
import { setNotifikasi } from "redux/Notifications/NotificationActions";
import { store } from "redux/rootRedux/Store";

navigator.serviceWorker.addEventListener("message", (message) => {
	let notif;
	if (message.data["firebase-messaging-msg-data"]) {
		notif = message.data["firebase-messaging-msg-data"].data;
	} else {
		notif = message.data.data;
	}
	store.dispatch(setNotifikasi(notif));
	notif &&
		stores.addNotification({
			title: `Surat ${notif.kategori}`,
			message: notif.perihal,
			type: notif.type,
			insert: "bottom",
			container: "bottom-right",
			animationIn: ["animate__animated", "animate__fadeIn"],
			animationOut: ["animate__animated", "animate__fadeOut"],
			dismiss: {
				duration: 6000,
				onScreen: true,
			},
		});
});
