import React, { Fragment, useState, useEffect } from "react";
import Paginate from "components/footers/paginate";
import { useDispatch, useSelector } from "react-redux";
import {
  decrement,
  increment,
  setFirstPage,
  setLastPage,
} from "utils/pagination";
import { getListMethod } from "redux/SuratReducers/ListSuratMasuk/ListSuratAction";
import Loading from "components/body/Loading";
import ModalComponent from "./modals";

const Content = () => {
  const dispatch = useDispatch();
  const { GeneralReducers, ListSuratReducers } = useSelector((state) => state);
  const [isOpen, setOpen] = useState(false)
  const { loading } = GeneralReducers;
  const [satuan_kerja, setSatuanKerja] = useState({ status: true });
  const { items, meta } = ListSuratReducers.listMasterSatuanKerja;

  const getList = (current_page = 1) => {
    dispatch(getListMethod(`pegawai-egs/api/satuan-kerja`, "listMasterSatuanKerja", current_page));
  };

  const editSatuanKerja = (item) => {
    setOpen(true);
    setSatuanKerja({ ...item })

  }

  useEffect(getList, []);

  return (
    <Fragment>
      <ModalComponent
        isOpen={isOpen}
        setOpen={setOpen}
        satuan_kerja={satuan_kerja}
        setSatuanKerja={setSatuanKerja}
        getList={getList}
      />
      <div className="content-title">
        <div>
          <a
            onClick={() => setOpen(true)}
            href="# "
            className="btn btn-sm btn-primary"
          >
            <i className="fa fa-plus"></i> Tambah Satuan Kerja
          </a>
        </div>
      </div>
      <div className="content-body">
        <Loading isLoading={loading} />
        <table className="table table-striped table-list">
          <thead>
            <tr>
              <td>Nama</td>
              <td>Alamat</td>
              <td>Opsi</td>
            </tr>
          </thead>
          <tbody>
            {items &&
              items.map((item, i) => {
                return (
                  <tr key={`${i}-${item.id}`}>
                    <td>
                      {item.name}
                    </td>
                    <td>
                      {item.address}
                    </td>
                    <td>
                      <button className="btn btn-sm btn-default" onClick={() => editSatuanKerja(item)}>
                        <i className="fa fa-pencil"></i>
                      </button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      {(
        <Paginate
          increment={() => increment(meta.current_page, getList)}
          meta={meta}
          decrement={() => decrement(meta.current_page, getList)}
          setLastPage={() => setLastPage(meta.last_page, getList)}
          setFirstPage={() => setFirstPage(meta.from, getList)}
        />
      )}
    </Fragment>
  );
};

export default Content;
