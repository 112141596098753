import { NavLink } from "react-router-dom";
import React from "react";
import { useSelector } from "react-redux";

function Sidebar() {
  const {
    NotificationReducers,
    LoginReducers: { menus },
  } = useSelector((state) => state);
  // const dispatch = useDispatch();
  const { persetujuan_count, surat_masuk } = NotificationReducers;

  // const goHome = () => {
  //   window.location.href = "/";
  // };
  // console.log("============= menus", menus)
  return (
    <div id="#sidebar" className="sidebar">
      <div className="img-logo pointer-mouse">
        <i className="toggle-sidebar fa fa-chevron-left"></i>

        <img src={process.env.PUBLIC_URL + '/assets/img/logo.svg'} alt="logo" width="40px" />
        <span className="text-logo">
          <h3>EGS-AMS</h3>
        </span>
      </div>
      <div className="wrapper-side-link">
        <div className="text-link-menu">Menu</div>
        <ul className="side-link">
          {menus.length > 0 &&
            menus.map((list, i) => {
              return (
                <li className="side-link-item" key={i}>
                  <NavLink
                    to={list.to}
                    onClick={(e) => {
                      list.data_link !== "dashboard" && e.preventDefault();
                    }}
                    data-link={list.data_link}
                    className="link-item"
                  >
                    <i className={`fa ${list.ico}`}></i>
                    <span>{list.name}</span>
                    {list.data_link !== "dashboard" ? (
                      <i className="fa arr fa-chevron-down"></i>
                    ) : (
                      <span></span>
                    )}
                  </NavLink>
                  {list.sub_link.length > 0 && (
                    <ul className={`sub-side-link-item ${list.data_link}`}>
                      {list.sub_link.map((sub_list, i) => {
                        return (
                          <li key={i}>
                            <NavLink to={sub_list.to} className="child-link">
                              <i className={`fa ${sub_list.ico}`}></i>{" "}
                              {sub_list.name}
                              {sub_list.name === "Surat Masuk" &&
                                surat_masuk > 0 && (
                                  <span className="pull-right badge badge-danger">
                                    {surat_masuk}
                                  </span>
                                )}
                              {sub_list.name === "Persetujuan" &&
                                persetujuan_count > 0 && (
                                  <span className="pull-right badge badge-danger">
                                    {persetujuan_count}
                                  </span>
                                )}
                            </NavLink>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
}

export default Sidebar;
