import React from 'react'

function Input({type = "text", disabled = false, value}) {
  return (
    <input
      type={type}
      className="form-control"
      disabled={disabled}
      value={
        value
    }
  />
  )
}

export default Input
