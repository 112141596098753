import React, { Fragment, useState, useEffect, useCallback } from "react";
import { getRequest } from "config/axios";
import { Modal, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { set_value } from "redux/SuratReducers/BuatSurat/BuatSuratActions";
import { checkError, setLoading } from "redux/GeneralReducers/GeneralActions";
import Loading from "components/body/Loading";
import { debounce } from "lodash";
import PrevBtn from "components/body/buttons/prevBtn";
import NextBtn from "components/body/buttons/nextBtn";
import Input from "components/body/inputs/Input";
import InputsWithLabel from "components/body/inputs/InputsWithLabel";

const Pengirim = () => {
  const [lgShow, setLgShow] = useState(false);
  const { BuatSuratReducers } = useSelector((state) => state);

  const { pengirim } = BuatSuratReducers;
  // console.log("pengirim ", BuatSuratReducers)
  return (
    <Fragment>
      <Modals lgShow={lgShow} setLgShow={setLgShow} />
      <div className="form-group">
        <label htmlFor="jabatan">
          <a href="# " onClick={() => setLgShow(!lgShow)}>
            Jabatan*
          </a>
        </label>
        <Input type="text" disabled={true} value={pengirim.user.data_pegawai.jabatan_nama} />
      </div>
      <div className="form-group">
        <InputsWithLabel
          type="text"
          disabled={true}
          label="Nama Pegawai"
          value={pengirim.user.data_pegawai.peg_nama}
        />
      </div>
      <div className="form-group">
        <InputsWithLabel
          type="text"
          disabled={true}
          label="NIP"
          value={pengirim.user.data_pegawai.peg_nip}
        />
      </div>
      <div className="form-group">
        <InputsWithLabel
          type="text"
          disabled={true}
          label="Nama Unit"
          value={pengirim.user.data_pegawai.unit_kerja.unit_kerja_nama}
        />
      </div>
      <div className="form-group">
        <label htmlFor="jabatan">Business Organisasi</label>
        <input type="text" className="form-control" />
      </div>
      <div className="col-md-12 flex-space-between">
        <PrevBtn to="kepala-surat" />
        <NextBtn to="penerima" />
      </div>
    </Fragment>
  );
};

export default Pengirim;

const Modals = (props) => {
  const [dataPegawai, setDataPegawai] = useState(null);
  const [search, setQ] = useState("");
  const [selected, setSelected] = useState(null);
  const dispatch = useDispatch();
  const { setLgShow, lgShow } = props;
  const { GeneralReducers } = useSelector((state) => state);

  const getJabatan = (current_page = 1, q = "") => {
    dispatch(setLoading(true));
    getRequest(`api/egs/list/pegawai-by-jabatan?q=${q}&page=${current_page}&per_page=10`)
      .then((res) => {
        setDataPegawai(res.data);
        dispatch(setLoading(false));
      })
      .catch((err) => dispatch(checkError(err)));
  };

  const debounceSave = useCallback(
    debounce((current_page, q) => getJabatan(current_page, q), 1000),
    []
  );

  const setListPegawai = (item) => {
    const lists = dataPegawai.data.map((list) => {
      return (item.peg_nip === list.peg_nip) && (item.is_plt === list.is_plt)
        ? {
          ...list,
          active_selected: true,
        }
        : { ...list, active_selected: false };
    });
    setDataPegawai({ ...dataPegawai, data: lists });
    setSelected(item);
  };

  const setPengirim = () => {
    dispatch(
      set_value({
        value: {
          user: {
            data_pegawai: {
              jabatan_nama: selected.namaJabatan,
              peg_nama: selected.fullName,
              peg_nip: selected.peg_nip,
              is_plt: selected.is_plt || false,
              unit_kerja: {
                unit_kerja_nama: selected.unitKerja,
              },
            },
            peg_nip: selected.peg_nip,
          },
        },
        name: "pengirim",
      })
    );
    setQ("");
    setLgShow(false);
  };

  useEffect(() => debounceSave(1, search), [debounceSave, search]);

  return (
    <Fragment>
      <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header className="header-modal" closeButton>
          <Modal.Title
            className="title-modal"
            id="example-modal-sizes-title-lg"
          >
            JABATAN
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row reset-margin-row">
            <div className="col-md-12 content-modal">
              <div className="title-tab-second bg-white no-border-bottom text-right">
                <span className="mr-3">
                  {dataPegawai && dataPegawai.current_page} dari{" "}
                  {dataPegawai && dataPegawai.last_page}
                </span>
                <i
                  className="fa fa-chevron-left mr-3 pointer-mouse"
                  onClick={() => {
                    dataPegawai.current_page > 1 &&
                      getJabatan(dataPegawai.current_page - 1);
                  }}
                ></i>
                <i
                  className="fa fa-chevron-right pointer-mouse"
                  onClick={() => {
                    dataPegawai.current_page < dataPegawai.last_page &&
                      getJabatan(dataPegawai.current_page + 1);
                  }}
                ></i>
              </div>
              <Loading isLoading={GeneralReducers.loading} />
              <Table striped bordered hover className="table-modal-content">
                <thead>
                  <tr>
                    <th>Nama</th>
                    <th>Nip</th>
                    <th>Jabatan</th>
                  </tr>
                </thead>
                <tbody>
                  {dataPegawai &&
                    dataPegawai.data.map((view, i) => {
                      return (
                        <tr
                          key={i}
                          onClick={() => setListPegawai(view)}
                          className={`pointer-mouse ${view.active_selected && `active-selected`
                            }`}
                        >
                          <td>{view.fullName}</td>
                          <td>{view.peg_nip}</td>
                          <td>
                            {view.is_plt && <b>PLT - </b>} {view.namaJabatan}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </div>
          </div>
          <hr />
          <div className="row reset-margin-row ">
            <div className="col-md-4 mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Kata Kunci"
                value={search}
                onChange={(e) => setQ(e.target.value)}
              />
            </div>
            <div className="col-md-5 mb-3"></div>
            <div className="col-md-3 tabs-header-modal space-table mb-3">
              {selected && (
                <button
                  onClick={setPengirim}
                  className="btn col-md-12 btn-primary mr-2"
                >
                  Oke
                </button>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};
