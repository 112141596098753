import React, { Fragment } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { checkError, setLoading } from "redux/GeneralReducers/GeneralActions";

const dataJadwalIfUndefine = {
  tanggal_mulai: new Date(),
  tanggal_berakhir: new Date(),
  tempat: "",
};
const InfoJadwal = ({ data_jadwal = dataJadwalIfUndefine, data }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isSuccessEvent, setIsSuccessEvent] = useState(null);
  const [mailInfo, setMailInfo] = useState(null);
  const dispatch = useDispatch();
  const startDateTime = convertDate(data_jadwal.tanggal_mulai);
  const endDateTime = convertDate(data_jadwal.tanggal_berakhir);

  function convertDate(date) {
    return {date: new Date(date).toDateString(), time: new Date(date).toLocaleTimeString() }
  }

  var gapi = window.gapi;
  const handleEventCaledarInit = () => {
    gapi.load("client:auth2", initClient);
  };

  const initClient = () => {
    dispatch(setLoading(true));
    gapi.client
      .init({
        apiKey: process.env.REACT_APP_API_KEY,
        clientId: process.env.REACT_APP_CLIENT_ID,
        discoveryDocs: [process.env.REACT_APP_DISCOVERY_DOCS],
        scope: process.env.REACT_APP_SCOPES,
      })
      .then(
        function () {
          if (gapi.auth2.getAuthInstance().isSignedIn.get()) {
            let profile = gapi.auth2
              .getAuthInstance()
              .currentUser.get()
              .getBasicProfile();
            setMailInfo(profile.getEmail());
            setIsAuthenticated(true);
            dispatch(setLoading(false));
          } else {
            handleAuthClick();
          }
        },
        function (error) {
          console.log("gapi", error);
        }
      );
  };

  const addingEvent = () => {
    dispatch(setLoading(true));
    let startDate = new Date(data_jadwal.tanggal_mulai);
    let endDate = new Date(data_jadwal.tanggal_berakhir);
    var event = {
      summary: data.kepala_surat.perihal,
      location: data_jadwal.tempat,
      description: `Detail Surat: ${data.esign_document.url}`,
      start: {
        dateTime: startDate,
        timeZone: "Asia/Jakarta",
      },
      end: {
        dateTime: endDate,
        timeZone: "Asia/Jakarta",
      },
      recurrence: ["RRULE:FREQ=DAILY;COUNT=1"],
      reminders: {
        useDefault: false,
        overrides: [
          { method: "email", minutes: 24 * 60 },
          { method: "popup", minutes: 10 },
        ],
      },
    };

    var request = gapi.client.calendar.events.insert({
      calendarId: "primary",
      resource: event,
    });
    request.execute(function (event) {
      dispatch(setLoading(false));
      setIsSuccessEvent("Event berhasil di tambah !");
    });
  };

  function handleAuthClick() {
    gapi.auth2
      .getAuthInstance()
      .signIn()
      .then(() => {
        let profile = gapi.auth2
          .getAuthInstance()
          .currentUser.get()
          .getBasicProfile();
        setMailInfo(profile.getEmail());
        setIsAuthenticated(true);
        dispatch(setLoading(false));
      })
      .catch((err) => dispatch(checkError(err, "request-fail")));
  }

  function handleSignoutClick() {
    dispatch(setLoading(true));
    if (gapi.auth2.getAuthInstance().isSignedIn.get()) {
      return gapi.auth2
        .getAuthInstance()
        .signOut()
        .then(() => {
          setMailInfo(null);
          dispatch(setLoading(false));
          setIsAuthenticated(false);
        })
        .catch((err) => dispatch(checkError(err, "request-fail")));
    }
  }
  return (
    <Fragment>
      {isSuccessEvent && (
        <div className="alert alert-success">{isSuccessEvent}</div>
      )}
      <table className="table table-striped font-12">
        <tbody>
          <tr>
            <td>Tanggal</td>
            <td>
              : {startDateTime.date} - {endDateTime.date}
            </td>
          </tr>
          <tr>
            <td>Waktu</td>
            <td>
              : {startDateTime.time} - {endDateTime.time}
            </td>
          </tr>
          <tr>
            <td>Tempat</td>
            <td>: {data_jadwal.tempat}</td>
          </tr>
        </tbody>
      </table>
      <div className="form-group">
        {isAuthenticated && (
          <button className="btn btn-info m-lr-5" onClick={handleSignoutClick}>
            {mailInfo} - Signout ?
          </button>
        )}
        {isAuthenticated ? (
          <button className="btn btn-primary m-lr-5" onClick={addingEvent}>
            <img src="/assets/img/icon/google-calendar.png" alt="icon-gc" /> Add
            To Google Calendar
          </button>
        ) : (
          <button
            className="btn btn-primary m-lr-5"
            onClick={handleEventCaledarInit}>
            <img
              src="/assets/img/icon/google-calendar.png"
              alt="icon-gc"
            />
            Authenticate To Added Google Calendar
          </button>
        )}
      </div>
    </Fragment>
  );
};

export default InfoJadwal;
