import React from "react";
import "../../styles/footer/paginate.scss";

const Paginate = (props) => {
  // const increment = (e) => {
  //   e = increment();
  // };

  return (
    <div className="content-footer">
      <div className="paginate-table">
        <div className="paginate-text">
          Menampilkan page {props.meta && props.meta.current_page} dari{" "}
          {props.meta && props.meta.last_page} total pages dan{" "}
          {props.meta && props.meta.total} total entri.
        </div>
        <ul className="paginate">
          <li>
            <a href="# " onClick={props.setFirstPage}>
              <img src="/assets/img/icon/arrow-left-end.svg" alt="arrowLeft" />
            </a>
          </li>
          <li>
            {props.meta && props.meta.current_page > 1 && (
              <a href="# " onClick={props.decrement}>
                <img src="/assets/img/icon/arrow-left.svg" alt="arrowLeft" />
              </a>
            )}
          </li>
          <li>
            {props.meta && props.meta.current_page < props.meta.last_page && (
              <a href="# " onClick={props.increment}>
                <img src="/assets/img/icon/arrow-right.svg" alt="arrowLeft" />
              </a>
            )}
          </li>
          <li>
            <a href="# " onClick={props.setLastPage}>
              <img src="/assets/img/icon/arrow-right-end.svg" alt="arrowLeft" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Paginate;
