import React, { Fragment } from "react";
import { Modal } from "react-bootstrap";

const Modals = ({ show, setShow, title, children, size }) => {
  return (
    <Fragment>
      <Modal
        size={size}
        show={show.isShow}
        onHide={() => setShow({ ...show, isShow: false })}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header className="header-modal" closeButton>
          <Modal.Title
            className="title-modal"
            id="example-modal-sizes-title-lg"
          >
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default Modals;
