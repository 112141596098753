import { SET_LIST, SET_DEFAULT } from "redux/rootRedux/TypeActions";
import moment from "moment";
import "moment/locale/id";
moment.locale("id");

let today = moment(new Date()).format("YYYY-MM-DD");
let a_month_ago = moment(new Date()).subtract(1, "month").format("YYYY-MM-DD");

const GlobalState = {
  listMasterPegawai: {},
  listMasterSatuanKerja: {},
  listMasterUnitKerja: {},
  listMasterJabatan: {},
  daftarSuratMasukSekretariat: {},
  daftarSuratMasukNonSekretariat: {},
  listPersetujuan: {},
  listTelusuri: {},
  listKonsep: {},
  listTerkirimInternal: {},
  listTerkirimExternal: {},
  listSuratBatal: {},
  listLogs: {},
  listBankSurat: {},
  listBankSuratMasuk: {},
  listBankSuratMasukSekretariat: {},
  listBankSuratKeluar: {},
  start_date: a_month_ago,
  end_date: today,
  q: "",
  jenis_surat: null,
  status_surat: null
};

export const ListSuratReducers = (state = GlobalState, action) => {
  switch (action.type) {
    case SET_LIST:
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case SET_DEFAULT:
      return {
        ...state,
        start_date: a_month_ago,
        end_date: today,
        q: "",
      };

    default:
      return state;
  }
};
