import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

function PrivateRoute({ Component, ...rest }) {
  const Auth = useSelector((state) => state.LoginReducers);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        Auth.isLogin ? (
          <Component />
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;
