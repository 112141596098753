import { setLoading } from "redux/GeneralReducers/GeneralActions";
import { getRequest } from "config/axios";
import { SET_SKPD } from "redux/rootRedux/TypeActions";

const set_skpd = (data) => ({
  type: SET_SKPD,
  payload: data,
});

export const getSkpd = () => {
  return (dispatch) => {
    dispatch(setLoading(true));
    getRequest(`api/master/skpd`)
      .then((res) => {
        // console.log("respon aster skpd", res.data.data);

        const options = res.data.data.map((list, i) => ({
          id: list.satuan_kerja_id,
          value: list.kode_skpd,
          label: list.satuan_kerja_nama,
        }));
        dispatch(set_skpd(options));
        dispatch(setLoading(false));
      })
      .catch((err) => {
        // console.log("error get skpd", err);
        dispatch(setLoading(false));
      });
  };
};
