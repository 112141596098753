import React, { Fragment, useState } from "react";
import { checkError, setLoading } from "redux/GeneralReducers/GeneralActions";
import {
  decrement,
  increment,
  setFirstPage,
  setLastPage,
} from "utils/pagination";
import { useDispatch, useSelector } from "react-redux";

import Loading from "components/body/Loading";
import ModalHakAkses from "./ModalHakAkses";
import Paginate from "components/footers/paginate";
import Select from "react-select";
import { getRequest } from "config/axios";
import { useEffect } from "react";

const Content = () => {
  const [modal, setModal] = useState(false);
  const [list, setList] = useState(null);
  const [dataSkpd, setDataSkpd] = useState(null);
  const [propsModal, setPropsModal] = useState(null)
  const [q, setQ] = useState("");
  const dispatch = useDispatch();
  const { GeneralReducers, LoginReducers } = useSelector((state) => state);
  let satuan_kerja_id =
    LoginReducers.userInfo.data_pegawai.satuan_kerja.satuan_kerja_id || 0;
  const [idSkpd, setIdSkpd] = useState(satuan_kerja_id);

  const getSkpd = () => {
    dispatch(setLoading(true));
    getRequest(`/api/master/skpd`)
      .then((res) => {
        dispatch(setLoading(false));
        filterSkpd(res.data);
      })
      .catch((err) => dispatch(checkError(err)));
  };

  const getList = (current_page = 1) => {
    dispatch(setLoading(true));
    getRequest(
      `api/egs/list/pegawai?satuan_kerja_id=${idSkpd}&q=${q}&page=${current_page}&per_page=10`
    )
      .then((res) => {
        dispatch(setLoading(false));
        setList(res.data);
        console.log("-----", res.data);
      })
      .catch((err) => dispatch(checkError(err)));
  };

  const filterSkpd = (data) => {
    let a = data.data.map((list) => {
      return { label: list.satuan_kerja_nama, value: list.satuan_kerja_id };
    });
    setDataSkpd(a);
  };

  const onSelectSkpd = (e) => {
    setIdSkpd(e.value);
  };

  useEffect(getList, [idSkpd]);
  useEffect(getSkpd, []);
  const openModalHakAkses = (nama, jabatan, nip) => {
    setModal(!modal)
    setPropsModal({nama, jabatan, nip})
  }
  return (
    <Fragment>
    {modal &&
      <ModalHakAkses modal={modal} setModal={setModal} propsModal={propsModal} />
    }
      <div className="row">
        <div className="col-md-8 col-sm-12">
          <Select
            placeholder="Pilih SKPD"
            className="m-l-5 m-b-5"
            options={dataSkpd}
            onChange={(e) => onSelectSkpd(e)}
          />
        </div>
      </div>
      <hr />
      <div className="content-body">
        <Loading isLoading={GeneralReducers.loading} />
        <table className="table table-striped">
          <thead>
            <tr>
              <td>NIP</td>
              <td>Nama Lengkap</td>
              <td>Jabatan</td>
              <td>Nama Pengguna</td>
              <td>Hak Akses</td>
              <td>Opsi</td>
            </tr>
          </thead>
          <tbody>
            {list &&
              list.data.map((list, i) => {
                return (
                  <tr key={i}>
                    <td>{list.peg_nip}</td>
                    <td>{list.peg_nama}</td>
                    <td>{list.satuanKerjaNama}</td>
                    <td>-</td>
                    <td>
                      <i className="fa fa-toggle-on"></i>
                    </td>
                    <td>
                      <a href="# " onClick={() => openModalHakAkses(list.peg_nama,list.satuanKerjaNama,list.peg_nip)}>
                        <i className="fa fa-edit"></i>
                      </a>
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
      {list && (
        <Paginate
          increment={() => increment(list.current_page, getList)}
          meta={list}
          decrement={() => decrement(list.current_page, getList)}
          setLastPage={() => setLastPage(list.last_page_url, getList)}
          setFirstPage={() => setFirstPage(list.first_page_url, getList)}
        />
      )}
    </Fragment>
  );
};

export default Content;
