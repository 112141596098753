import React from 'react'
import { NavLink } from 'react-router-dom'

const Navmenu = () => {
  return (
    <header>
      <img src={process.env.PUBLIC_URL + '/assets/img/login-logo.png'} alt="login_logo" />
      <i className="fa fa-bars btn-bar__menu-login"></i>
      <div className="menu-header">
        <ul>
          <li>
            <NavLink exact to="/">Masuk</NavLink>
          </li>
          <li>
            <NavLink exact to="/cari">Cari Surat</NavLink>
          </li>
        </ul>
      </div>
    </header>
  )
}

export default Navmenu
