import { messaging } from "../firebase";
import { set_token_fcm } from "redux/loginRedux/LoginActios";

const { store } = require("redux/rootRedux/Store");

const getTokenFcm = () => {
  Notification.requestPermission();
  if (messaging) {
    messaging
      .getToken()
      .then((token) => {
        store.dispatch(set_token_fcm(token));
      })
      .catch((err) => console.log("errorr get token FCM", err));
  }
};

export {
  getTokenFcm
}