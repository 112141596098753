const SET_LOADING = "SET_LOADING";
const SET_STATUS = "SET_STATUS";
const UNAUTHORIZED = "UNAUTHORIZED";
const SET_RESPON_REQUEST = "SET_RESPON_REQUEST";

const SET_TOKEN = "SET_TOKEN";
const SET_USER_INFO = "SET_USER_INFO";
const RESET_VALUE = "RESET_VALUE";

const SET_VALUE_SURAT_MASUK = "SET_VALUE_SURAT_MASUK";
const RESET_VALUE_SURAT_MASUK = "RESET_VALUE_SURAT_MASUK";
const SET_SURAT_MASUK = "SET_SURAT_MASUK";
const EDIT_SURAT_KELUAR = "EDIT_SURAT_KELUAR";
const SET_SKPD = "SET_SKPD";
const SET_VALUE = "SET_VALUE";
const SEND_TO_DRAFT = "SEND_TO_DRAFT";
const SET_LIST = "SET_LIST";
const SET_DATE = "SET_DATE";
const SET_DEFAULT = "SET_DEFAULT";
const SET_NOTIF = "SET_NOTIF";
const RESET_NOTIF = "RESET_NOTIF";
const SET_TOKEN_FCM = "SET_TOKEN_FCM";
const GET_NOTIF = "GET_NOTIF";
const SET_BADGE = "SET_BADGE";
const SET_MENU = "SET_MENU";
const SET_OPTION_SELECT = "SET_OPTION_SELECT";
const SET_OPTION_DEFAULT = "SET_OPTION_DEFAULT";

export {
  SET_TOKEN,
  SET_USER_INFO,
  SET_LOADING,
  SET_RESPON_REQUEST,
  SET_STATUS,
  SET_SKPD,
  SET_VALUE,
  SEND_TO_DRAFT,
  UNAUTHORIZED,
  RESET_VALUE,
  SET_VALUE_SURAT_MASUK,
  RESET_VALUE_SURAT_MASUK,
  SET_SURAT_MASUK,
  EDIT_SURAT_KELUAR,
  SET_LIST,
  SET_DATE,
  SET_DEFAULT,
  SET_NOTIF,
  RESET_NOTIF,
  SET_TOKEN_FCM,
  GET_NOTIF,
  SET_BADGE,
  SET_MENU,
  SET_OPTION_SELECT,
  SET_OPTION_DEFAULT
};
