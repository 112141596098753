import React from "react";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { submitSuratMasuk } from "redux/SuratReducers/BuatSuratMasuk/BuatSuratMasukAction";

function SubNavbar() {
  const { BuatSuratMasukReducers } = useSelector((state) => state);
  const dispatch = useDispatch();
  return (
    <Fragment>
      <div className="label-form-sub-nav m-0-10"></div>
      <div className="label-form-sub-nav">
        <Link
          to="# "
          className="btn btn-default"
          onClick={() => dispatch(submitSuratMasuk(BuatSuratMasukReducers))}
        >
          <i className="fa fa-send"></i> KIRIM
        </Link>
      </div>
    </Fragment>
  );
}

export default SubNavbar;
