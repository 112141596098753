import React, { Fragment, useState } from "react";
import { Modal } from "react-bootstrap";
import { patchRequest, postFileRequest } from "config/axios";
import { useDispatch, useSelector } from "react-redux";
import {
  checkError,
  setLoading,
  setStatus,
} from "redux/GeneralReducers/GeneralActions";
import Buttons from "components/body/buttons/Button";

const ModalBerkas = (props) => {
  const formData = new FormData();
  const dispatch = useDispatch();
  const { GeneralReducers } = useSelector((state) => state);
  const [attacment, setAttacment] = useState(null);

  const dragOver = (e) => {
    e.preventDefault();
  };

  const dragEnter = (e) => {
    e.preventDefault();
  };

  const dragLeave = (e) => {
    e.preventDefault();
  };

  const fileDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    uploadFileDropped(files[0]);
  };

  const onClickFile = (e) => {
    e.preventDefault();
    const files = e.target.files;
    uploadFileDropped(files[0]);
  };

  const uploadFileDropped = (data) => {
    dispatch(setLoading(true));
    formData.append("file", data);
    postFileRequest(`api/master/store-file`, formData)
      .then((res) => {
        setAttacment({ file: data, respon: res.data.data });
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(checkError(err));
      });
  };

  const saveResponse = () => {
    dispatch(setLoading(true));
    patchRequest(`egs/master/kop-surat/${props.id}`, { ...attacment.respon })
      .then((res) => {
        dispatch(setLoading(false));
        props.setModalBerkas(false);
        props.getList();
        dispatch(
          setStatus({ status: "success", msg: "Berhasil menyimpan file" })
        );
      })
      .catch((err) => {
        dispatch(checkError(err));
      });
  };

  return (
    <Fragment>
      <Modal
        size="lg"
        show={props.modalBerkas}
        onHide={() => {
          props.setModalBerkas(false);
          setAttacment(null);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header className="header-modal" closeButton>
          <Modal.Title
            className="title-modal"
            id="example-modal-sizes-title-lg"
          >
            Berkas
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row reset-margin-row">
            <div className="col-md-6 content-modal">
              <div className="container-fluid">
                <div className="form-group">
                  {GeneralReducers.status === "fail" ? (
                    <div className="alert alert-danger">
                      {GeneralReducers.msg}
                    </div>
                  ) : (
                    <label htmlFor="lokasi">Unggah Berkas*</label>
                  )}
                  <div className="content-lampiran">
                    <div className="container-lampiran">
                      <div
                        className="drop-container-lampiran"
                        onDragOver={dragOver}
                        onDragEnter={dragEnter}
                        onDragLeave={dragLeave}
                        onDrop={fileDrop}
                      >
                        <input
                          type="file"
                          id="file"
                          onChange={onClickFile}
                          style={{ display: "none" }}
                        />
                        <div className="drop-message-lampiran">
                          {GeneralReducers.loading ? (
                            <div>
                              <i className="fa fa-spinner fa-2x fa-pulse fa-fw"></i>
                            </div>
                          ) : (
                            <div className="upload-icon-lampiran"></div>
                          )}
                          Drag & Drop files here or click{" "}
                          <label htmlFor="file" style={{ cursor: "pointer" }}>
                            <b> here </b>
                          </label>
                          <p>File yang bisa di proses maksimal 100MB</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 content-modal">
              Files
              <div className="container-fluid">
                {attacment && (
                  <>
                    <br />
                    <a href="# " onClick={() => setAttacment(null)}>
                      <i className="fa fa-trash"></i>
                    </a>{" "}
                    {attacment.file.name}
                  </>
                )}
              </div>
            </div>
          </div>
          <hr />
          <div className="row reset-margin-row ">
            <div className="col-md-12 text-right mb-3">
              <Buttons
                onClick={() => {
                  props.setModalBerkas(false);
                  setAttacment(null);
                }}
                className="btn btn-default mr-2"
              >
                Batal
              </Buttons>
              <Buttons
                onClick={saveResponse}
                disabled={attacment ? false : true}
                className="btn btn-primary mr-2"
              >
                Simpan
              </Buttons>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default ModalBerkas;
