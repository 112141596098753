import Content from "./cards/content";
import React from "react";
import Layout from "layout";

export function BankSuratMasuk() {
  return (
    <Layout subnavbar={{ print_btn: true, reset_btn: false, title: "Bank Surat Masuk", keysTab: "listBankSuratMasuk", path: "/bank-surat/list-surat/" }}>
      <Content />
    </Layout>
  );
}
