import moment from 'moment';
import { useEffect } from 'react';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
// import { delRequest } from 'config/axios';
import Loading from 'components/body/Loading';
import Paginate from 'components/footers/paginate';
import { useDispatch, useSelector } from 'react-redux';
import { getListMethod, set_list } from 'redux/SuratReducers/ListSuratMasuk/ListSuratAction';
import {
  decrement,
  increment,
  setFirstPage,
  setLastPage,
} from 'utils/pagination';

const Content = () => {
  const dispatch = useDispatch();
  const { GeneralReducers, ListSuratReducers, BuatSuratReducers, LoginReducers } = useSelector(
    (state) => state
  );
  const { loading, status, msg } = GeneralReducers;
  const { jenis_surat, status_surat } = ListSuratReducers;
  const { items, meta } = ListSuratReducers.listBankSuratKeluar;
  let satuan_kerja_id =
    LoginReducers.userInfo.data_pegawai.satuan_kerja.satuan_kerja_id || 0;
  const getList = () => {
    dispatch(
      getListMethod(
        `master/bank-mail-keluar`,
        'listBankSuratKeluar',
        null,
        satuan_kerja_id
      )
    );
  };
  console.log("list bank surat reducers", BuatSuratReducers)
  // const deleteSurat = (id) => {
  //   dispatch(setLoading(true));
  //   delRequest(`egs/master/bank-mail/${id}`)
  //     .then((res) => {
  //       dispatch(setLoading(false));
  //       dispatch(setStatus({ status: 'success-bank-surat', msg: 'Success !' }));
  //     })
  //     .catch((err) => dispatch(checkError(err, 'fail-bank-surat')));
  // };

  useEffect(getList, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(getList, [jenis_surat]);
  useEffect(getList, [status_surat]);

  return (
    <Fragment>
      <div className='content-body'>
        {status === 'success-bank-surat' && (
          <div className='alert alert-success'>{msg}</div>
        )}
        {status === 'fail-bank-surat' && (
          <div className='alert alert-danger'>{msg}</div>
        )}
        <Loading isLoading={loading} />
        <div className="form-group row">
          <div className="col-md-4">
            <label htmlFor="jenis_surat"></label>
            <select
              className="form-control"
              defaultValue={jenis_surat}
              onChange={(e) => {
                dispatch(set_list({ name: "jenis_surat", value: e.target.value }))
              }}
            >
              <option value="">Pilih Jenis Surat</option>
              <option value="perintah">Perintah</option>
              <option value="undangan">Undangan</option>
            </select>
          </div>
          <div className="col-md-4">
            <label htmlFor="status_surat"></label>
            <select
              className="form-control"
              defaultChecked={status_surat}
              onChange={(e) => {
                dispatch(set_list({ name: "status_surat", value: e.target.value }))
              }}
            >
              <option value="">Pilih Status</option>
              <option value="terkirim">Terkirim</option>
              <option value="konsep">Konsep</option>
            </select>
          </div>
        </div>

        <table className='table table-striped table-list'>
          <thead>
            <tr>
              <td width='130px'>Tanggal Di Buat</td>
              <td>Pengirim</td>
              <td>Hal</td>
              <td>Status</td>
              <td>Jenis Surat</td>
            </tr>
          </thead>
          <tbody>
            {items &&
              items.map((list, i) => {
                return (
                  <tr key={i}>
                    <td>
                      <Link to={`/bank-surat/list/detail/${list.id}`}>
                        {moment(list.tanggal).format('LL')}
                      </Link>
                    </td>
                    <td>
                      <b>{list.pengirim.peg_nama}</b>
                      <p>{list.pengirim.peg_jabatan}</p>
                      {list.dari}
                    </td>
                    <td>{list.hal}</td>
                    <td>
                      <span className='badge badge-primary'>
                        {list.status.text}
                      </span>
                    </td>
                    <td>{list.status_mail}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      {meta && (
        <Paginate
          meta={meta}
          increment={() => increment(meta.current_page, getList)}
          decrement={() => decrement(meta.current_page, getList)}
          setLastPage={() => setLastPage(meta.last_page, getList)}
          setFirstPage={() => setFirstPage(meta.from, getList)}
        />
      )}
    </Fragment>
  );
};

export default Content;
