import React, { Fragment } from "react";
import Paginate from "components/footers/paginate";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getListMethod } from "redux/SuratReducers/ListSuratMasuk/ListSuratAction";
import {
  decrement,
  increment,
  setFirstPage,
  setLastPage,
} from "utils/pagination";
import { useEffect } from "react";
import moment from "moment";
import Loading from "components/body/Loading";

const DaftarSuratSekretariat = () => {
  const dispatch = useDispatch();

  const {
    GeneralReducers,
    ListSuratReducers,
    NotificationReducers,
  } = useSelector((state) => state);

  const { loading } = GeneralReducers;
  const { surat_masuk } = NotificationReducers;
  const { items, meta } = ListSuratReducers.daftarSuratMasukSekretariat;

  const getList = (current_page = 1) => {
    dispatch(
      getListMethod(
        "mail-sekretariat",
        "daftarSuratMasukSekretariat",
        current_page
      )
    );
  };

  useEffect(getList, [surat_masuk]);

  return (
    <Fragment>
      <div className="content-title">
        <div className="content-filter">
        </div>
      </div>
      <div className="content-body">
        <Loading isLoading={loading} />
        <table className="table table-striped table-list">
          <thead>
            <tr>
              <td>Nomor Surat</td>
              <td>Dari</td>
              <td>Hal</td>
              <td>Tipe</td>
              <td>Tgl. Surat</td>
              <td>Tgl. Terima</td>
            </tr>
          </thead>
          <tbody>
            {items &&
              items.map((list, i) => {
                return (
                  <tr key={i} className={!list.is_read ? `unread-msg` : ""}>
                    <td>
                      <Link
                        to={`/surat-masuk/daftar-surat/detail-sekretariat/sekretariat/${list.id}`}
                      >
                        {list.no_surat}{" "}
                        {/* {!list.is_read && (
                          <span className="badge badge-success">unread</span>
                        )} */}
                      </Link>
                    </td>
                    <td>{list.dari}</td>
                    {/* <td>{list.kepada}</td> */}
                    <td>{list.hal}</td>
                    <td>
                      <span className="badge badge-success">
                        {list.status.text}
                      </span>
                    </td>
                    <td>{moment(list.tanggal_surat).format("LL")}</td>
                    <td>{moment(list.tanggal_terima).format("LL")}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      {meta && (
        <Paginate
          increment={() => increment(meta.current_page, getList)}
          meta={meta}
          decrement={() => decrement(meta.current_page, getList)}
          setLastPage={() => setLastPage(meta.last_page, getList)}
          setFirstPage={() => setFirstPage(meta.from, getList)}
        />
      )}
    </Fragment>
  );
};

export default DaftarSuratSekretariat;
