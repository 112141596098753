import {
  RESET_VALUE_SURAT_MASUK,
  SET_SURAT_MASUK,
  SET_VALUE_SURAT_MASUK,
} from "redux/rootRedux/TypeActions";

const GlobalState = {
  status_surat: ["terkirim"],
  kategori_surat: ["external"],
  no_surat: "",
  tahun_agenda: "",
  tanggal_terima: new Date(),
  tanggal_surat: new Date(),
  dari: "",
  kepala_surat: {
    perihal: "",
    lampiran: {
      type: "",
      value: "",
    },
    klasifikasi: null,
    sifat: {
      pengamanan: "",
      penyampaian: "",
    },
  },
  tembusan: {
    user: [],
    plt: [],
  },
  penerima: {
    user: [],
    plt: [],
  },
  info_jadwal: {
    tanggal_mulai: new Date(),
    tanggal_berakhir: new Date(),
    tempat: "",
  },
  lampiran: [],
  data_pengirim: {
    nama: "",
    email: "",
    nomor_wa: ""
  }
};

export const BuatSuratMasukReducers = (state = GlobalState, action) => {
  switch (action.type) {
    case SET_VALUE_SURAT_MASUK:
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case RESET_VALUE_SURAT_MASUK:
      return {
        ...GlobalState,
      };
    case SET_SURAT_MASUK: 
      return {...action.payload}
    default:
      return state;
  }
};
