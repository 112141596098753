import { SET_OPTION_SELECT, SET_OPTION_DEFAULT } from "redux/rootRedux/TypeActions";

const GlobalState = {
  satuanKerja: {},
  unitKerja: {},
  jabatan: {}
};

export const ListOptionReducers = (state = GlobalState, action) => {
  switch (action.type) {
    case SET_OPTION_SELECT:
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case SET_OPTION_DEFAULT:
      return { ...GlobalState };
    default:
      return state;
  }
};
