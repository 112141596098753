import React from "react";
import { Fragment } from "react";

function SubNavbar(props) {
  return (
    <Fragment>
      <div className="label-form-sub-nav m-0-10">Master Kop Surat</div>
      <div className="input-form-sub-nav m-0-10"></div>
      <div className="ex-btn-form-sub-nav m-0-10"></div>
    </Fragment>
  );
}

export default SubNavbar;
